<template>
  <div>
    <el-col :span="24">
      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Client Total</h5>
        <clientTotal ref="grandchildComp" />
      </div>

      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Full Breakdown</h5>
        <fullBreakdown ref="grandchildComp2" />
      </div>
      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">State and Region</h5>
        <byStateAndRegion ref="grandchildComp3" />
      </div>

      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">State Region and Charger Type</h5>
        <StateRegionAndChargerType ref="grandchildComp4" />
      </div>

      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Verra Template</h5>
        <VerraTemplate ref="grandchildComp5" />
      </div>

      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Private Fleet By Site</h5>
        <PrivateFleetBySite ref="grandchildComp8" />
      </div>

      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">State and Region 1</h5>
        <StateRegionOne ref="grandchildComp6" />
      </div>

      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">State and Region 2</h5>
        <StateRegionTwo ref="grandchildComp7" />
      </div>

      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">SD Vista</h5>
        <SdVista ref="grandchildComp9" />
      </div>
    </el-col>
  </div>
</template>

<script>
import clientTotal from "./clientTotal.vue";
import byStateAndRegion from "./byStateAndRegion.vue";
import fullBreakdown from "./fullBreakdown.vue";
import StateRegionAndChargerType from "./StateRegionAndChargerType.vue";
import VerraTemplate from "./VerraTemplate.vue";
import StateRegionOne from "./StateRegionOne.vue";
import StateRegionTwo from "./StateRegionTwo.vue";
import PrivateFleetBySite from "./PrivateFleetBySite.vue";
import SdVista from "./SdVista.vue";

export default {
  props: {
    parentDataFilter: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataList: [],
    };
  },
  components: {
    clientTotal,
    byStateAndRegion,
    fullBreakdown,
    StateRegionAndChargerType,
    VerraTemplate,
    StateRegionOne,
    StateRegionTwo,
    PrivateFleetBySite,
    SdVista,
  },

  methods: {
    emitToParent(parentDataFilter) {
      this.$refs.grandchildComp.getTableData(parentDataFilter);
      this.$refs.grandchildComp2.getTableData(parentDataFilter);
      this.$refs.grandchildComp3.getTableData(parentDataFilter);
      this.$refs.grandchildComp4.getTableData(parentDataFilter);
      this.$refs.grandchildComp5.getTableData(parentDataFilter);
      this.$refs.grandchildComp8.getTableData(parentDataFilter);
      this.$refs.grandchildComp6.getTableData(parentDataFilter);
      this.$refs.grandchildComp7.getTableData(parentDataFilter);
      this.$refs.grandchildComp9.getTableData(parentDataFilter);
    },
  },
};
</script>
