<template>

    <el-col :span="24">
      <div
        class="ma-0"
        v-loading="loading"
        :element-loading-spinner="svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        element-loading-background="rgba(122, 122, 122, 0.8)"
      >
      
        <el-table
          :data="sortedUserDataList"
          :fit="true"
          height="300"
          :row-class-name="tableRowClassName"
          @row-click="handleRowClick"
          class="my-2 data-table"
          scrollbar-always-on
          :default-sort="{ prop: 'log_date', order: 'descending' }"
        >
          <el-table-column
            :label="'User'"
            :prop="'user_full_name'"
            :width="$vuetify.display.width <= 1400 ? 200 : ''"
          />
          <!-- <el-table-column
            :label="'Email'"
            :prop="'user_email'"
            :width="$vuetify.display.width <= 1400 ? 250 : 250"
          /> -->
          <el-table-column
            :label="'Log Date Time (AU/SYD)'"
            :prop="'log_date'"
            :width="$vuetify.display.width <= 1400 ? 150 : ''"
          >
            <template #default="scope">
              <i class="fa fa-calendar f-14 me-primary mr-2"></i>
              {{ convertTimezone(scope.row.log_date, 'date_time') }}
            </template>
          </el-table-column>
          <el-table-column
            :label="'Form Kind'"
            :prop="'form_kind'"
            :width="$vuetify.display.width <= 1400 ? 200 : ''">
            <template #default="scope">
              {{ scope.row.form_kind.replace("report_", "").replace("snapshot","Snapshot")}}
            </template>

          </el-table-column>
          <el-table-column
            :label="'Action Kind'"
            :prop="'action_kind'"
            :width="$vuetify.display.width <= 1400 ? 200 : ''">
            <template #default="scope">
              {{ scope.row.action_kind.replace("_snapshot", "").replace("create","Create")}}
            </template>
          </el-table-column>
          <el-table-column
            :label="'Comment'"
            :prop="'comment'"
            :width="$vuetify.display.width <= 1400 ? 300 : 280"
          >
            <template #default="scope">
              <span class="line-clamp-2"> {{ scope.row.comment }} </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="$vuetify.display.width < 500"
            :label="'Operations'"
            :prop="'operations'"
            align="center"
            width="150"
          >
            <template #default="scope">
              <el-button
                v-if="
                  scope.row.before_change != null && scope.row.before_change != ''
                "
                color="#0d3937"
                @click.stop="handleShow(scope.$index, scope.row)"
                size="small"
                class="me-primary-light"
              >
                <i class="fa fa-edit"></i>
                <span class="pl-1">Show</span>
              </el-button>
              <el-button
                v-if="
                  scope.row.before_change != null && scope.row.before_change != ''
                  "
                  class="height-25 border-color-light rounded-2 m-0 ml-2"
                  @click.stop="handleExportFile(scope.$index, scope.row)"
                  color="#0D3937"
                  size="small"
                  >
                  <i class="text-white fa fa-cloud-download"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            fixed="right"
            :label="'Operations'"
            :prop="'operations'"
            align="center"
            width="150"
          >
            <template #default="scope">
              <el-button
                v-if="
                  scope.row.before_change != null && scope.row.before_change != ''
                "
                color="#0d3937"
                @click.stop="handleShow(scope.$index, scope.row)"
                size="small"
                class="me-primary-light"
              >
                <i class="fa fa-edit"></i>
                <span class="pl-1">Show</span>
              </el-button>
              <el-button
                v-if="
                  scope.row.before_change != null && scope.row.before_change != ''
                  "
                  class="height-25 border-color-light rounded-2 m-0 ml-2"
                  @click.stop="handleExportFile(scope.$index, scope.row)"
                  color="#0D3937"
                  size="small"
                  >
                  <i class="text-white fa fa-cloud-download"></i>
              </el-button>
            </template>
          </el-table-column>
          <!-- show button end -->
        </el-table>
  
        <el-row class="row-bg" justify="space-between">
          <el-col
            :span="5"
            class="d-flex justify-content-start align-items-center"
          >
            <el-select
              v-model="perPageCount"
              placeholder="Select"
              size="small"
              style="width: 70px"
            >
              <el-option label="10" :value="'10'" />
              <el-option label="25" :value="'25'" />
              <el-option label="50" :value="'50'" />
              <el-option label="100" :value="'100'" />
            </el-select>
          </el-col>
          <el-col
            :span="8"
            class="d-flex align-items-center justify-content-center"
          >
            <el-pagination
              small
              background
              layout="prev, pager, next"
              :total="total_items"
              v-model:page-size="page_size"
              v-model:current-page="current_page"
              @current-change="changePage"
              class="my-4"
            />
          </el-col>
          <el-col :span="5" class="d-flex justify-content-end align-items-center">
            <span class="text-white">
              {{ (current_page - 1) * perPageCount + 1 }}-{{
                current_page * perPageCount > total_items
                  ? total_items
                  : current_page * perPageCount
              }}
              of {{ total_items }}
            </span>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </template>
  
  <script>
  import moment from 'moment-timezone';
  // import { ElMessageBox } from "element-plus";
  // import ShowDetail from "./dialogDetail.vue";
  // import UserService from "../../services/user.service";
  export default {
    props: {
      loadingProp: {
        type: Boolean,
        default: false,
      },
      usersDataProp: {
        type: Array,
        default(rawProps) {
          return rawProps;
        },
      },
      totalRowProp: {
        type: Number,
      },
    },
    components: {
      // ShowDetail,
    },
    data() {
      return {
        firstPlay: true, // control if change page to last active

        show: true,
        userDataList: [],
        search: "",
        current_page: 1,
        page_size: 50,
        total_items: 100,
        perPageCount: 50,
        loading: false,
        show_modal: false,
        data_modal: null,
        current_row_index: null,
        filterOpen: false,
      };
    },

    watch: {
      loadingProp: {
        handler: function (newValue) {
          this.loading = newValue;
        },
        deep: true,
      },
      usersDataProp: {
        handler: function (newValue) {
          this.userDataList = newValue;
        },
        deep: true,
      },
      totalRowProp: {
        handler: function (newValue) {
          this.total_items = newValue;
          // console.log(this.total_items);
        },
        deep: true,
      },
      perPageCount: function (newVal) {
        this.current_page = 1;
        this.page_size = parseInt(newVal);
        this.$emit("tableAction", {
          index: this.page_size,
          data: null,
          kind: "perPageChange",
        });
      },
    },
    computed: {
      sortedUserDataList() {
        return this.userDataList.slice().sort((a, b) => new Date(b.log_date) - new Date(a.log_date));
      },
      lastPage() {
        return Math.ceil(this.total_items / this.page_size);
      },
    },

    // when table updated, change page to last page, when first play is false
    updated() {
      if (this.firstPlay) {
        this.current_page = this.pageToLast();
        this.pageToLast();
        this.firstPlay = false;
      } else {
        this.firstPlay = false;
      }
    },
    
    methods: {

      /**
       * Converts a given date string to the specified format in the Australia/Sydney timezone.
       *
       * @param {string} dateString - The date string to be converted.
       * @param {string} type - The type of format to return. Currently supports:
       *   - 'date_time': Returns the date and time in the format 'YYYY-MM-DD HH:mm:ss'.
       * @returns {string} - The formatted date string in the Australia/Sydney timezone.
       *
       * Description:
       * - Cleans the `dateString` by removing the milliseconds and appending a 'Z' to indicate UTC time.
       * - Converts the cleaned date string to the Australia/Sydney timezone using `moment.utc` and `tz`.
       * - Formats the date based on the specified `type`. Currently, it supports the 'date_time' format, which returns the date and time in the format 'YYYY-MM-DD HH:mm:ss'.
       */

      // convert timezone from utc to syd
      convertTimezone(dateString, type) {
        const cleanedDateString = dateString.split('.')[0] + 'Z';
        const date = moment.utc(cleanedDateString).tz('Australia/Sydney');
        // if (type === 'date') {
        //   return date.format('YYYY-MM-DD');
        // } else if (type === 'time') {
        //   return date.format('HH:mm:ss');
        // }
        if (type === 'date_time') {
          return date.format('YYYY-MM-DD HH:mm:ss');
        }

      },

      // change page to last page to show newest data
      /**
       * Navigates to the last page of the table.
       *
       * @returns {number} - The last page number.
       *
       * Description:
       * - Emits a `tableAction` event to navigate to the last page of the table.
       * - The event payload includes the last page number, null data, and the action kind "changePage".
       * - Returns the last page number.
       */
      pageToLast() {
        
        this.$emit("tableAction", {
          index: this.lastPage,
          data: null,
          kind: "changePage",
        });
        // console.log("last Page", this.lastPage);
        return this.lastPage;
        
      },


      // export excel file send index and event to parent component
      handleExportFile(index, data) {
        this.$emit("tableAction", { index: index, data: data, kind: "export" });
      },
      handleRowClick(row, column, event) {
        console.log(row, column, event);
      },
      handleShow(index, data) {
        this.$emit("tableAction", { index: index, data: data, kind: "edit" });
      },
      deleteRow(event, index) {
        this.$emit("tableAction", { index: index, data: event, kind: "delete" });
      },
      changePage(page) {
        this.$emit("tableAction", {
          index: page,
          data: null,
          kind: "changePage",
        });
      },
    },
  };
  </script>
  