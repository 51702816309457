<template>
    <Breadcrumbs main="Auditor Reports" title="default" />

      <div class="container-fluid p-0">
        <div class="row widget-grid">
            <Filter
                :stateProp="stateList"
                @filterHandle="filterHandle"
                :hasCustomDate="true"
                :hasCustomer="false"
                :hasPeriod="true"
                :periodNameProp="`2023 MP`"
            /> 


        <div class="row widget-grid m-0 px-6">
            <div class= "m-0 mt-3 py-3 bg-me-primary border-color-light rounded-4">
            
            <el-row class="">

                <el-col :span="24">
                    <div class="row m-0">
                        <!-- search bar -->
                        <el-col
                            class="pl-0"
                            :span="$vuetify.display.width >= 450 ? 14 : 24"
                            :class="
                            $vuetify.display.width < 450
                                ? 'x-center p-0 order-3 mt-2'
                                : ''
                            "
                        >
                            <el-input
                            size="large"
                            v-model="filter.search"
                            placeholder="Type to search"
                            :prefix-icon="Search"
                            class="table-search"
                            />
                        </el-col>
                        <el-col
                            class="p-0"
                            :span="$vuetify.display.width >= 450 ? 10 : 24"
                            :class="
                            $vuetify.display.width < 450
                            ? 'x-center pr-1 order-1 width-100-p'
                            : 'd-flex justify-content-end'"
                            >

                                <!-- export file button -->
                                <el-button
                                @click="handleExportFile"
                                color="#0D3937"
                                class="height-40 border-color-light rounded-3 mr-1"
                                >
                                <i class="text-white fa fa-cloud-download"></i>
                                </el-button>

                                <!-- apply button -->
                                <el-button
                                @click.stop="handleReload"
                                color="#ddf0ac"
                                class="height-40 rounded-3 ml-1"
                                :class="$vuetify.display.width < 450 ? 'width-100-p' : ''"
                                >
                                <svg class="stroke-icon width-20 height-20">
                                    <use
                                    href="@/assets/svg/icon-sprite.svg#stroke-refresh"
                                    ></use>
                                </svg>
                                <span class="me-primary pl-3"> Apply </span>
                                </el-button>
                        </el-col>
                    </div>
                </el-col>
            </el-row>   
          </div>
        </div>

        <el-row class="m-0 width-100-p mt-3">
        <el-col class="d-flex align-item-center" :span="24">
          <div class="row m-0 width-100-p">
            <div >
                <auditorReportTables 
                    :parentDataFilter="filter"
                    :loadingProp="loading"
                    :totalRowProp="total_items"
                    @tableAction="handleTableAction"
                    ref="childComp"
                    />
            </div>
            </div>
          </el-col>
          </el-row>
        </div>
      </div>  

</template>

<script>
import auditorReportTables from "./auditorReportTables.vue";
import Filter from "../common/filter.vue";
import XLSX from "xlsx";
import { mapState } from "vuex";


export default {
    data() {
        return {
            // test
            test : "",

            // table
            current_page: 1,
            perPageCount: 100,
            total_items: 100,
            loading: false,

            // Filter
            filter: {
                // customer_id: null,
                startDate: null,
                endDate: null,
                search: null,        // Search bar
            },

            request: {
                start_date: null,
                end_date: null,
                search: null,
                report_type : null,
                current_page: null,
                perPageCount: null,
            },

        }
    },

    computed: {
        ...mapState('auditorReport',{
            clientTotal: state => state.clientTotalDataList
        }),
        ...mapState('auditorReport',{
            fullBreakdown: state => state.fullBreakdownDataList
        }),
        ...mapState('auditorReport',{
            StateRegion: state => state.StateRegionDataList
        }),
        ...mapState('auditorReport',{
            StateRegionChargerType: state => state.StateRegionChargerTypeDataList
        }),
        ...mapState('auditorReport',{
            VerraTemplate: state => state.VerraTemplateList
        }),
        ...mapState('auditorReport',{
            StateRegionOne: state => state.StateRegionOneList
        }),
        ...mapState('auditorReport',{
            StateRegionTwo: state => state.StateRegionTwoList
        }),
        ...mapState('auditorReport',{
            PrivateFleetSite: state => state.PrivateFleetSiteList
        }),
        ...mapState('auditorReport',{
            SDVista: state => state.SDVistaList
        }),



        stateList() {
        return this.states;
        },
    },

    components: {
        auditorReportTables,
        Filter,
    },

    methods: {


        filterHandle(event) {
            this.filter = event;
            this.handleApplyFilterData();
        },    
        async handleExportFile() {
            const wb = XLSX.utils.book_new();
            this.excelDataList = this.clientTotal;
            if (this.excelDataList.length > 0) {
                const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
                header: Object.keys(this.excelDataList[0]),
                });
                XLSX.utils.book_append_sheet(wb, ws, "ClientTotal");
            } else {
                console.log("clientTotal no data");
            }
            this.excelDataList = this.fullBreakdown;
            if (this.excelDataList.length > 0) {
                const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
                header: Object.keys(this.excelDataList[0]),
                });
                XLSX.utils.book_append_sheet(wb, ws, "FullBreakdown");
            } else {
                console.log("FullBreakdown no data");
            }
            this.excelDataList = this.StateRegion;
            if (this.excelDataList.length > 0) {
                const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
                header: Object.keys(this.excelDataList[0]),
                });
                XLSX.utils.book_append_sheet(wb, ws, "byStateAndRegion");
            } else {
                console.log("byStateAndRegion no data");
            }
            this.excelDataList = this.StateRegionChargerType;
            if (this.excelDataList.length > 0) {
                const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
                header: Object.keys(this.excelDataList[0]),
                });
                XLSX.utils.book_append_sheet(wb, ws, "byStateAndRegionAndChargerType");
            } else {
                console.log("byStateAndRegionAndChargerType no data");
            }
            this.excelDataList = this.VerraTemplate;
            if (this.excelDataList.length > 0) {
                const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
                header: Object.keys(this.excelDataList[0]),
                });
                XLSX.utils.book_append_sheet(wb, ws, "byVerraTemplate");
            } else {
                console.log("byVerraTemplate no data");
            }
            this.excelDataList = this.PrivateFleetSite;
            if (this.excelDataList.length > 0) {
                const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
                header: Object.keys(this.excelDataList[0]),
                });
                XLSX.utils.book_append_sheet(wb, ws, "PrivateFleetBySite");
            } else {
                console.log("PrivateFleetBySite no data");
            }
            this.excelDataList = this.StateRegionOne;
            if (this.excelDataList.length > 0) {
                const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
                header: Object.keys(this.excelDataList[0]),
                });
                XLSX.utils.book_append_sheet(wb, ws, "ByStateRegionOne");
            } else {
                console.log("ByStateRegionOne no data");
            }
            this.excelDataList = this.StateRegionTwo;
            if (this.excelDataList.length > 0) {
                const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
                header: Object.keys(this.excelDataList[0]),
                });
                XLSX.utils.book_append_sheet(wb, ws, "ByStateRegionTwo");

            } else {
                console.log("ByStateRegionTwo no data");
            }
            this.excelDataList = this.SDVista;
            if (this.excelDataList.length > 0) {
                const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
                header: Object.keys(this.excelDataList[0]),
                });
                XLSX.utils.book_append_sheet(wb, ws, "SD_Vista");
            } else {
                console.log("SDVista no data");
            }
            XLSX.writeFileXLSX(wb, "AuditorReport.xlsx");
        },    

        handleTableAction(event) {
            if (event.kind == "changePage") {
                this.current_page = event.index;
                this.handleReload();
            } else if (event.kind == "perPageChange") {
                this.current_page = 1;
                this.perPageCount = event.index;
                this.handleReload();
            }
        },
        handleReload() {
            // this.fetchData();
            this.handleApplyFilterData();
        },

        handleApplyFilterData(){
            // console.log("apply filter data");
            this.$refs.childComp.emitToParent(this.filter);

            // update request data with filter data
            this.request.start_date = this.filter.startDate;
            this.request.end_date = this.filter.endDate;
            this.request.search = this.filter.search;

        },
    },
}
</script>

<style scoped>
span{
    color: white;
}
</style>