<!-- eslint-disable vue/no-unused-vars -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container-fluid">
    <Breadcrumbs main="History Logs" :path="breadcrumbPath" />
    <el-row class="m-0 width-100-p mt-3">
      <el-col class="d-flex align-item-center px-2" :span="24">
        <div class="row m-0 width-100-p px-2">
          <div
            class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
          >
            <el-col class="mb-2" :span="24">
              <div class="row m-0">
                <el-col
                  v-if="$vuetify.display.width < 700"
                  :span="24"
                  class="d-flex align-item-center"
                >
                  <el-button
                    color="#ddf0ac"
                    @click.stop="handleReload"
                    size="small"
                    class="height-40 width-40-p rounded-2 m-0 ml-2"
                  >
                    <i class="fa fa-refresh pr-2"></i>
                    <span class="me-primary"> Apply </span>
                  </el-button>
                  <!-- <el-button
                    color="#ddf0ac"
                    size="small"
                    @click.stop="handleNew"
                    class="height-40 width-40-p rounded-2 m-0 ml-2"
                  >
                    <i class="fa fa-plus pr-2 me-primary"></i>
                    <span class="me-primary"> New </span>
                  </el-button> -->
                  <el-button
                    class="height-40 width-20-p rounded-2 m-0 ml-2"
                    size="small"
                    @click="handleExportFile"
                    color="#ddf0ac"
                  >
                    <i class="fa fa-file-excel-o me-primary"></i>
                  </el-button>
                </el-col>
                <el-col
                  v-if="$vuetify.display.width < 700"
                  :span="24"
                  class="d-flex align-item-center mt-3"
                >
                  <el-input
                    size="large"
                    v-model="filter.search"
                    placeholder="Type to search by site name, charger ID, port ..."
                    :prefix-icon="Search"
                    class="table-search"
                  />
                </el-col>
                <el-col
                  v-if="$vuetify.display.width >= 700"
                  :span="$vuetify.display.width >= 1200 ? 14 : 24"
                  class="d-flex align-item-center"
                  :class="
                    $vuetify.display.width < 750
                      ? 'x-center mt-2'
                      : 'justify-start'
                  "
                >
                  <el-input
                    size="large"
                    v-model="filter.search"
                    placeholder="Type to search by site name, charger ID, port ..."
                    :prefix-icon="Search"
                    class="table-search"
                    :class="$vuetify.display.width >= 1200 ? 'width-400' : ''"
                  />
                  <el-button
                    color="#ddf0ac"
                    @click.stop="handleReload"
                    size="small"
                    class="height-40 rounded-2 m-0 ml-2"
                  >
                    <i class="fa fa-refresh pr-2"></i>
                    <span class="me-primary"> Apply </span>
                  </el-button>
                  <!-- <el-button
                    v-if="$vuetify.display.width < 1200"
                    color="#ddf0ac"
                    size="small"
                    @click.stop="handleNew"
                    class="height-40 rounded-2 m-0 ml-2"
                  >
                    <i class="fa fa-plus pr-2 me-primary"></i>
                    <span class="me-primary"> New </span>
                  </el-button> -->
                  <el-button
                    v-if="$vuetify.display.width < 1200"
                    class="height-40 rounded-2 m-0 ml-2"
                    size="small"
                    @click="handleExportFile"
                    color="#ddf0ac"
                  >
                    <i class="fa fa-file-excel-o me-primary"></i>
                  </el-button>
                </el-col>
                <el-col
                  :span="10"
                  v-if="$vuetify.display.width >= 1200"
                  class="d-flex align-item-center justify-end"
                >
                  <!-- <el-button
                    color="#ddf0ac"
                    size="small"
                    @click.stop="handleNew"
                    class="height-40 rounded-2 m-0"
                  >
                    <i class="fa fa-plus pr-2 me-primary"></i>
                    <span class="me-primary"> New </span>
                  </el-button> -->
                  <el-button
                    class="height-40 rounded-2 m-0 ml-2"
                    size="small"
                    @click="handleExportFile"
                    color="#ddf0ac"
                  >
                    <i class="fa fa-file-excel-o me-primary"></i>
                  </el-button>
                </el-col>
              </div>
            </el-col>

            <DataTable
              :loadingProp="loading"
              :usersDataProp="historyDataList"
              :totalRowProp="total_items"
              @tableAction="handleTableAction"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <ShowDetail
      :open_modal="show_modal"
      :data_modal="data_modal"
      @close_modal="close_modal"
    />
  </div>
</template>

<script>
import Breadcrumbs from "../../components/bread_crumbs.vue";
import XLSX from "xlsx";
import UserService from "../../services/user.service";
import DataTable from "./dataTable.vue";
// import Filter from "./filter.vue";
import ShowDetail from "./dialogDetail.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "historyLogs",
  components: {
    Breadcrumbs,
    // Filter,
    ShowDetail,
    DataTable,
  },
  data() {
    return {
      breadcrumbPath: [
        {
          title: "General",
          disabled: true,
          href: "",
        },
        {
          title: "History Logs",
          disabled: false,
          href: "/history-logs",
          active: true,
        },
      ],

      show: true,
      historyDataList: [],
      current_page: 1,
      page_size: 50,
      total_items: 100,
      perPageCount: 50,
      loading: false,
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      filterOpen: false,
      filter: {
        fromDate: "",
        toDate: "",
        formKind: "",
        actionKind: "",
        search: "",
      },
    };
  },
  watch: {},
  computed: {
    filter_history() {
      return this.historyDataList;
    },
  },
  mounted() {
    this.fetchHistoryData(this.current_page);
  },
  methods: {
    fetchHistoryData(page) {
      // this.filter.search = this.search;
      UserService.getHistoryLogs(page, this.filter, this.perPageCount)
        .then(
          (response) => {
            const dataList = response.data.history_logs;
            this.historyDataList = dataList.filter((item) => item.form_kind !== "report_snapshot" && item.action_kind !== "create_snapshot");
            this.total_items = response.data.total_record;
            // console.log("total", response.data.total_record);
            // console.log('dataList', this.historyDataList.length);
            
            
            // this.total_items = this.historyDataList.length;
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },

    handleExportFile() {
      if (this.filter_history.length > 0) {
        const myHeader = [
          "full_name",
          "phone",
          "effective_from",
          "country",
          "has_user",
        ];
        const ws = XLSX.utils.json_to_sheet(this.filter_history, {
          header: myHeader,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFileXLSX(wb, "HistoryLogList.xlsx");
      }
    },

    reset_filter11(event) {
      this.current_page = 1;
      this.search = "";
      this.filter = event.filter;
      this.fetchHistoryData(this.current_page);
    },

    handleTableAction(event) {
      if (event.kind == "edit") {
        this.handleShow(event.index, event.data);
      } else if (event.kind == "delete") {
        this.deleteRow(event.data, event.index);
      } else if (event.kind == "changePage") {
        this.current_page = event.index;
        this.fetchHistoryData(this.current_page);
      } else if (event.kind == "perPageChange") {
        this.current_page = 1;
        this.perPageCount = event.index;
        this.handleReload();
      }
    },

    handleReload() {
      // this.filter.search = event.filter.search;
      // this.filter = event.filter;
      this.fetchHistoryData(this.current_page);
    },
    filterSearch11(event) {
      this.filter.search = event.search;
      this.fetchHistoryData(this.current_page);
    },
    handleShow(index, data) {
      if (data.before_change != null && data.before_change != "") {
        this.current_row_index = index;
        this.data_modal = data.before_change; //JSON.stringify(data);
        this.show_modal = true;
      }
    },
    // eslint-disable-next-line no-unused-vars
    close_modal(event) {
      this.show_modal = false;
    },
  },
};
</script>

<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-8);
}
.el-table .error-row {
  --el-table-tr-bg-color: var(--el-color-error-light-8);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.cell {
  text-align: center !important;
}

.starter-main .card-body ul {
  list-style-type: none !important;
}

.el-pager {
  -webkit-user-select: none !important;
  user-select: none !important;
  list-style: none !important;
  font-size: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
}

.el-upload-list {
  margin: 10px 0 0 !important;
  padding: 0 !important;
  list-style: none !important;
  position: relative !important;
}
</style>
