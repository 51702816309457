<!-- eslint-disable vue/no-unused-vars -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container-fluid">
    <Breadcrumbs main="Monitoring Periods" :path="breadcrumbPath" />
    <el-row class="m-0 width-100-p mt-3">
      <el-col class="d-flex align-item-center px-2" :span="24">
        <div class="row m-0 width-100-p px-2">
          <div
            class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
          >
            <el-col class="mb-2" :span="24">
              <div class="row m-0">
                <el-col
                  v-if="$vuetify.display.width < 700"
                  :span="24"
                  class="d-flex align-item-center"
                >
                  <el-button
                    color="#ddf0ac"
                    @click.stop="handleReload"
                    size="small"
                    class="height-40 rounded-2 m-0 ml-2"
                    :class="
                      $store.state.auth.user != null &&
                      !$store.state.auth.user.is_auditor
                        ? 'width-70-p'
                        : 'width-40-p'
                    "
                  >
                    <i class="fa fa-refresh pr-2"></i>
                    <span class="me-primary"> Apply </span>
                  </el-button>

                  

                  <el-button
                    v-if="
                      $store.state.auth.user != null &&
                      !$store.state.auth.user.is_auditor
                    "
                    color="#ddf0ac"
                    size="small"
                    @click.stop="handleNew"
                    class="height-40 width-40-p rounded-2 m-0 ml-2"
                  >
                    <i class="fa fa-plus pr-2 me-primary"></i>
                    <span class="me-primary"> New </span>
                  </el-button>
                  <el-button
                    class="height-40 rounded-2 m-0 ml-2"
                    :class="
                      $store.state.auth.user != null &&
                      !$store.state.auth.user.is_auditor
                        ? 'width-30-p'
                        : 'width-20-p'
                    "
                    size="small"
                    @click="handleExportFile"
                    color="#ddf0ac"
                  >
                    <i class="fa fa-file-excel-o me-primary"></i>
                  </el-button>
                </el-col>
                <el-col
                  v-if="$vuetify.display.width < 700"
                  :span="24"
                  class="d-flex align-item-center mt-3"
                >
                  <el-input
                    size="large"
                    v-model="search"
                    placeholder="Type to search by site name, charger ID, port ..."
                    :prefix-icon="Search"
                    class="table-search"
                  />
                </el-col>
                <el-col
                  v-if="$vuetify.display.width >= 700"
                  :span="$vuetify.display.width >= 1200 ? 14 : 24"
                  class="d-flex align-item-center"
                  :class="
                    $vuetify.display.width < 750
                      ? 'x-center mt-2'
                      : 'justify-start'
                  "
                >
                  <el-input
                    size="large"
                    v-model="search"
                    placeholder="Type to search by site name, charger ID, port ..."
                    :prefix-icon="Search"
                    class="table-search"
                    :class="$vuetify.display.width >= 1200 ? 'width-400' : ''"
                  />
                  <el-button
                    color="#ddf0ac"
                    @click.stop="handleReload"
                    size="small"
                    class="height-40 rounded-2 m-0 ml-2"
                  >
                    <i class="fa fa-refresh pr-2"></i>
                    <span class="me-primary"> Apply </span>
                  </el-button>

               

                  <el-button
                    v-if="
                      $vuetify.display.width < 1200 &&
                      $store.state.auth.user != null &&
                      !$store.state.auth.user.is_auditor
                    "
                    color="#ddf0ac"
                    size="small"
                    @click.stop="handleNew"
                    class="height-40 rounded-2 m-0 ml-2"
                  >
                    <i class="fa fa-plus pr-2 me-primary"></i>
                    <span class="me-primary"> New </span>
                  </el-button>
                  <el-button
                    v-if="$vuetify.display.width < 1200"
                    class="height-40 rounded-2 m-0 ml-2"
                    size="small"
                    @click="handleExportFile"
                    color="#ddf0ac"
                  >
                    <i class="fa fa-file-excel-o me-primary"></i>
                  </el-button>
                </el-col>
                <el-col
                  :span="10"
                  v-if="$vuetify.display.width >= 1200"
                  class="d-flex align-item-center justify-end"
                >

                

                  <el-button
                    v-if="
                      $store.state.auth.user != null &&
                      !$store.state.auth.user.is_auditor
                    "
                    color="#ddf0ac"
                    size="small"
                    @click.stop="handleNew"
                    class="height-40 rounded-2 m-0"
                  >
                    <i class="fa fa-plus pr-2 me-primary"></i>
                    <span class="me-primary"> New </span>
                  </el-button>
                  <el-button
                    class="height-40 rounded-2 m-0 ml-2"
                    size="small"
                    @click="handleExportFile"
                    color="#ddf0ac"
                  >
                    <i class="fa fa-file-excel-o me-primary"></i>
                  </el-button>
                </el-col>
              </div>
            </el-col>

            <DataTable
              :loadingProp="loading"
              :usersDataProp="filter_monitoring"
              :totalRowProp="total_items"
              @tableAction="handleTableAction"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <ShowDetail
      :open_modal="show_modal"
      :data_modal="data_modal"
      @close_modal="close_modal"
      @confirm_modal="confirm_modal"
    />
  </div>
</template>

<script>
import Breadcrumbs from "../../components/bread_crumbs.vue";
import XLSX from "xlsx";
import UserService from "../../services/user.service";
import DataTable from "./dataTable.vue";
import ShowDetail from "./dialogDetail.vue";
import { ElMessageBox } from "element-plus";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "monitoring-periods",
  components: {
    Breadcrumbs,
    // Filter,
    ShowDetail,
    DataTable,
  },
  data() {
    return {
      
     

      breadcrumbPath: [
        {
          title: "General",
          disabled: true,
          href: "",
        },
        {
          title: "Monitoring Periods",
          disabled: false,
          href: "/monitoring-periods",
          active: true,
        },
      ],

      show: true,
      monitoringDataList: [],
      search: "",
      current_page: 1,
      page_size: 20,
      total_items: 100,
      perPageCount: 50,
      loading: false,
      show_modal: false,
      data_modal: null,
      current_row_index: null,
      filterOpen: false,
    };
  },
  watch: {},
  computed: {
    filter_monitoring() {
      return this.monitoringDataList;
    },
  },
  mounted() {

    

    this.fetchMonitoringData(this.current_page);
  },
  methods: {



    fetchMonitoringData(page) {
      UserService.getMonitoringPeriods(
        null,
        this.search,
        page,
        this.perPageCount
      )
        .then(
          (response) => {
            this.monitoringDataList = response.data.monitoring_periods;
            this.total_items = response.data.total_record;



          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    DeleteMonitoring(user, index) {
      UserService.DeleteMonitoringPeriod(user.id)
        .then(
          // eslint-disable-next-line no-unused-vars
          (response) => {
            this.monitoringDataList.splice(index, 1);
          },
          (error) => {
            this.loading = false;
            console.log(error);
            if (error.response.status == 401) {
              this.$router.push({
                path: "/",
              });
            }
          }
        )
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status == 401) {
            this.$router.push({
              path: "/",
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // eslint-disable-next-line no-unused-vars
    handleRowClick(row, column, event) {
      // console.log(row, column, event);
    },
    // eslint-disable-next-line no-unused-vars
    close_modal(event) {
      this.show_modal = false;
    },

    confirm_modal(event) {
      if (this.monitoringDataList.length == this.current_row_index)
        this.monitoringDataList.push(event);
      else this.monitoringDataList[this.current_row_index] = event;
      this.show_modal = false;
    },

    handleShow(index, data) {
      this.current_row_index = index;
      this.data_modal = JSON.stringify(data);
      this.show_modal = true;
    },

    paginate(array, page_size, page_number) {
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },

    handleExportFile() {
      if (this.filter_monitoring.length > 0) {
        const myHeader = ["start_date", "end_date", "status"];
        const ws = XLSX.utils.json_to_sheet(this.filter_monitoring, {
          header: myHeader,
        });
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFileXLSX(wb, "MonitoringPeriodsList.xlsx");
      }
    },

    reset_filter(event) {
      this.current_page = 1;
      this.search = "";
      this.filter = event.filter;
    },

    handleTableAction(event) {
      if (event.kind == "edit") {
        this.handleShow(event.index, event.data);
      } else if (event.kind == "delete") {
        this.deleteRow(event.data, event.index);
      } else if (event.kind == "changePage") {
        this.current_page = event.index;
        this.fetchMonitoringData(this.current_page);
      } else if (event.kind == "perPageChange") {
        this.current_page = 1;
        this.perPageCount = event.index;
        this.handleReload();
      }
    },

    handleReload() {
      this.fetchMonitoringData(this.current_page);
    },
    handleNew() {
      let data = {
        start_date: null,
        end_date: null,
        status: "",
        id: 0,
      };
      this.current_row_index = this.monitoringDataList.length;
      this.data_modal = JSON.stringify(data);
      this.show_modal = true;
    },
    filterSearch(event) {
      this.search = event.search;
    },

    deleteRow(event, index) {
      console.log(event, index);
      ElMessageBox.confirm("Are you sure to delete this?", "info", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "Confirm Delete",
      })
        .then(() => {
          this.DeleteMonitoring(event, index);
          // ElMessage({
          //   type: "success",
          //   message: "Delete completed",
          // });
        })
        .catch(() => {
          // ElMessage({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
  },
};
</script>

<style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-8);
}
.el-table .error-row {
  --el-table-tr-bg-color: var(--el-color-error-light-8);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.cell {
  text-align: center !important;
}

.starter-main .card-body ul {
  list-style-type: none !important;
}

.el-pager {
  -webkit-user-select: none !important;
  user-select: none !important;
  list-style: none !important;
  font-size: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
}

.el-upload-list {
  margin: 10px 0 0 !important;
  padding: 0 !important;
  list-style: none !important;
  position: relative !important;
}
</style>
