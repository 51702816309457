<!-- eslint-disable vue/no-mutating-props -->
<template>
    <el-dialog
      v-model="show"
      :width="$vuetify.display.width <= 1400 ? 800 : $vuetify.display.width <= 1600 ? 1000 : $vuetify.display.width <= 2000 ? 1400 : $vuetify.display.width <= 2456 ? '75%' : ''"
      :fullscreen="$vuetify.display.width <= 800 ? true : false"
      class="public-style"
      destroy-on-close
      align-center
      @close="close"
    >
   

      <h5 >{{ comment }}</h5>

      <el-select v-model="selectedUpperTable" placeholder="Select Table" @change="updateSubSelectedTableData">
        <el-option
          v-for="item in upperTableList"
          :key="item"
          :label="item.replaceAll('_', ' ')"
          :value="item"
          >
        </el-option>
      </el-select>

      <el-select v-if="hasSubTable" v-model="selectedTable" placeholder="Select Table" @change="updateSelectedTableData">
        <el-option
          v-for="item in tableList"
          :key="item"
          :label="item.replaceAll('_', ' ')"
          :value="item"
          >
        </el-option>
      </el-select>
      
        <div class="popUpTable">
          
          <el-table :data="paginatedData" v-if="selectedTableData"
            class="my-2 data-table"
            width="95%"
            >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.prop"
              :label="column.label"
              :prop="column.prop"
              :width="$vuetify.display.width <= 1400 ? 200 : 180"
            >
            </el-table-column>
          </el-table>

          <el-row class="row-bg" justify="space-between">
          <el-col
            :span="5"
            class="d-flex justify-content-start align-items-center"
          >
            <el-select
              v-model="perPageCount"
              placeholder="Select"
              size="small"
              style="width: 70px"
              @change="updatePageSize"
            >
              <el-option label="10" :value="'10'" />
              <el-option label="25" :value="'25'" />
              <el-option label="50" :value="'50'" />
              <el-option label="100" :value="'100'" />
            </el-select>
          </el-col>
          <el-col
            :span="8"
            class="d-flex align-items-center justify-content-center"
          >
            <el-pagination
              small
              background
              layout="prev, pager, next"
              :total="total_items"
              v-model:page-size="perPageCount"
              v-model:current-page="current_page"
              @current-change="changePage"
              class="my-4"
            />
          </el-col>
          <el-col :span="5" class="d-flex justify-content-end align-items-center">
            <span class="text-white">
              {{ (current_page - 1) * perPageCount + 1 }}-{{
                current_page * perPageCount > total_items
                  ? total_items
                  : current_page * perPageCount
              }}
              of {{ total_items }}
            </span>
          </el-col>
        </el-row>
          
        </div>
      

      <!-- </el-row> -->
      <template #footer>
        <span class="dialog-footer">
          <el-button
            size="medium"
            class="me-primary"
            @click.stop="close"
            color="#ddf0ac"
          >
            <i class="fa fa-times me-2 me-primary"></i> Cancel
          </el-button>
        </span>
      </template>
    </el-dialog>



  </template>
  
  <script>
 
  export default {
    props: {
      open_modal: {
        type: Boolean,
        default: false,
      },
      data_comment: {
        type: String,
        default: null,
      },
      data_modal: {
        type: Object,
        // eslint-disable-next-line no-unused-vars
        default(rawProps) {
          return rawProps;
        },
      },
    },

    computed: {
      tableColumns() {
        if(this.selectedTableData.length > 0) {
          const columns = Object.keys(this.selectedTableData[0]).map(item => {
            return {
              prop: item,
              label: this.formatTableLabel(item).replaceAll('Per', 'per').replaceAll('Gal', '(gal)').replaceAll('Tco2','(tCo2-e)').replaceAll('Km', '(km)').replaceAll('Mwh','MWh').replaceAll('No ', 'No. '),
            };
          });
          return columns;
        } else {
          return [];
        }
      },

    paginatedData() {
      const start = (this.current_page - 1) * this.perPageCount;
      const end = this.current_page * this.perPageCount;
      return this.selectedTableData.slice(start, end);
    },

    },

    data() {
      return {
        show: this.open_modal,

        hasSubTable: false, // check if popupData has sub table or not

        comment: null, // comment of snapshot report

        popupData: null, // received data from snapshot report (parent component)
        subPopData: null, // received data from snapshot report (parent component)

        tableList: [], // list of table names in popupData
        selectedTable: null, // selected table name from popupData to show
        selectedTableData: [], // selected table data from popupData to show

        upperTableList: [], // list of table names in popupData
        selectedUpperTable: null, // selected table name from popupData to show

        current_page: 1,
        page_size: 50,
        total_items: 0,
        perPageCount: 50,

      };
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      open_modal: function (newVal) {
        this.show = newVal;
        if (newVal == true) {
          this.comment = this.data_comment;
          this.popupData = this.data_modal;
          // console.log("watch to update popupData", this.popupData);
          // console.log("watch to update comment", this.comment);
          
        }
      },

      perPageCount: function (newVal) {
        this.current_page = 1;
        this.perPageCount = parseInt(newVal);
      },

      popupData: {
        handler: 'updateUpperTableNames',
        deep: true,
      },

      subPopData: {
        handler: 'updataSubPopupTableData',
        deep: true,
      },



      
    },
    methods: {

      /**
       * Formats a table label by converting it to title case and replacing underscores with spaces.
       *
       * @param {string} label - The label to be formatted.
       * @returns {string} - The formatted label.
       *
       * Description:
       * - Converts the label to lowercase and splits it by underscores.
       * - Capitalizes the first letter of each word.
       * - Joins the words with spaces to form the formatted label.
       */
      formatTableLabel(label) {

        let splitLabel = label.toLowerCase().split("_");
        for (let i = 0; i < splitLabel.length; i++) {
          splitLabel[i] = splitLabel[i].replace(/\b\w/g, char => char.toUpperCase());
        }
        label = splitLabel.join(" ");
        return label;
      },


      updataSubPopupTableData() {

        this.updateTableNames();

      },

      // update data into popup window table
      /**
       * Updates the table names and data in the popup window table.
       *
       * Description:
       * - Checks if `subPopData` contains any items.
       * - If `subPopData` is not empty:
       *   - Maps through the `subPopData` to extract `table_name` values.
       *   - Filters out any undefined `table_name` values.
       *   - Updates the `tableList` with the filtered table names.
       *   - If there is only one table name available, sets `total_items`, `selectedTable`, and `selectedTableData` to display the data directly.
       * - If `subPopData` is empty, sets `tableList` to an empty array.
       * - Catches and logs any errors that occur during the process.
       */
      updateTableNames() {

        try {
           // get all available table names from popupData
            if(this.subPopData.length > 0) {
              // console.log("subpopupData", this.subPopData);
              
              const data = this.subPopData;
              const tableNames = data.map(item => item.table_name).filter(name => name !== undefined);
          
              // console.log("sub tableNames", tableNames);
              this.tableList = tableNames;

              // when available table names are only one, skip choose table to display, show data directly
              if (this.tableList.length == 1) {
                this.total_items = data[0].data.length;
                this.selectedTable = this.tableList[0];
                this.selectedTableData = data[0].data;
              }

 
            } else {
              this.tableList = [];
            }

        } catch (error) {
          console.error("error in updateTableNames", error);
        }

       
      },

      /**
       * Updates the list of upper table names from the `popupData`.
       *
       * Description:
       * - Checks if `popupData` contains any items.
       * - If `popupData` is not empty:
       *   - Maps through the `popupData` to extract `table_name` values.
       *   - Filters out any undefined `table_name` values.
       *   - Updates the `upperTableList` with the filtered table names.
       * - If `popupData` is empty, sets `tableList` to an empty array.
       * - Catches and logs any errors that occur during the process.
       */
      updateUpperTableNames() {

        try {
          // get all available table names from popupData
            if(this.popupData.length > 0) {
              // console.log("popupData", this.popupData);
              
              const data = this.popupData;
              const tableNames = data.map(item => item.table_name).filter(name => name !== undefined);
          
              // console.log("upper tableNames", tableNames);
              this.upperTableList = tableNames;

              
            } else {
              this.tableList = [];
            }

        } catch (error) {
          console.error("error in updateTableNames", error);
        }


        },

      /**
       * Updates the sub-table data based on the selected upper table.
       *
       * Description:
       * - Sets `hasSubTable` to `true` and `selectedTable` to `null`.
       * - Filters the `popupData` to find the data corresponding to the `selectedUpperTable`.
       * - Updates the `subPopData` with the data of the selected upper table.
       * - Catches and logs any errors that occur during the process.
       */
      updateSubSelectedTableData() {
        this.hasSubTable = true;
        this.selectedTable = null;
        try {

          const data = this.popupData;
          const selectedTableData = data.filter(item => item.table_name === this.selectedUpperTable);

          // console.log("length of tableList", this.tableList.length);

          this.subPopData = selectedTableData[0].data;
          // console.log("send subPopData", this.subPopData);
          
          
        } catch (error) {
          console.error("error in updateSelectedTableData", error);
        }
        
      },


      /**
       * Updates the data for the selected table.
       *
       * Description:
       * - Checks if `tableList` contains any items.
       * - If `tableList` is not empty:
       *   - Filters the `subPopData` to find the data corresponding to the `selectedTable`.
       *   - Updates `total_items` with the total record count of the selected table.
       *   - Updates `selectedTableData` with the data of the selected table.
       * - If `tableList` is empty, sets `selectedTableData` to an empty array and logs a message.
       * - Catches and logs any errors that occur during the process.
       */
      updateSelectedTableData() {
        try {

          if(this.tableList.length > 0) {
          const data = this.subPopData;

          const selectedTableData = data.filter(item => item.table_name === this.selectedTable);
          // console.table("filter data for table name selectedTableData", selectedTableData[0].data);
          const totalRecord = selectedTableData[0].data.length;
          this.total_items = totalRecord;
          this.selectedTableData = selectedTableData[0].data;

          // console.log("length of tableList", this.tableList.length);

          
        } else {
          this.selectedTableData = [];
          console.log("selectedTableData is empty");
        }

        } catch (error) {
          console.error("error in updateSelectedTableData", error);
        }
        
      },


      changePage(page) {
        this.current_page = page;
      },

      updatePageSize(size) {
        this.perPageCount = size;
        this.current_page = 1;
      },
      
      close() {
        try {
          this.show = false;
          this.selectedTable = null;
          this.selectedTableData = [];
          this.tableList = [];
          this.total_items = 0;
          this.hasSubTable = false;
          this.selectedUpperTable = null;
          this.upperTableList = [];
          this.$emit("close_modal", false);
        } catch (error) {
          console.error("error in close", error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  h5 {
    color:#ffffff;
  }
  .el-dialog__body {
    padding: 30px 10px;
  }
 
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
  
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  </style>
  