<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Breadcrumbs main="Verra Reports" title="default" />
  <div class="container-fluid p-0">
    <div class="row widget-grid">
      <Filter
        :stateProp="stateList"
        @filterHandle="filterHandle"
        :hasCustomDate="true"
        :hasCustomer="false"
        :hasPeriod="true"
        :periodNameProp="`2023 MP`"
      />
      <div class="row widget-grid m-0 px-6">
        <div class="m-0 mt-3 py-3 bg-me-primary border-color-light rounded-4">
          <el-row class="">
            <el-col :span="24">
              <div class="row m-0">
                <!-- search bar -->
                <el-col
                  class="pl-0"
                  :span="$vuetify.display.width >= 450 ? 14 : 24"
                  :class="
                    $vuetify.display.width < 450
                      ? 'x-center p-0 order-3 mt-2'
                      : ''
                  "
                >
                  <el-input
                    size="large"
                    v-model="filter.search"
                    placeholder="Type to search"
                    :prefix-icon="Search"
                    class="table-search"
                  />
                </el-col>
                <el-col
                  class="p-0"
                  :span="$vuetify.display.width >= 450 ? 10 : 24"
                  :class="
                    $vuetify.display.width < 450
                      ? 'x-center pr-1 order-1 width-100-p'
                      : 'd-flex justify-content-end'
                  "
                >
                  <!-- export file button -->
                  <el-button
                    @click="handleExportFile"
                    color="#0D3937"
                    class="height-40 border-color-light rounded-3 mr-1"
                  >
                    <i class="text-white fa fa-cloud-download"></i>
                  </el-button>

                  <!-- apply button -->
                  <el-button
                    @click.stop="handleReload"
                    color="#ddf0ac"
                    class="height-40 rounded-3 ml-1"
                    :class="$vuetify.display.width < 450 ? 'width-100-p' : ''"
                  >
                    <svg class="stroke-icon width-20 height-20">
                      <use
                        href="@/assets/svg/icon-sprite.svg#stroke-refresh"
                      ></use>
                    </svg>
                    <span class="me-primary pl-3"> Apply </span>
                  </el-button>
                </el-col>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <el-row class="m-0 width-100-p mt-3">
        <el-col class="d-flex align-item-center" :span="24">
          <div class="row m-0 width-100-p">
            <div>
              <verraReportTables
                :parentDataFilter="filter"
                :loadingProp="loading"
                :totalRowProp="total_items"
                @tableAction="handleTableAction"
                ref="childComp"
              />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import verraReportTables from "./verraReportTables.vue";
import Filter from "../common/filter.vue";
import XLSX from "xlsx";
import { mapState } from "vuex";

export default {
  data() {
    return {
      // test
      test: "",

      // table
      current_page: 1,
      perPageCount: 100,
      total_items: 100,
      loading: false,

      // Filter
      filter: {
        // customer_id: null,
        startDate: null,
        endDate: null,
        search: null, // Search bar
      },
    };
  },

  computed: {
    ...mapState("auditorReport", {
      ProjectInstance: (state) => state.ProjectInstance,
    }),
    ...mapState("auditorReport", {
      VerraEq1: (state) => state.VerraEq1,
    }),
    ...mapState("auditorReport", {
      VerraEq4: (state) => state.VerraEq4,
    }),
    ...mapState("auditorReport", {
      VerraEq5: (state) => state.VerraEq5,
    }),
    ...mapState("auditorReport", {
      VerraEq7: (state) => state.VerraEq7,
    }),
    ...mapState("auditorReport", {
      VerraEq9: (state) => state.VerraEq9,
    }),

    stateList() {
      return this.states;
    },
  },

  components: {
    verraReportTables,
    Filter,
  },

  methods: {
    filterHandle(event) {
      this.filter = event;
      this.handleApplyFilterData();
    },
    async handleExportFile() {
      const wb = XLSX.utils.book_new();
      this.excelDataList = this.ProjectInstance;
      if (this.excelDataList.length > 0) {
        const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
          header: Object.keys(this.excelDataList[0]),
        });
        XLSX.utils.book_append_sheet(wb, ws, "ProjectInstance");
      } else {
        console.log("ProjectInstance no data");
      }

      this.excelDataList = this.VerraEq1;
      if (this.excelDataList.length > 0) {
        const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
          header: Object.keys(this.excelDataList[0]),
        });
        XLSX.utils.book_append_sheet(wb, ws, "VerraEq1");
      } else {
        console.log("VerraEq1 no data");
      }

      this.excelDataList = this.VerraEq4;
      if (this.excelDataList.length > 0) {
        const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
          header: Object.keys(this.excelDataList[0]),
        });
        XLSX.utils.book_append_sheet(wb, ws, "VerraEq4");
      } else {
        console.log("VerraEq4 no data");
      }
      this.excelDataList = this.VerraEq5;
      if (this.excelDataList.length > 0) {
        const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
          header: Object.keys(this.excelDataList[0]),
        });
        XLSX.utils.book_append_sheet(wb, ws, "VerraEq5");
      } else {
        console.log("VerraEq5 no data");
      }
      this.excelDataList = this.VerraEq7;
      if (this.excelDataList.length > 0) {
        const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
          header: Object.keys(this.excelDataList[0]),
        });
        XLSX.utils.book_append_sheet(wb, ws, "VerraEq7");
      } else {
        console.log("VerraEq7 no data");
      }
      this.excelDataList = this.VerraEq9;
      if (this.excelDataList.length > 0) {
        const ws = XLSX.utils.json_to_sheet(this.excelDataList, {
          header: Object.keys(this.excelDataList[0]),
        });
        XLSX.utils.book_append_sheet(wb, ws, "VerraEq9");
      } else {
        console.log("VerraEq9 no data");
      }
      XLSX.writeFileXLSX(wb, "VerraReport.xlsx");
    },

    handleTableAction(event) {
      if (event.kind == "changePage") {
        this.current_page = event.index;
        this.handleReload();
      } else if (event.kind == "perPageChange") {
        this.current_page = 1;
        this.perPageCount = event.index;
        this.handleReload();
      }
    },
    handleReload() {
      // this.fetchData();
      this.handleApplyFilterData();
    },

    handleApplyFilterData() {
      // console.log("apply filter data");
      this.$refs.childComp.emitToParent(this.filter);
    },
  },
};
</script>

<style scoped>
span {
  color: white;
}
</style>
