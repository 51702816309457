<template>

    <div class="container-fluid p-0">
        <div class="row widget-grid">


            <!-- form start -->
            <form id="input-form">

                <div class="pb-4 pt-3 pl-2 pr-2 mt-3 mb-3 bg-me-primary border-color-light width-100-p rounded-4"
                :class="{'bg-me-primary': isOdd(scenarioID), 'bg-me-primary-1': isEven(scenarioID)}">

                    <div >

                        <el-row>
                            <el-col 
                            :class="$vuetify.display.width < 827
                                    ? 'x-left'
                                    : ''
                                "
                             :span="$vuetify.display.width >= 1619 ? 20 : ($vuetify.display.width >= 1378 ? 20 : ($vuetify.display.width >= 1187 ? 21 : ($vuetify.display.width >= 827 ? 21 : ($vuetify.display.width >= 375 ? 21 : 24))) ) ">
                                <!-- Filter Button -->
                                <estimFilter :key="filterKey" :scenarioId="scenarioID" :applyFilter="applyFilter" ref="childFilter" @updateFilter="updateInputForm" />
                            </el-col>
                            <el-col
                             :class="$vuetify.display.width < 827
                                    ? 'x-right ml-3 mt-3'
                                    : ''
                                "
                                  :span="$vuetify.display.width >= 1619 ? 4 : ($vuetify.display.width >= 1378 ? 4 : ($vuetify.display.width >= 1187 ? 3 : ($vuetify.display.width >= 827 ? 3 : ($vuetify.display.width >= 375 ? 21 : 24))) ) ">
                                <h5>Scenario {{scenarioID}}</h5>
                            </el-col>
                        </el-row>

                        
                        <el-row class="m-0 width-100-p mt-3" gutter="20">
                            <el-col :span="6">
                                <label>Charging type</label>
                                <div id="charging_checkbox" >

                                    <div @click="set_checkBox_ac" >
                                        
                                    
                                        <input class="checkbox" v-model="form.showAC" type="string" >
                                        <img class="checkboximg" id="type_ac"  name="type_ac" 
                                            :src="form.showAC 
                                            ? require('../../../assets/images/dashboard/estimator/Vector-1.png') 
                                            : require('../../../assets/images/dashboard/estimator/Vector.png')" 
                                            alt="Charging Type"
                                        >
                                        <label for="type_ac">AC</label>
                                        <br>
                                    </div>

                                    <div @click="set_checkBox_dc" >
                                        <input class="checkbox" v-model="form.showDC" type="string"  >
                                        <img class="checkboximg" id="type_dc" name="type_dc"
                                            :src="form.showDC 
                                            ? require('../../../assets/images/dashboard/estimator/Vector-1.png') 
                                            : require('../../../assets/images/dashboard/estimator/Vector.png')" 
                                            alt="Charging Type"
                                        >
                                        <label for="type_dc">DC</label>
                                        <br>
                                </div>
                                </div>

                            </el-col>
                            

                        </el-row>

                        <!-- basic parameter -->
                        <el-row class="m-0 width-100-p mt-3" gutter="20">
                            <!-- Grid Emission Intensity (tCO2-e/MWh) -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div >
                                    <label>Grid emission intensity (tCO2-e/MWh)</label>
                                    <div >
                                        <el-input
                                            size="large"
                                            v-model="form.b_gei"
                                            @input="form.b_gei = handleOninput(form.b_gei)" 
                                            @blur="form.b_gei = parseFloat(form.b_gei)"                      
                                            type="number"
                                            setp="0.0001"
                                            style="width: 100%"
                                            placeholder="e.g. 0.5"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                        <!-- <input v-model="form.b_gei" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                            <!-- Annual Emissions Intensity Improvement (%) -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>Annual emissions intensity improvement (%)</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.b_aeii"
                                            @input="form.b_aeii = handleOninput(form.b_aeii)"
                                            @blur="form.b_aeii = parseFloat(form.b_aeii)"
                                            type="number"
                                            setp="0.0001"
                                            style="width: 100%"
                                            placeholder="e.g. 5"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                        <!-- <input v-model="form.b_aeii" class="form-control digits" type="number" placeholder="e.g. 10" step="0.1" required> -->
                                    </div>
                                </div>
                            </el-col>

                        </el-row>

                    

                        <el-row class="m-0 width-100-p mt-3" gutter="20">
                            <!-- Percentage of Onsite Renewables (%) -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>Percentage of onsite renewables (%)</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.b_por"
                                            @input="form.b_por = handleOninput(form.b_por)"
                                            @blur="form.b_por = parseFloat(form.b_por)"
                                            type="number"
                                            setp="0.0001"
                                            style="width: 100%"
                                            placeholder="e.g. 5"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                    <!-- <input v-model="form.b_por" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                            <!-- Fuel Emission Intensity (tCO2-e/gallon) -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>Fuel emission intensity (tCO2-e/gallon)</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.b_fei"
                                            @input="form.b_fei = handleOninput(form.b_fei)"
                                            @blur="form.b_fei = parseFloat(form.b_fei)"
                                            type="number"
                                            setp="0.000000000000000000001"
                                            style="width: 100%"
                                            placeholder="e.g. 0.0087541"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                    <!-- <input v-model="form.b_fei" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                        </el-row>
                        <!-- basic parameter END-->
                    </div>

                </div>

                <div>

                    <div v-if="form.showAC" class="pb-4 pl-2 pr-2 mt-3 bg-me-primary border-color-light width-100-p rounded-4"
                    :class="{'bg-me-primary': isOdd(scenarioID), 'bg-me-primary-1': isEven(scenarioID)}">

                        <el-row class="m-0 width-100-p mt-3">
                            <el-col class="d-flex align-item-center px-2" :span="12">
                                <h5 class="text-white mt-3 ml-1">AC charging parameters</h5>
                            </el-col>
                        </el-row>

                        <!-- AC charging parameter -->
                        <el-row class="m-0 width-100-p mt-3" gutter="20">
                            <!-- Annual usage (MWh) -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div >
                                    <label>Annual usage (MWh)</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.ac_au"
                                            @input="form.ac_au = handleOninput(form.ac_au)"
                                            @blur="form.ac_au = parseFloat(form.ac_au)"
                                            type="number"
                                            setp="0.0001"
                                            style="width: 100%"
                                            placeholder="e.g. 1000"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                        <!-- <input v-model="form.ac_au" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                            <!-- Annual Growth in Usage (%) -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>Annual growth in usage (%)</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.ac_agu"
                                            @input="form.ac_agu = handleOninput(form.ac_agu)"
                                            @blur="form.ac_agu = parseFloat(form.ac_agu)"
                                            type="number"
                                            setp="0.0001"
                                            style="width: 100%"
                                            placeholder="e.g. 10"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                        <!-- <input v-model="form.ac_agu" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                        </el-row>

                        <el-row class="m-0 width-100-p mt-3" gutter="20">
                            <!-- MPG (miles/gallon) -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>MPG (miles/gallon)</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.ac_mpg"
                                            @input="form.ac_mpg = handleOninput(form.ac_mpg)"
                                            @blur="form.ac_mpg = parseFloat(form.ac_mpg)"
                                            type="number"
                                            setp="0.000000000000001"
                                            style="width: 100%"
                                            placeholder="e.g. 30.70828"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                    <!-- <input v-model="form.ac_mpg" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                            <!-- AFEC (kWh/100 miles) -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>AFEC (kWh/100 miles)</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.ac_afec"
                                            @input="form.ac_afec = handleOninput(form.ac_afec)"
                                            @blur="form.ac_afec = parseFloat(form.ac_afec)"
                                            type="number"
                                            setp="0.000000000000001"
                                            style="width: 100%"
                                            placeholder="e.g. 28.5809"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                    <!-- <input v-model="form.ac_afec" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                        </el-row>

                        <el-row class="m-0 width-100-p mt-3" gutter="20">
                            <!-- Technology improvement rate -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>Technology improvement rate</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.ac_tir"
                                            @input="form.ac_tir = handleOninput(form.ac_tir)"
                                            @blur="form.ac_tir = parseFloat(form.ac_tir)"
                                            type="number"
                                            step="0.0001"
                                            style="width: 100%"
                                            placeholder="e.g. 1"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                    <!-- <input v-model="form.ac_tir" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                            <!-- Charger efficiency -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>Charger efficiency</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.ac_ce"
                                            @input="form.ac_ce = handleOninput(form.ac_ce)"
                                            @blur="form.ac_ce = parseFloat(form.ac_ce)"
                                            type="number"
                                            step="0.0001"
                                            style="width: 100%"
                                            placeholder="e.g. 100"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                    <!-- <input v-model="form.ac_ce" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                        </el-row>
                        <!-- AC charging parameter END -->
                    </div>

                </div>

                <div>


                    <div v-if="form.showDC" class="pb-4 pl-2 pr-2 mt-3 bg-me-primary border-color-light width-100-p rounded-4"
                        :class="{'bg-me-primary': isOdd(scenarioID), 'bg-me-primary-1': isEven(scenarioID)}">
                        <el-row class="m-0 width-100-p mt-3">
                            <el-col class="d-flex align-item-center px-2" :span="12">
                                <h5 class="text-white mt-3 ml-1">DC charging parameters</h5>
                            </el-col>
                        </el-row>
                            

                        <!-- DC charging parameter -->
                        <el-row class="m-0 width-100-p mt-3" gutter="20">
                            <!-- Annual usage (MWh) -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div >
                                    <label>Annual usage (MWh)</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.dc_au"
                                            @input="form.dc_au = handleOninput(form.dc_au)"
                                            @blur="form.dc_au = parseFloat(form.dc_au)"
                                            type="number"
                                            step="0.0001"
                                            style="width: 100%"
                                            placeholder="e.g. 1000"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                        <!-- <input v-model="form.dc_au" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                            <!-- Annual Growth in Usage (%) -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>Annual growth in usage (%)</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.dc_agu"
                                            @input="form.dc_agu = handleOninput(form.dc_agu)"
                                            @blur="form.dc_agu = parseFloat(form.dc_agu)"
                                            type="number"
                                            step="0.0001"
                                            style="width: 100%"
                                            placeholder="e.g. 10"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                        <!-- <input v-model="form.dc_agu" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                        </el-row>

                        <el-row class="m-0 width-100-p mt-3" gutter="20">
                            <!-- MPG (miles/gallon) -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>MPG (miles/gallon)</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.dc_mpg"
                                            @input="form.dc_mpg = handleOninput(form.dc_mpg)"
                                            @blur="form.dc_mpg = parseFloat(form.dc_mpg)"
                                            type="number"
                                            step="0.000000000000000000001"
                                            style="width: 100%"
                                            placeholder="e.g. 30.60337"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                    <!-- <input v-model="form.dc_mpg" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                            <!-- AFEC (kWh/100 miles) -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>AFEC (kWh/100 miles)</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.dc_afec"
                                            @input="form.dc_afec = handleOninput(form.dc_afec)"
                                            @blur="form.dc_afec= parseFloat(form.dc_afec)"
                                            type="number"
                                            step="0.000000000000000000001"
                                            style="width: 100%"
                                            placeholder="e.g. 30.30432"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                    <!-- <input v-model="form.dc_afec" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                        </el-row>

                        <el-row class="m-0 width-100-p mt-3" gutter="20">
                            <!-- Technology improvement rate -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>Technology improvement rate</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.dc_tir"
                                            @input="form.dc_tir = handleOninput(form.dc_tir)"
                                            @blur="form.dc_tir= parseFloat(form.dc_tir)"
                                            type="number"
                                            step="0.0001"
                                            style="width: 100%"
                                            placeholder="e.g. 1"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                    <!-- <input v-model="form.dc_tir" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                            <!-- Charger efficiency -->
                            <el-col :span="$vuetify.display.width >= 1500 ? 12 : 24">
                                <div>
                                    <label>Charger efficiency</label>
                                    <div>
                                        <el-input
                                            size="large"
                                            v-model="form.dc_ce"
                                            @input="form.dc_ce = handleOninput(form.dc_ce)"
                                            @blur="form.dc_ce= parseFloat(form.dc_ce)"
                                            type="number"
                                            step="0.0001"
                                            style="width: 100%"
                                            placeholder="e.g. 92.3"
                                            class="mr-2 table-search"
                                            required  
                                        />
                                    <!-- <input v-model="form.dc_ce" class="form-control digits" type="number" placeholder="e.g. 10" required> -->
                                    </div>
                                </div>
                            </el-col>

                        </el-row>
                        <!-- DC charging parameter END -->

                    </div>

                </div>

                <div>


                    <div >

                        <el-row class="m-0 width-100-p mt-3">
                            <el-col class="d-flex align-item-center" v-if="activeApply <= 1" 
                            :span="$vuetify.display.width >= 1618 ? 2 : ($vuetify.display.width >= 1187 ? 3 : ($vuetify.display.width >= 721 ? 4 : 6))">
                                <!-- <button><span>Apply</span></button> -->
                                <el-button
                                    color="#ddf0ac"
                                    @click="submitForm"
                                    class="height-40 rounded-3"
                                >
                                    <svg class="stroke-icon width-20 height-20">
                                    <use
                                        href="@/assets/svg/icon-sprite.svg#stroke-refresh"
                                    ></use>
                                    </svg>
                                    <span class="me-primary pl-3"> Apply </span>
                                </el-button>
                            </el-col>

                            <el-col class="d-flex align-item-center"
                            :class="$vuetify.display.width < 827
                                    ? 'x-left mt-2'
                                    : ''
                                    ? 'justify-end'
                                    : ''
                                " 
                            :span="$vuetify.display.width >= 1618 ? 2 : ($vuetify.display.width >= 1187 ? 3 : ($vuetify.display.width >= 676 ? 4 : 24))">
                                <!-- <button><span>Reset</span></button> -->
                                <el-button
                                    color="#0D3937"
                                    @click="resetForm"
                                    class="height-40 rounded-3 border-color-light"
                                >
                                <svg class="stroke-icon width-20 height-20">
                                    <use
                                        href="@/assets/svg/icon-sprite.svg#estimator_reset_btn"
                                    ></use>
                                    </svg>
                                    <span class="text-white pl-3"> Reset </span>
                                </el-button>
                            </el-col>

                            <el-col v-if="scenarioID > 1" class="d-flex align-item-center" 
                                :class="$vuetify.display.width < 827
                                    ? 'x-left mt-2'
                                    : ''
                                    ? 'justify-end'
                                    : ''
                                "
                            :span="$vuetify.display.width >= 1618 ? 2 : ($vuetify.display.width >= 1187 ? 3 : ($vuetify.display.width >= 676 ? 4 : 24))"
                            >
                                <!-- <button><span>Reset</span></button> -->
                                <el-button
                                    color="#0D3937"
                                    @click="removeForm"
                                    class="height-40 rounded-3 border-color-light"
                                >
                                <svg class="stroke-icon width-20 height-20">
                                    <use
                                        href="@/assets/svg/icon-sprite.svg#estimator_reset_btn"
                                    ></use>
                                    </svg>
                                    <span class="text-white pl-3"> Remove </span>
                                </el-button>
                            </el-col>
                        </el-row>

                    </div>

                </div>


            </form>
      
        </div>

    </div>

</template>

<script>
import {  mapMutations } from "vuex";
import { ElMessageBox } from "element-plus";

import userService from "@/services/user.service";

import estimFilter from "./estimFilter.vue";

export default{

    // receive scenarioID from index component scenarioid
    props: {
        scenarioID: {
            type: Number,
            required: true
        },
        formData: {
            type: Object,
        },
        activeApply: {
            type: Number,
            required: true
        }
    },

    data(){
        return{

            filterKey: 0,

            form: this.formData,

            filter: {
                country: '',
                region: '',
                regionBeforeConvert: '',
            },

            filterData: {},
            }
        },

    computed:  {
        // ...mapState('estimator', {
        //     scenarios: state => state.scenarios
        // }),
    },

    components:{
        estimFilter,
    },
 
    methods: {
        ...mapMutations('estimator', ['set_estimatorData']),
        ...mapMutations('estimator', ['set_ac_and_dc']), // display tables control
        ...mapMutations('estimator', ['set_form']),


        updateFilterKey(){
            this.filterKey++;
        },
        // check scenario id is even or odd
        isEven(id) {
            return id % 2 === 0;
        },

        isOdd(id) {
            return id % 2 !== 0;
        },
        // check scenario id is even or odd
        
        set_checkBox_ac() {
            if (this.form.showAC) {
                this.set_form({
                    id: this.scenarioID,
                    payload: {
                        showAC: false,
                    }
                });
            } else {
                this.set_form({
                    id: this.scenarioID,
                    payload: {
                        showAC: true,
                    }
                });
            }

            this.onCheckboxChange();
        },

        set_checkBox_dc() {
            if (this.form.showDC) {
                this.set_form({
                    id: this.scenarioID,
                    payload: {
                        showDC: false,
                    }
                });
            } else {
                this.set_form({
                    id: this.scenarioID,
                    payload: {
                        showDC: true,
                    }
                });
            }

            // troger checkbox change again when location changed
            this.onCheckboxChange();
        },


        filterBaseData(database, country, chargingType) {
            return database.filter(
                item => 
                item.country === country.toUpperCase() &&
                item.charger_type === chargingType
            );
        },

        resetFilter(){
            this.updateFilterKey();
            this.$refs.childFilter.resetCountry();
        },

        updateInputForm(childForm){
            // console.log(childForm); 
            this.filter.country = childForm.country;
            this.filter.region = childForm.region;
            this.filter.regionBeforeConvert = childForm.regionBeforeConvert;
            // console.log(this.filter);
            },

        onCheckboxChange() {

            // check if filter  is applied 
            if (this.filter.country == "" && this.filter.region == "") {
                // this.checkBoxtoForm();
                return
            } else {
                // this.checkBoxtoForm();
                this.applyFilter('chargingType');
            }
        },

        applyFilter(option) {

            if (this.filter) {

            try {

            const { country, region, regionBeforeConvert } = this.filter;
            const data = this.filterData;

            
            // handle apply filter when choose charging type
            if (option === 'chargingType' && this.form.showAC){
            
                let basedata = data.base_data;
                let filteredBasedata = this.filterBaseData(basedata, country, "AC");
                const selectedData = filteredBasedata[0];
                
                this.set_form({
                    id: this.scenarioID,
                    
                    
                    payload: {

                        b_fei : selectedData.fuel_emission_intensity,
                        ac_afec : selectedData.afec,
                        ac_mpg : selectedData.mpg,
                        ac_ce : selectedData.charger_efficiency * 100,
                        ac_tir : selectedData.tech_improvement_rate,

                    },
                    
                });

            }

            // handle apply filter when choose charging type
            if (option === 'chargingType' && this.form.showDC){
              
                let basedata = data.base_data;
                let filteredBasedata = this.filterBaseData(basedata, country, "DC");
                const selectedData = filteredBasedata[0];
                // return console.log(JSON.stringify(filteredBasedata));

                this.set_form({
                    id: this.scenarioID,
                    payload: {
                        b_fei : selectedData.fuel_emission_intensity,
                        dc_afec : selectedData.afec,
                        dc_mpg : selectedData.mpg,
                        dc_ce : selectedData.charger_efficiency * 100,
                        dc_tir : selectedData.tech_improvement_rate,
                    },
                });

            }



            // handle apply filter when choose location is UK
            if (option === 'location' && country === 'UK'){
                let ukdata = data.UK;
                // return console.log(region,ukdata);
                this.set_form({
                    id: this.scenarioID,
                    payload: {
                        b_gei : ukdata[region],
                        // add region as location into form
                        location : regionBeforeConvert,
                    },

                });

            }

            // handle apply filter when choose location Is AU
            if (option === 'location' && country === 'Australia'){
                let audata = data.AU;
                // return console.log(region,audata);
                this.set_form({
                    id: this.scenarioID,
                    payload: {
                        b_gei : audata[region],

                        // add region as location into form
                        location : regionBeforeConvert,
                    },

                });

            }

            // call check box change when click on filter
            this.onCheckboxChange();
                
            } catch (error) {
                return console.log("error when apply filter", error);
            }
        } else {
            return console.log("filter is not apply");
        }

        },


        handleOninput(value){
            let str = value;
            let len1 = str.substr(0, 1);
            let len2 = str.substr(1, 1);

            // If the first character is '0' and the second is not '.', replace the first '0'
            if (str.length > 1 && len1 == 0 && len2 != ".") {
                str = str.substr(1, 1);
            }

            // The first character cannot be '.' or '+' or '-'
            if (len1 == "." || len1 == "+" || len1 == "-") {
                ElMessageBox.alert(
                    '<strong><span>Sorry, inputs cannot be negative!</span></strong>',
                    'Invalid Inputs',
                    {
                    dangerouslyUseHTMLString: true,
                    }
                );
                // alert("Inputs cannot be negative!");
                str = "";
            }

            // Only one decimal point is allowed
            if (str.indexOf(".") != -1) {
            let str_ = str.substr(str.indexOf(".") + 1);
            if (str_.indexOf(".") != -1) {
                str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
            }
            }

            // Keep only numbers and decimal points
            return str.replace(/[^\d.]+/g, "");
        },

        // check if form is all filled before submite

        isFormFilled() {
            const basicFieldsFilled = this.validateBasicFields();

            if (this.form.showAC && !this.form.showDC) {
                return basicFieldsFilled && this.validateACFields();
            }

            if (this.form.showDC && !this.form.showAC) {
                return basicFieldsFilled && this.validateDCFields();
            }

            if (this.form.showAC && this.form.showDC) {
                return basicFieldsFilled && this.validateACFields() && this.validateDCFields();
            }

            // Return false if neither AC nor DC is selected
            return false;
    },

            validateBasicFields() {
                // Check if basic fields (b_) are filled
                return this.form.b_gei !== '' && this.form.b_aeii !== '' && this.form.b_por !== null && this.form.b_fei !== '';
            },

            validateACFields() {
                // Check if AC fields (ac_) are filled
                return this.form.ac_au !== '' && this.form.ac_agu !== '' && this.form.ac_mpg !== '' && this.form.ac_afec !== '' && this.form.ac_tir !== '' && this.form.ac_ce !== '';
            },

            validateDCFields() {
                // Check if DC fields (dc_) are filled
                return this.form.dc_au !== '' && this.form.dc_agu !== '' && this.form.dc_mpg !== '' && this.form.dc_afec !== '' && this.form.dc_tir !== '' && this.form.dc_ce !== '';
            },


        async submitForm() {

            if (!this.form.showAC && !this.form.showDC ) {
                // alert("Charging type is missing!");
                ElMessageBox.alert(
                    '<strong><span>Charging type is missing!</span></strong>',
                    'Invalid Inputs',
                    {
                    dangerouslyUseHTMLString: true,
                    }
                );
            }
            else {

                this.set_ac_and_dc({ showAC: this.form.showAC, showDC: this.form.showDC }); // control display table for ac/dc or both

                if(this.isFormFilled()) {

                await userService.DashboardEstimator(this.form).then(
                    
                    
                    (res) => {
                        // console.log(this.form);
                        if (res.data != null) {
                            // console.log(res.data);
                            res.data.scenario_id = this.scenarioID;
                            console.log(res.data);
                            this.set_estimatorData(res.data);
                        } else {
                            console.log("empty response data, check error");
                        }
                    }
                ).then(
                    (res) => {
                        this.$emit('submitForm');
                        return res;
                    }
                ).catch((err)=>{
                    console.log("error message: ", err);
                    return ElMessageBox.alert(
                        '<strong><span>Some fields of form is missing!</span></strong>',
                         'Invalid Inputs',
                        { dangerouslyUseHTMLString: true,});                   
                }).finally(()=>{
                    console.log("request done");
                });
                } else {
                    return ElMessageBox.alert(
                    '<strong><span>Some fields of form is missing!</span></strong>',
                    'Invalid Inputs',
                    {dangerouslyUseHTMLString: true,});
                }
            }
        },


        // reset input form
        resetForm() {

            this.resetFilter();

            this.$emit('resetForm');
                },

        // remove scenario of input form
        removeForm() {
            this.$emit('removeForm');
        }

    },

    mounted(){
        userService.DashboardEstimatorFilter().then(
          (res) => {
            // console.log(JSON.stringify(res.data)); // filter data
            this.filterData = res.data;
          }
        );  
    },


}
</script>

<style scoped>
h2 {
    color: white;
}
h5 {
    color: white;
}
span {
    color: white;
}
label{
    color: white;
    font-size:medium;
}
.checkbox{
    /* margin-right: 11.17px;
    width: 30px;
    height: 16px; */
    display: none;
}
.checkboximg{
    width: 18px;
    height: 18px;
    margin-right: 7px;
    position: relative;
}

</style>
