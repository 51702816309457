const state = {
    estimatorData : null,
    inputFormData : null,
    ac_and_dc: false,

    // scenarios for input form
    scenarios: [
        // {id: 1, name: 'Scenario 1', data: {}},
    ],

    // scenarios: response data for all scenarios
    scenarioResults: [],

    // input form data
    form: {
        // AC / DC form contro
        showAC: false,
        showDC: false,
        // basic parameter 
        b_gei: '', // Grid Emi
        b_aeii: 5, // Annual  
        b_por: 0, //  Percenta
        b_fei: '', // Fuel Emi
        // AC charging paramet
        ac_au: 1000, // Annual u
        ac_agu: 10, // Annual 
        ac_mpg: '', // MPG (mi
        ac_afec: '', // AFEC (
        ac_tir: '', // Technol
        ac_ce: 100, // Charger
        // DC charging paramet
        dc_au: 1000, // Annual u
        dc_agu: 10, // Annual 
        dc_mpg: '', // MPG (mi
        dc_afec: '', // AFEC (
        dc_tir: '', // Technol
        dc_ce: '', // Charger 
        location: 'null',
    },

    



};

const mutations = {
    // scenario results data
    reset_scenario_result(state){
        state.scenarioResults = [];
    },

    // set data to scenario results
    add_scenario_result(state, {id, responseData}) {
        const existingResult = state.scenarioResults.find((result) => result.id === id);

        if(existingResult) {
            // update existing result
            existingResult.data = responseData;
        } else {
            // add new result
            state.scenarioResults.push({
                scenario_id: id,
                data: responseData,
            });
        }
    },

    // data for input form
    set_form(state, {id, payload}) {
        const scenario = state.scenarios.find((scenario) => scenario.id === id);
        if (scenario) {
            Object.keys(payload).forEach((key) => {
              if (key in scenario.data) {
                scenario.data[key] = payload[key];
              }
            });
            }
        },
    
    // reset input form
    set_reset_from(state, {id, payload}) {
        const scenario = state.scenarios.find((scenario) => scenario.id === id);
        payload = state.form;
        if (scenario) {
            Object.keys(payload).forEach((key) => {
              if (key in scenario.data) {
                scenario.data[key] = payload[key];
              }
            });
            }
    },

    // data from api response, used in estimaOutput and estimatorCharts
    set_estimatorData(state, data) {
        state.estimatorData = data;
        // console.log(data); 
    },

    // estimator  data reset
    reset_estimatorData(state){
        state.estimatorData = null;
    },

    set_inputFormData(state,data) {
        state.inputFormData = data;
    },

    // control switch display table on estimaOutput
    set_ac_and_dc(state,{showAC,showDC}){
        state.ac_and_dc = showAC && showDC;
    },

    // add more scenarios for input form
    set_scenario(state) {
        const newId = state.scenarios.length > 0 ? Math.max(...state.scenarios.map(scenario => scenario.id)) + 1 : 1;
        state.scenarios.push({ 
            id: newId, 
            name: "Scenario ${newId}", 
            data:{...state.form}});
    },

    // reset all scenarios
    set_reset_scenario(state) {
        state.scenarios = [];
    },

    // removeScenario
    set_remove_scenario(state, id) {
        state.scenarios = state.scenarios.filter(scenario => scenario.id !== id);
    },

};

export default {
    namespaced: true,
    state,
    mutations,
}





