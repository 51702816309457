


export const estimatorChart = {
    series: [
        {
            name: "Project Emission (tCO2-e)",
            group:"year",
            type: "bar",
            // data: [350, 200, 500, 360, 485, 523, 380, 100, 200, 296],
            data: [],
            color: "#DDF0AC",
        },
        {
            name: "Abatement (tCO2-e)",
            group:"year",
            type: "bar",
            // data: [440, 505, 414, 671, 227, 413, 201, 352, 452, 320],
            data: [],
            color: "#78BE20",
        },
        {
            name: "Abatement per MWh",
            group:"year",
            type: "line",
            // data: [350, 200, 500, 360, 485, 523, 380, 100, 200, 296],
            data: [],
            color: "#E73B30",
        },
    ],
    chartOptions: {
        title: {
            text: "AC",
            align: 'center',
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },

        },
        chart: {
            type: 'line',
            height: 350,
            stacked: true, // bar two in one or not
            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '35%',
                // endingShape: 'rounded',
                dataLabels : {
                    position: "top",
                },
            },
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [2],
            
        },
        stroke: {
            // show: true,
            width: [0, 0, 4],
            // colors: ['transparent']
        },
        labels: [
            "Year 0",
            "Year 1",
            "Year 2",
            "Year 3",
            "Year 4",
            "Year 5",
            "Year 6",
            "Year 7",
            "Year 8",
            "Year 9",
            "Year 10",
        ],
        // labels: [],
        xaxis: {
            title: {
                text: "location A",
                align: 'center',
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },

            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                  
                },
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
            },
         
        },
        yaxis: [{
                seriesName: "Project Emission (tCO2-e)",
                decimalsInFloat: 0,
                tickAmount: 5,
                tickPlacement: "between",
                title: {
                    text: "tCO2-e",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                labels: {
                    style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
            {   
                show: false,
                seriesName: "Abatement (tCO2-e)",
                decimalsInFloat: 0,
                tickAmount: 5,
                tickPlacement: "between",
                labels: {
                    style: {
                        colors: "#78BE20",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                // opposite: true,
                title: {
                    text: "tCO2-e",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
            {
                seriesName: "Abatement per MWh",
                decimalsInFloat: 4,
                tickAmount: 5,
                tickPlacement: "between",
                labels: {
                    style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                opposite: true,
                title: {
                    text: "tCO2-e/MWh",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
        ],
        legend: {
            position: "top",
            horizontalAlign: "center",
            labels: {
                colors: "#FFFFFF",
            },
            markers: {
                fillColors: ["#78BE20", "#DDF0AC", "#E73B30" ],
            },
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: [{
                    formatter: function(val) {
                        return val + " tCO2-e"
                    }
                },
                {
                    formatter: function(val) {
                        return val + " tCO2-e"
                    }
                }
            ]
        }
    },
};

// export const estimatorChartTwo = {
//     series: [
//         {
//             name: "Project Emission (tCO2-e)",
//             group:"year",
//             type: "bar",
//             // data: [350, 200, 500, 360, 485, 523, 380, 100, 200, 296],
//             data: [],
//             color: "#DDF0AC",
//         },
//         {
//             name: "Abatement (tCO2-e)",
//             group:"year",
//             type: "bar",
//             // data: [440, 505, 414, 671, 227, 413, 201, 352, 452, 320],
//             data: [],
//             color: "#78BE20",
//         },
//         {
//             name: "Abatement per MWh",
//             group:"year",
//             type: "line",
//             // data: [350, 200, 500, 360, 485, 523, 380, 100, 200, 296],
//             data: [],
//             color: "#E73B30",
//         },
//     ],
//     chartOptions: {
//         title: {
//             text: "DC",
//             align: 'center',
//                 style: {
//                     color: "#FFFFFF",
//                     fontSize: "14px",
//                     fontWeight: "bold",
//                     fontFamily: "Helvetica, Arial, sans-serif",
//                 },

//         },
//         chart: {
//             type: 'line',
//             height: 350,
//             stacked: true,
//             zoom: {
//                 enabled: false,
//             },
//         },
//         plotOptions: {
//             bar: {
//                 horizontal: false,
//                 columnWidth: '35%',
//                 // endingShape: 'rounded',
//                 dataLabels : {
//                     position: "top",
//                 },
//             },
//         },
//         dataLabels: {
//             enabled: true,
//             enabledOnSeries: [2],

//         },
//         stroke: {
//             // show: true,
//             width: [0, 0, 4],
//             // colors: ['transparent']
//         },
//         labels: [
//             "Year 0",
//             "Year 1",
//             "Year 2",
//             "Year 3",
//             "Year 4",
//             "Year 5",
//             "Year 6",
//             "Year 7",
//             "Year 8",
//             "Year 9",
//             "Year 10",
//         ],
//         // labels: [],
//         xaxis: {
//             title: {
//                 text: "location b",
//                 align: 'center',
//                     style: {
//                         color: "#FFFFFF",
//                         fontSize: "14px",
//                         fontWeight: "bold",
//                         fontFamily: "Helvetica, Arial, sans-serif",
//                     },

//             },
//             labels: {
//                 style: {
//                     colors: "#FFFFFF",
//                     fontSize: "12px",
//                     fontFamily: "Helvetica, Arial, sans-serif",
//                     fontWeight: 400,

//                 },
//                 rotate: -45,
//                 rotateAlways: true,
//                 hideOverlappingLabels: false,
//             },

//         },
//         yaxis: [{
//                 seriesName: "Project Emission (tCO2-e)",
//                 decimalsInFloat: 0,
//                 tickAmount: 5,
//                 tickPlacement: "between",
//                 title: {
//                     text: "tCO2-e",
//                     style: {
//                         color: "#FFFFFF",
//                         fontSize: "14px",
//                         fontWeight: "bold",
//                         fontFamily: "Helvetica, Arial, sans-serif",
//                     },
//                 },
//                 labels: {
//                     style: {
//                         colors: "#FFFFFF",
//                         fontSize: "12px",
//                         fontFamily: "Helvetica, Arial, sans-serif",
//                         fontWeight: 400,
//                     },
//                 },
//                 axisBorder: {
//                     show: false,
//                     color: "#FFFFFF",
//                 },
//                 axisTicks: {
//                     show: false,
//                     color: "#FFFFFF",
//                 },
//             },
//             {
//                 show: false,
//                 seriesName: "Abatement (tCO2-e)",
//                 decimalsInFloat: 0,
//                 tickAmount: 5,
//                 tickPlacement: "between",
//                 labels: {
//                     style: {
//                         colors: "#78BE20",
//                         fontSize: "12px",
//                         fontFamily: "Helvetica, Arial, sans-serif",
//                         fontWeight: 400,
//                     },
//                 },
//                 // opposite: true,
//                 title: {
//                     text: "tCO2-e",
//                     style: {
//                         color: "#FFFFFF",
//                         fontSize: "14px",
//                         fontWeight: "bold",
//                         fontFamily: "Helvetica, Arial, sans-serif",
//                     },
//                 },
//                 axisBorder: {
//                     show: false,
//                     color: "#FFFFFF",
//                 },
//                 axisTicks: {
//                     show: false,
//                     color: "#FFFFFF",
//                 },
//             },
//             {
//                 seriesName: "Abatement per MWh",
//                 decimalsInFloat: 4,
//                 tickAmount: 5,
//                 tickPlacement: "between",
//                 labels: {
//                     style: {
//                         colors: "#FFFFFF",
//                         fontSize: "12px",
//                         fontFamily: "Helvetica, Arial, sans-serif",
//                         fontWeight: 400,
//                     },
//                 },
//                 opposite: true,
//                 title: {
//                     text: "tCO2-e/MWh",
//                     style: {
//                         color: "#FFFFFF",
//                         fontSize: "14px",
//                         fontWeight: "bold",
//                         fontFamily: "Helvetica, Arial, sans-serif",
//                     },
//                 },
//                 axisBorder: {
//                     show: false,
//                     color: "#FFFFFF",
//                 },
//                 axisTicks: {
//                     show: false,
//                     color: "#FFFFFF",
//                 },
//             },
//         ],
//         legend: {
//             position: "top",
//             horizontalAlign: "left",
//             labels: {
//                 colors: "#FFFFFF",
//             },
//             markers: {
//                 fillColors: ["#78BE20", "#DDF0AC", "#E73B30" ],
//             },
//         },
//         fill: {
//             opacity: 1
//         },
//         tooltip: {
//             y: [{
//                     formatter: function(val) {
//                         return val + "tCO2-e"
//                     }
//                 },
//                 {
//                     formatter: function(val) {
//                         return val + " tCO2-e/MWh"
//                     }
//                 }
//             ]
//         }
//     },
// };

export const estimatorChartS1 = {
    series: [
        // Series data will be dynamically added here
    ],
    chartOptions: {
        title: {
            text: "Emission Analysis by Scenario",
            align: 'center',
            style: {
                color: "#FFFFFF",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Helvetica, Arial, sans-serif",
            },
        },
        chart: {
            height: 350,
            stacked: true,
            // stackType: 'normal',

            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                type: 'bar',
                // horizontal: false,
                columnWidth: '75%',
                dataLabels : {
                    hideOverflowingLabels: true,
                    position: "top",

                },
            },
        },
        dataLabels: {
            // enabled: true,
            enabledOnSeries: [],
            offsetY: -30,
            style: {
                fontSize: "9px",
                colors: ["#fff"], // Use white for contrast if inside a bar
            },
            formatter: function (val, opts) {
                // Use series name for labels
                const seriesIndex = opts.seriesIndex;
                const seriesName = opts.w.globals.seriesNames[seriesIndex];
              
                // Replace and split the series name to create multi-line labels
                const formattedLabel = seriesName
                  .replaceAll("Scenario", "S")
                  .replaceAll("Abatement", "")
                  .replaceAll("AC Abatement", "")
                  .replaceAll("DC Abatement", "")
                  .replaceAll(" ", "")
                  .split(/(?=[A, D])/); // Split at uppercase letters to create multi-line labels
              
                return formattedLabel;
              },
            dropShadow: {
                enabled: false,
            },
            background: {
                enabled: false,
            },
            offsetX: 0,

            distributed: true,

        },
        stroke: {
            width: 1,
            colors: ['#0D3937']
        },
        
        xaxis: {
            type:'category',
            categories: [
            "Year 0",
            "Year 1",
            "Year 2",
            "Year 3",
            "Year 4",
            "Year 5",
            "Year 6",
            "Year 7",
            "Year 8",
            "Year 9",
            "Year 10",
                        ],
            title: {
                text: "",
                align: 'center',
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
            },
        },
        yaxis: [
        ],
        legend: {
            position: "top",
            horizontalAlign: "center",
            labels: {
                colors: "#FFFFFF",
            },
            customLegendItems: [
                'Project Emission',
                'Abatement'
            ],
            markers: {
                fillColors: ["#78BE20", "#DDF0AC"],
            },
            grid: {
                padding: {
                  bottom: 20
                }
              }
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: [
                // {
                //     formatter: function(val) {
                //         return val + " tCO2-e";
                //     },
                // },
                // {
                //     formatter: function(val) {
                //         return val + " tCO2-e/MWh";
                //     },
                // },
            ],
        },
    },
};

export const estimatorChartS1Two = {
    series: [
        // Series data will be dynamically added here
    ],
    chartOptions: {
        title: {
            text: "Abatement per MWh",
            align: 'center',
            style: {
                color: "#FFFFFF",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Helvetica, Arial, sans-serif",
            },
        },
        chart: {
            height: 350,
            stacked: false,
            // stackType: 'normal',

            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                barHeight: '100%',
                horizontal: false,
                columnWidth: '75%',
                dataLabels: {
                    position: "top",
                },
            },
        },
        colors: ['#33b2df', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
            '#f48024', '#69d2e7'
        ],
        dataLabels: {
            enabled: true,
            offsetY: -30,
            style: {
                fontSize: "10px",
                colors: ["#fff"], // Use white for contrast if inside a bar
            },
            formatter: function (val, opts) {
              // Use series name for labels
              const seriesIndex = opts.seriesIndex;
              const seriesName = opts.w.globals.seriesNames[seriesIndex];
              return seriesName.replaceAll("Scenario", "S").replaceAll(" ","").split(/(?=[A, D])/);
            },
            dropShadow: {
                enabled: false,
            },
            background: {
                enabled: false,
            },
            offsetX: 0,

            distributed: true,
        },
        stroke: {
            width: 1,
            colors: ['#0D3937']
            // width: [0,0,4,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,],
        },

        xaxis: {
            type:'category',
            categories: [
            "Year 0",
            "Year 1",
            "Year 2",
            "Year 3",
            "Year 4",
            "Year 5",
            "Year 6",
            "Year 7",
            "Year 8",
            "Year 9",
            "Year 10",
                        ],
            title: {
                text: "",
                align: 'center',
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },
            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,
                },
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
            },
        },
        yaxis: [
            
        ],
        legend: {
            position: "top",
            horizontalAlign: "center",
            labels: {
                colors: "#FFFFFF",
            },
            markers: {
                // fillColors: ["#78BE20"],
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: [
                // {
                //     formatter: function(val) {
                //         return val + " tCO2-e";
                //     },
                // },
                // {
                //     formatter: function(val) {
                //         return val + " tCO2-e/MWh";
                //     },
                // },
            ],
        },
    },
};

export const estimatorChartSTotal = {
    series: [
        // Series data will be dynamically added here
    ],
    chartOptions: {
        title: {
            text: "Emission Analysis by Scenario",
            align: 'center',
            style: {
                color: "#FFFFFF",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Helvetica, Arial, sans-serif",
            },
        },
        chart: {
            height: 350,
            stacked: true,
            // stackType: 'normal',

            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '30%',
                dataLabels : {
                    hideOverflowingLabels: false,
                    position: "top",
                    // orientation: 'vertical',

                },
            },
        },
        dataLabels: {
            enabled: true,
            // enabledOnSeries: [],
            offsetY: -15,
            style: {
                fontSize: "12px",
                colors: ["#fff"], // Use white for contrast if inside a bar
            },

            formatter: function (val, opts) {
            // Use series name for labels
            const seriesIndex = opts.seriesIndex;
            const dataPointIndex = opts.dataPointIndex;
            const w = opts.w;

            // Calculate the sum of val and the value of the previous series index
            let sum = val;
            if (seriesIndex > 0) {
                const prevVal = w.globals.series[seriesIndex - 1][dataPointIndex];
                sum += prevVal;
            }

            // Get the series name and format it
            const seriesName = w.globals.seriesNames[seriesIndex]
                .replaceAll("Scenario", "S")
                .replaceAll("Abatement", "")
                .replaceAll("AC Abatement", " AC")
                .replaceAll("DC Abatement", " DC")
                .replaceAll(" ", "") + " " + parseFloat(sum).toFixed(2);

            return seriesName.split(/(?=[" "])/);
            },
            
        
            dropShadow: {
                enabled: false,
            },
            background: {
                enabled: false,
            },
            offsetX: 0,

            distributed: false,

        },
        stroke: {
            width: 3,
            colors: ['#0D3937']
        },
        labels: [
            {enabled: false,}
            
        //    "scenario"
        ],
        
        xaxis: {
        },
        yaxis: [
        ],
        legend: {
            position: "top",
            horizontalAlign: "center",
            labels: {
                colors: "#FFFFFF",
            },
            customLegendItems: [
                'Project Emission',
                'Abatement'
            ],
            markers: {
                fillColors: ["#78BE20", "#DDF0AC"],
            },
            grid: {
                padding: {
                  bottom: 20
                }
              }
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            enabled: false,
            y: [
               
            ],
        },
    },
};


export const estimatorChartTwo = {
    series: [
        {
            name: "AC Project Emission (tCO2-e)",
            group:"ac",
            type: "bar",
            // data: [350, 200, 500, 360, 485, 523, 380, 100, 200, 296],
            data: [],
            color: "#DDF0AC",
        },
        {
            name: "AC Abatement (tCO2-e)",
            group:"ac",
            type: "bar",
            // data: [440, 505, 414, 671, 227, 413, 201, 352, 452, 320],
            data: [],
            color: "#78BE20",
        },
        {
            name: "DC Project Emission (tCO2-e)",
            group:"dc",
            type: "bar",
            // data: [350, 200, 500, 360, 485, 523, 380, 100, 200, 296],
            data: [],
            color: "#DDF0AC",
        },
        {
            name: "DC Abatement (tCO2-e)",
            group:"dc",
            type: "bar",
            // data: [440, 505, 414, 671, 227, 413, 201, 352, 452, 320],
            data: [],
            color: "#78BE20",
        },
        // {
        //     name: "Abatement per MWh",
        //     group:"year",
        //     type: "line",
        //     // data: [350, 200, 500, 360, 485, 523, 380, 100, 200, 296],
        //     data: [],
        //     color: "#E73B30",
        // },
    ],
    chartOptions: {
        title: {
            text: "Emission Analysis",
            align: 'center',
                style: {
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },

        },
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '40%',
                // endingShape: 'rounded',
                dataLabels : {
                    position: "top",
                },
            },
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [1,3],
            offsetY: -30,
            style: {
                fontSize: "9px",
                colors: ["#fff"], // Use white for contrast if inside a bar
            },
            formatter: function (val, opts) {
                // Use series name for labels
                const seriesIndex = opts.seriesIndex;
                const seriesName = opts.w.globals.seriesNames[seriesIndex];
              
                // Replace and split the series name to create multi-line labels
                const formattedLabel = seriesName
                  .replaceAll("Scenario", "S")
                  .replaceAll("Abatement", "")
                  .replaceAll("AC Abatement", "")
                  .replaceAll("DC Abatement", "")
                  .replaceAll(" ", "").replaceAll("(tCO2-e)", "")
                  .split(/(?=[A, D])/); // Split at uppercase letters to create multi-line labels
              
                return formattedLabel;
              },
            dropShadow: {
                enabled: false,
            },
            background: {
                enabled: false,
            },
            offsetX: 0,

            distributed: true,

        },
        stroke: {
            // show: true,
            width: 1,
            colors: ['#0D3937']
        },
        labels: [
            // "Year 0",
            // "Year 1",
            // "Year 2",
            // "Year 3",
            // "Year 4",
            // "Year 5",
            // "Year 6",
            // "Year 7",
            // "Year 8",
            // "Year 9",
            // "Year 10",
        ],
        // labels: [],
        xaxis: {
            type:'category',
            categories: [
                "Year 0",
                "Year 1",
                "Year 2",
                "Year 3",
                "Year 4",
                "Year 5",
                "Year 6",
                "Year 7",
                "Year 8",
                "Year 9",
                "Year 10",
            ],
            title: {
                text: "",
                align: 'center',
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },

            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,

                },
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
            },

        },
        yaxis: [{
                seriesName: "AC Project Emission (tCO2-e)",
                decimalsInFloat: 0,
                tickAmount: 5,
                tickPlacement: "between",
                title: {
                    text: "tCO2-e",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                labels: {
                    style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
            {
                show: false,
                seriesName: "AC Abatement (tCO2-e)",
                decimalsInFloat: 0,
                tickAmount: 5,
                tickPlacement: "between",
                labels: {
                    style: {
                        colors: "#78BE20",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                // opposite: true,
                title: {
                    text: "tCO2-e",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
            {
                show: false,
                seriesName: "DC Project Emission (tCO2-e)",
                decimalsInFloat: 0,
                tickAmount: 5,
                tickPlacement: "between",
                title: {
                    text: "tCO2-e",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                labels: {
                    style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
            {
                show: false,
                seriesName: "DC Abatement (tCO2-e)",
                decimalsInFloat: 0,
                tickAmount: 5,
                tickPlacement: "between",
                labels: {
                    style: {
                        colors: "#78BE20",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                // opposite: true,
                title: {
                    text: "tCO2-e",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
            // {
            //     seriesName: "Abatement per MWh",
            //     decimalsInFloat: 4,
            //     tickAmount: 5,
            //     tickPlacement: "between",
            //     labels: {
            //         style: {
            //             colors: "#FFFFFF",
            //             fontSize: "12px",
            //             fontFamily: "Helvetica, Arial, sans-serif",
            //             fontWeight: 400,
            //         },
            //     },
            //     opposite: true,
            //     title: {
            //         text: "tCO2-e/MWh",
            //         style: {
            //             color: "#FFFFFF",
            //             fontSize: "14px",
            //             fontWeight: "bold",
            //             fontFamily: "Helvetica, Arial, sans-serif",
            //         },
            //     },
            //     axisBorder: {
            //         show: false,
            //         color: "#FFFFFF",
            //     },
            //     axisTicks: {
            //         show: false,
            //         color: "#FFFFFF",
            //     },
            // },
        ],
        legend: {
            position: "top",
            horizontalAlign: "center",
            labels: {
                colors: "#FFFFFF",
            },
            customLegendItems: [
                'Project Emission',
                'Abatement'
            ],
            markers: {
                fillColors: ["#78BE20", "#DDF0AC" ],
            },
            grid: {
                padding: {
                  bottom: 20
                }
              }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            // y: [{
            //         formatter: function(val) {
            //             return val
            //         }
            //     },
                
            // ]
        }
    },
};

export const estimatorChartTwoMWH = {
    series: [
        {
            name: "AC Abatement per MWh",
            group:"year",
            type: "bar",
            // data: [350, 200, 500, 360, 485, 523, 380, 100, 200, 296],
            data: [],
            color: '#33b2df', 
        },
        {
            name: "DC Abatement per MWh",
            group:"year",
            type: "bar",
            // data: [350, 200, 500, 360, 485, 523, 380, 100, 200, 296],
            data: [],
            color: '#d4526e',
        },
    ],
    chartOptions: {
        title: {
            text: "Abatement per MWh",
            align: 'center',
                style: {
                    color: "#FFFFFF",
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontFamily: "Helvetica, Arial, sans-serif",
                },

        },
        chart: {
            type: 'bar',
            height: 350,
            stacked: false,
            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '40%',
                // endingShape: 'rounded',
                dataLabels : {
                    position: "top",
                },
            },
        },
        dataLabels: {
            enabled: true,
            // enabledOnSeries: [1,3],
            offsetY: -30,
            style: {
                fontSize: "9px",
                colors: ["#fff"], // Use white for contrast if inside a bar
            },
            formatter: function (val, opts) {
                // Use series name for labels
                const seriesIndex = opts.seriesIndex;
                const seriesName = opts.w.globals.seriesNames[seriesIndex];
              
                // Replace and split the series name to create multi-line labels
                const formattedLabel = seriesName
                  .replaceAll("Abatement", "")
                  .replaceAll("AC Abatement", "")
                  .replaceAll("DC Abatement", "")
                  .replaceAll(" ", "").replaceAll("(tCO2-e)", "")
                  .replaceAll("perMWh", "")
                  .split(/(?=[A, D])/); // Split at uppercase letters to create multi-line labels
              
                return formattedLabel;
              },
            dropShadow: {
                enabled: false,
            },
            background: {
                enabled: false,
            },
            offsetX: 0,

            distributed: true,

        },
        stroke: {
            // show: true,
                        // show: true,
                        width: 1,
                        colors: ['#0D3937']
        },
        labels: [
          
        ],
        // labels: [],
        xaxis: {
            type:'category',
            categories: [
                "Year 0",
                "Year 1",
                "Year 2",
                "Year 3",
                "Year 4",
                "Year 5",
                "Year 6",
                "Year 7",
                "Year 8",
                "Year 9",
                "Year 10",
            ],
            title: {
                text: "",
                align: 'center',
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },

            },
            labels: {
                style: {
                    colors: "#FFFFFF",
                    fontSize: "12px",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: 400,

                },
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
            },

        },
        yaxis: [{
                seriesName: "AC Abatement per MWh",
                decimalsInFloat: 0,
                tickAmount: 5,
                tickPlacement: "between",
                title: {
                    text: "tCO2-e",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                labels: {
                    style: {
                        colors: "#FFFFFF",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
            {
                show: false,
                seriesName: "DC Abatement per MWh",
                decimalsInFloat: 0,
                tickAmount: 5,
                tickPlacement: "between",
                labels: {
                    style: {
                        colors: "#78BE20",
                        fontSize: "12px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        fontWeight: 400,
                    },
                },
                // opposite: true,
                title: {
                    text: "tCO2-e",
                    style: {
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: "bold",
                        fontFamily: "Helvetica, Arial, sans-serif",
                    },
                },
                axisBorder: {
                    show: false,
                    color: "#FFFFFF",
                },
                axisTicks: {
                    show: false,
                    color: "#FFFFFF",
                },
            },
        ],
        legend: {
            show: false,
            position: "top",
            horizontalAlign: "center",
            labels: {
                colors: "#FFFFFF",
            },
            markers: {
                fillColors: ["#78BE20", "#DDF0AC" ],
            },
            grid: {
                padding: {
                  bottom: 20
                }
              }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            // y: [{
            //         formatter: function(val) {
            //             return val
            //         }
            //     },
                
            // ]
        }
    },
};






