const state = {
    clientTotalDataList: [],
    fullBreakdownDataList: [],
    StateRegionDataList: [],
    StateRegionChargerTypeDataList: [],
    VerraTemplateList: [],
    PrivateFleetSiteList: [],
    StateRegionOneList: [],
    StateRegionTwoList: [],
    SDVistaList: [],
    VerraEq1: [],
    VerraEq4: [],
    VerraEq5: [],
    VerraEq7: [],
    VerraEq9: [],
    ProjectInstance: [],
}

const mutations = {
    set_clientTotal(state, data) {
        state.clientTotalDataList = data;
    },

    set_fullBreakdown(state, data) {
        state.fullBreakdownDataList = data;
    },

    set_StateRegion(state, data) {
        state.StateRegionDataList = data;
    },

    set_StateRegionChargerType(state, data) {
        state.StateRegionChargerTypeDataList = data;
    },

    set_VerraTemplate(state, data) {
        state.VerraTemplateList = data;
    },

    set_StateRegionOne(state, data) {
        state.StateRegionOneList = data;
    },

    set_StateRegionTwo(state, data) {
        state.StateRegionTwoList = data;
    },

    set_PrivateFleetSite(state, data) {
        state.PrivateFleetSiteList = data;
    },

    set_SDVista(state, data) {
        state.SDVistaList = data;
    },

    set_verraEq1(state, data) {
        state.VerraEq1 = data;
    },

    set_verraEq4(state, data) {
        state.VerraEq4 = data;
    },

    set_verraEq5(state, data) {
        state.VerraEq5 = data;
    },

    set_verraEq7(state, data) {
        state.VerraEq7 = data;
    },

    set_verraEq9(state, data) {
        state.VerraEq9 = data;
    },
    set_projectInstance(state, data) {
        state.ProjectInstance = data;
    },


}

export default {
    namespaced: true,
    state,
    mutations,
}