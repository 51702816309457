<template>
    <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
        
        <!-- single scenario charts -->
        <!-- <div class="html2pdf__page-break"/> -->
        <div v-if="!ismorescenario">
            <h5 class="text-white mt-3 ml-5">Totals</h5>
            <div class="width-100-p" v-if="!displayTwoChart">
                <apexchart
                    ref="chart"
                    type="bar"
                    height="350"
                    style="width: 95%;"
                    :options="chartOptions"
                    :series="series"
                ></apexchart>

            </div>
            <div class="width-100-p" v-if="displayTwoChart">
                <apexchart
                    ref="chart"
                    type="bar"
                    height="350"    
                    style="width: 95%;"      
                    :options="chartOptionsTwo"            
                    :series="seriesTwo"
                ></apexchart>   
                <apexchart
                    ref="chart"
                    type="bar"
                    height="350"    
                    style="width: 95%;"      
                    :options="chartOptionsTwoMWH"            
                    :series="seriesTwoMWH"
                ></apexchart>                               
            </div>
        </div>

        <!-- more scenario charts -->  
        <div v-if="ismorescenario && chartOne">
            <h5 class="text-white mt-3 ml-5">Abatement</h5>
            <!-- total chart  -->
            <div class="width-100-p">
                <apexchart
                    :key="chartKey"
                    ref="chart"
                    height="350"
                    style="width: 95%;"
                    :options="chartOptionsScenarioTotal"
                    :series= "chartTwo" 
                ></apexchart>
            </div>
            <!-- abatement -->
            <div class="width-100-p">
                <apexchart
                    :key="chartKey"
                    ref="chart"
                    type="bar"
                    height="350"
                    style="width: 95%;"
                    :options="chartOptionsScenario"
                    :series= "chartOne" 
                ></apexchart>
            </div>
            <!-- abatement per mwh -->
            <div class="width-100-p">
                <apexchart
                    :key="chartKey"
                    ref="chart"
                    type="bar"
                    height="350"
                    style="width: 95%;"
                    :options="chartOptionsScenarioTwo"
                    :series= "seriesScenarioTwo" 
                ></apexchart>
            </div>
        </div>
        <!-- more scenario charts -->

           
        <!-- <div v-if="isShowFusionChart">
            
            <h5 class="text-white mt-3 ml-5">Abatement</h5>
            <div class="width-100-p">
                 <estimatorFusionchart />
            </div>

        </div> -->
      

    </div>

</template>

<script>
import { mapState } from 'vuex';
import {estimatorChart, estimatorChartTwo, estimatorChartS1, estimatorChartS1Two, estimatorChartSTotal, estimatorChartTwoMWH } from './config.js';
// import estimatorFusionchart from './estimatorFusionchart.vue';



export default {
    components:{
        // estimatorFusionchart,
    },
    computed: {
        // data from store for total tables
        ...mapState('estimator', {
            estimatorData: state => state.estimatorData
        }),

                // scenarios results
        ...mapState('estimator',{
            scenarioResults: state => state.scenarioResults
        }),

    



    },
    data(){
        return{

            chartKey: 0, // control chart rerender when option or series change
            
            series: estimatorChart.series,
            chartOptions: estimatorChart.chartOptions,


            displayTwoChart: false,

            seriesTwo: estimatorChartTwo.series,
            chartOptionsTwo: estimatorChartTwo.chartOptions,

            seriesTwoMWH: estimatorChartTwoMWH.series,
            chartOptionsTwoMWH: estimatorChartTwoMWH.chartOptions,

            seriesScenario: estimatorChartS1.series,
            chartOptionsScenario: estimatorChartS1.chartOptions,

            seriesScenarioTwo: estimatorChartS1Two.series,
            chartOptionsScenarioTwo: estimatorChartS1Two.chartOptions,

            seriesTotal: estimatorChartSTotal.series,
            chartOptionsScenarioTotal: estimatorChartSTotal.chartOptions,

            ismorescenarioApex: true,

            // isShowFusionChart: false,

            chartOne: null,
            chartTwo: null,

            }
    },
    props:{
        ismorescenario: {
            type: Boolean,
        },
        totalData: {
            type: Object,
        },
    },
    methods: {
        updateChartKey() {
            this.chartKey++;
        },


        updateChartData(){

            this.resetSingleData();

            if (!this.estimatorData) {
                    console.log("when updateChartData no data in estimatorData" );
            } else {

            try {

                

            // when only ac or dc is selected
            if (!this.estimatorData.ac || !this.estimatorData.dc) {
                const chartData = this.estimatorData;

                // console.log("chartData", this.series[0].data);

                this.series[0].data = chartData.map(item => parseFloat(item.project_emission).toFixed(4)); // Project Emission
                this.series[1].data = chartData.map(item => parseFloat(item.abatement).toFixed(4)); // Abatement
                this.series[2].data = chartData.map(item => parseFloat(item.abatement_per_mwh).toFixed(4)); // Abatement per MWh

                // set chart location 
                this.chartOptions.xaxis.title.text = chartData[0].location;

                
                // set charging type for chart when ac or dc only
                this.chartOptions.title.text = chartData[0].charging_type;

                // console.log("chartData", this.series[0].data);
                
            } else {
                this.displayTwoChart = true;

                const acData = this.estimatorData.ac;
                const dcData = this.estimatorData.dc;


                let acyear = 0;
                let dcyear = 0;
                acData.forEach(item => {
                    this.seriesTwo[0].data.push({x: `Year ${acyear}`, y : parseFloat(item.project_emission).toFixed(4)});
                    this.seriesTwo[1].data.push({x:`Year ${acyear}`, y : parseFloat(item.abatement).toFixed(4)});
                    this.seriesTwoMWH[0].data.push({x:`Year ${acyear}`, y : parseFloat(item.abatement_per_mwh).toFixed(4)});
                    
                    acyear++;
                });
                dcData.forEach(item => {
                    this.seriesTwo[2].data.push({x: `Year ${dcyear}`, y : parseFloat(item.project_emission).toFixed(4)});
                    this.seriesTwo[3].data.push({x:`Year ${dcyear}`, y : parseFloat(item.abatement).toFixed(4)});
                    this.seriesTwoMWH[1].data.push({x:`Year ${dcyear}`, y : parseFloat(item.abatement_per_mwh).toFixed(4)});
      
                    dcyear++;
                });

                this.chartOptionsTwoMWH.xaxis.title.text = dcData[0].location;

                // console.log("seriesTwo 1", this.seriesTwo[0].data);
                // console.log("seriesTwo 2", this.seriesTwo[1].data);
                // console.log("seriesTwo 3", this.seriesTwo[2].data);
                // console.log("seriesTwo 4", this.seriesTwo[3].data);

                // console.log("seriesTwoMWH 1", this.seriesTwoMWH[0].data);
                // console.log("seriesTwoMWH 2", this.seriesTwoMWH[1].data);
                
            }
           
            } catch (error) {
                console.error("error when update data to single scenario chart:", error);
            }
        }

        },  

        updateChartScenarioData() {

            if (!this.scenarioResults || !this.ismorescenario) {
                    console.log("when update scenario ChartData no data in scenarioResults" );
            } else {

            try {

                const ResultList = this.scenarioResults;
             
                // Create series for each scenario
                ResultList.forEach(scenario => {

                    const scenarioId = scenario.scenario_id;
                    const data = scenario.data;
                    
                     // FOR single charging type

                    if (Array.isArray(data)) {

                        const projectEmissionData = [];
                        const abatementData = [];
                      
                        let year = 0;

                        data.forEach(item => {
                            projectEmissionData.push({x: `Year ${year}`, y: parseFloat(item.project_emission).toFixed(4)});
                            abatementData.push({x: `Year ${year}`, y: parseFloat(item.abatement).toFixed(4)});
                            year ++;
                        });

                        // generate yAxis for scenario

                        // generate series for scenario
                        this.seriesScenario.push({
                            name: `Scenario ${scenarioId} Project Emission`,
                            group: `Scenario ${scenarioId} Emission`,
                            // group: "year",
                            type: "bar",
                            data: projectEmissionData,
                            color: "#DDF0AC",
                            // yAxis: yAxisMapping["Project Emission"],
                            // labels: seriesLabel,
                        });

                        // swith showLabel
                        

                        this.seriesScenario.push({
                            name: `Scenario ${scenarioId} Abatement`,
                            group: `Scenario ${scenarioId} Emission`,
                            // group: "year",
                            type: "bar",
                            data: abatementData,
                            color: "#78BE20",
                            // yAxis: yAxisMapping["Abatement"],
                            // labels: seriesLabel,
                        },
                    );

                       

                    } else {

                        // for charging type AC

                        if (data.ac) {
                            const projectEmissionData = [];
                            const abatementData = [];

                            let year = 0;
                            

                            data.ac.forEach(item => {
                                projectEmissionData.push({x: `Year ${year}`, y: parseFloat(item.project_emission).toFixed(4)});
                                abatementData.push({x: `Year ${year}`, y: parseFloat(item.abatement).toFixed(4)});
                                year ++;
            
                            });
                            year = 0;

                            
                                

                            this.seriesScenario.push({
                                name: `Scenario ${scenarioId} AC Project Emission`,
                                group: `Scenario ${scenarioId} AC Emission`,
                                // group: "year",
                                type: "bar",
                                data: projectEmissionData,
                                color: "#DDF0AC",
                                // yAxis: yAxisMapping["Project Emission"],
                                    },
                                );

                            


                            this.seriesScenario.push({
                                name: `Scenario ${scenarioId} AC Abatement`,
                                group: `Scenario ${scenarioId} AC Emission`,
                                // group: "year",
                                type: "bar",
                                data: abatementData,
                                color: "#78BE20",
                                // yAxis: yAxisMapping["Abatement"],
                               
                            });
                            
                        }

                        // for charging type DC
                        if (data.dc) {
                            const projectEmissionData = [];
                            const abatementData = [];


                            let year = 0;

                            data.dc.forEach(item => {
                                projectEmissionData.push({x: `Year ${year}`, y: parseFloat(item.project_emission).toFixed(4)});
                                abatementData.push({x: `Year ${year}`, y: parseFloat(item.abatement).toFixed(4)});
                                year ++;
                            });
                            year = 0;

                            this.seriesScenario.push({
                                name: `Scenario ${scenarioId} DC Project Emission`,
                                group: `Scenario ${scenarioId} DC Emission`,
                                // group: "year",
                                type: "bar",
                                data: projectEmissionData,
                                color: "#DDF0AC",
                                // yAxis: yAxisMapping["Project Emission"],
                            });

                            this.seriesScenario.push({
                                name: `Scenario ${scenarioId} DC Abatement`,
                                group: `Scenario ${scenarioId} DC Emission`,
                                // group: "year",
                                type: "bar",
                                data: abatementData,
                                color: "#78BE20",
                                // yAxis: yAxisMapping["Abatement"],
                                
                            });
                            }

                    }
                });

                // find index of series to enable labels

                this.seriesScenario.forEach((series, index) => {
                    if (series.name.includes("Abatement")) {
                        this.chartOptionsScenario.dataLabels.enabledOnSeries.push(index);
                        // console.log(this.chartOptionsScenario.dataLabels.enabledOnSeries);
                    }
                });


                this.chartOptionsScenario.yaxis.push(
                                {
                                    show: true,
                                    seriesName: `${this.seriesScenario[0].name}`,
                                    decimalsInFloat: 0,
                                    tickAmount: 5,
                                    tickPlacement: "between",
                                    title: {
                                        text: "tCO2-e",
                                        style: {
                                            color: "#FFFFFF",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            fontFamily: "Helvetica, Arial, sans-serif",
                                        },
                                    },
                                    labels: {
                                        style: {
                                            colors: "#FFFFFF",
                                            fontSize: "12px",
                                            fontFamily: "Helvetica, Arial, sans-serif",
                                            fontWeight: 400,
                                        },
                                    },
                                    axisBorder: {
                                        show: false,
                                        color: "#FFFFFF",
                                    },
                                    axisTicks: {
                                        show: false,
                                        color: "#FFFFFF",
                                    },
                                },
                            );
                
          
                this.chartOne = this.seriesScenario;
                            
            } catch (error) {
                if(this.ismorescenario) {
                    console.log("updating... data to scenario Two chart:");
                } else {
                    return
                }
                
            }
        }
        },

        updateChartScenarioDataTwo() {

            if (!this.scenarioResults || !this.ismorescenario) {
                    console.log("when update scenario ChartData no data in scenarioResults" );
            } else {

                    
            try {

                const ResultList = this.scenarioResults;
            
                // Create series for each scenario
                ResultList.forEach(scenario => {

                    const scenarioId = scenario.scenario_id;
                    const data = scenario.data;

                    // FOR single charging type

                    if (Array.isArray(data)) {

                        const abatementMWHData = [];
    
                        let year = 0;

                        data.forEach(item => {
                            abatementMWHData.push({x: `Year ${year}`, y: parseFloat(item.abatement_per_mwh
                                ).toFixed(4)});
                            year ++;
                        });

                        // swith showLabelTwo
                        

                        this.seriesScenarioTwo.push({
                            name: `Scenario ${scenarioId}`,
                            group: `Scenario ${scenarioId} Emission`,
                            // group: "year",
                            type: "bar",
                            data: abatementMWHData,
                            // color: "#78BE20",

                        },
                    );

                    } else {

                        // for charging type AC

                        if (data.ac) {

                            const abatementMWHData = [];

                            let year = 0;
                            
                            data.ac.forEach(item => {
                                abatementMWHData.push({x: `Year ${year}`, y: parseFloat(item.abatement_per_mwh).toFixed(4)});
                                year ++;

                            });
                            year = 0;
                        
                            this.seriesScenarioTwo.push({
                                name: `Scenario ${scenarioId} AC`,
                                group: `Scenario ${scenarioId} AC Emission`,
                                // group: "year",
                                type: "bar",
                                data: abatementMWHData,
                                // color: "#78BE20",
                                // yAxis: yAxisMapping["Abatement"],
                            });
                            
                        }

                        // for charging type DC
                        if (data.dc) {
                    
                            const abatementMWHData = [];

                            let year = 0;

                            data.dc.forEach(item => {
                                abatementMWHData.push({x: `Year ${year}`, y: parseFloat(item.abatement_per_mwh).toFixed(4)});
                                year ++;
                            });
                            year = 0;
                        
                            this.seriesScenarioTwo.push({
                                name: `Scenario ${scenarioId} DC`,
                                group: `Scenario ${scenarioId} DC Emission`,
                                // group: "year",
                                type: "bar",
                                data: abatementMWHData,
                                // color: "#78BE20",
                                // yAxis: yAxisMapping["Abatement"],
                                
                            });
                        }
                        
                    }
                });

                        // console.log("before add yaxis for two", this.seriesScenarioTwo);
                        
                        this.chartOptionsScenarioTwo.yaxis.push(
                                    {
                                        show: true,
                                        seriesName: `${this.seriesScenarioTwo[0].name}`,
                                        decimalsInFloat: 2,
                                        tickAmount: 6,
                                        tickPlacement: "between",
                                        title: {
                                            text: "tCO2-e per MWh",
                                            style: {
                                                color: "#FFFFFF",
                                                fontSize: "14px",
                                                fontWeight: "bold",
                                                fontFamily: "Helvetica, Arial, sans-serif",
                                            },
                                        },
                                        labels: {
                                            style: {
                                                colors: "#FFFFFF",
                                                fontSize: "12px",
                                                fontFamily: "Helvetica, Arial, sans-serif",
                                                fontWeight: 400,
                                            },
                                        },
                                        axisBorder: {
                                            show: false,
                                            color: "#FFFFFF",
                                        },
                                        axisTicks: {
                                            show: false,
                                            color: "#FFFFFF",
                                        },
                                    },
                                );

                                // this.chartTwo= this.seriesScenarioTwo;

            } catch (error) {
                if (this.ismorescenario) {
                    console.log("updating... data to scenario Two chart:");
                } else {
                    return
                }
                
            }
        }
        },

        updateTotalScenarioData() {
            if (!this.totalData || !this.ismorescenario) {
                console.log("when update scenario total ChartData no data in scenarioResults");
            } else {
                try {
                const ResultList = this.totalData;
                const scenarioIds = [];

                // Create series for each scenario
                ResultList.forEach(item => {
                    const scenarioId = item.scenarioId;
                    scenarioIds.push(`Scenario ${scenarioId}`);
                    // console.log("item length", item.length);

                    if (Object.keys(item).length === 0) {
                    return;
                    }

                    if (Object.keys(item).length === 10) {
                    this.seriesTotal.push({
                        name: `Scenario ${scenarioId} AC Project Emission`,
                        group: `Scenario ${scenarioId} AC Emission`,
                        type: "bar",
                        data: [{ x: `Scenario ${scenarioId}`, y: item.Project_Emission_AC }],
                        color: "#DDF0AC",
                    });
                    this.seriesTotal.push({
                        name: `Scenario ${scenarioId} AC Abatement`,
                        group: `Scenario ${scenarioId} AC Emission`,
                        type: "bar",
                        data: [{ x: `Scenario ${scenarioId}`, y: item.Abatement_AC }],
                        color: "#78BE20",
                    });
                    

                    this.seriesTotal.push({
                        name: `Scenario ${scenarioId} DC Project Emission`,
                        group: `Scenario ${scenarioId} DC Emission`,
                        type: "bar",
                        data: [{ x: `Scenario ${scenarioId}`, y: item.Project_Emission_DC }],
                        color: "#DDF0AC",
                    });
                    this.seriesTotal.push({
                        name: `Scenario ${scenarioId} DC Abatement`,
                        group: `Scenario ${scenarioId} DC Emission`,
                        type: "bar",
                        data: [{ x: `Scenario ${scenarioId}`, y: item.Abatement_DC }],
                        color: "#78BE20",
                    });
                    } else if (Object.keys(item).length === 4 || Object.keys(item).length === 7) {
                    if ('Abatement_AC' in item) {
                        this.seriesTotal.push({
                        name: `Scenario ${scenarioId} AC Project Emission`,
                        group: `Scenario ${scenarioId} AC Emission`,
                        type: "bar",
                        data: [{ x: `Scenario ${scenarioId}`, y: item.Project_Emission_AC }],
                        color: "#DDF0AC",
                        });
                        this.seriesTotal.push({
                        name: `Scenario ${scenarioId} AC Abatement`,
                        group: `Scenario ${scenarioId} AC Emission`,
                        type: "bar",
                        data: [{ x: `Scenario ${scenarioId}`, y: item.Abatement_AC }],
                        color: "#78BE20",
                        });

                    } else if ('Abatement_DC' in item) {
                        this.seriesTotal.push({
                        name: `Scenario ${scenarioId} DC Project Emission`,
                        group: `Scenario ${scenarioId} DC Emission`,
                        type: "bar",
                        data: [{ x: `Scenario ${scenarioId}`, y: item.Project_Emission_DC }],
                        color: "#DDF0AC",
                        });
                        this.seriesTotal.push({
                        name: `Scenario ${scenarioId} DC Abatement`,
                        group: `Scenario ${scenarioId} DC Emission`,
                        type: "bar",
                        data: [{ x: `Scenario ${scenarioId}`, y: item.Abatement_DC }],
                        color: "#78BE20",
                        });
                    }
                    }
                });

                // console.log("SERIES TOTAL", this.seriesTotal);

                // find index of series to enable labels
                this.seriesTotal.forEach((series, index) => {
                    if (series.name.includes("Abatement")) {
                    this.chartOptionsScenarioTotal.dataLabels.enabledOnSeries.push(index);
                    }
                });

                // Update chart options with dynamic categories
                this.chartOptionsScenarioTotal = {
                    ...this.chartOptionsScenarioTotal,
                    xaxis: {
                        title: {
                            text: "Scenario Total Emission",
                            align: 'center',
                            style: {
                                color: "#FFFFFF",
                                fontSize: "14px",
                                fontWeight: "bold",
                                fontFamily: "Helvetica, Arial, sans-serif",
                            },
                        },
                    labels: {
                        show: false,
                    },
                    },
                    yaxis: [
                    {
                        show: true,
                        seriesName: `${this.seriesTotal[0].name}`,
                        decimalsInFloat: 0,
                        tickAmount: 5,
                        tickPlacement: "between",
                        title: {
                        text: "tCO2-e",
                        style: {
                            color: "#FFFFFF",
                            fontSize: "14px",
                            fontWeight: "bold",
                            fontFamily: "Helvetica, Arial, sans-serif",
                        },
                        },
                        labels: {
                        style: {
                            colors: "#FFFFFF",
                            fontSize: "12px",
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: 400,
                        },
                        },
                        axisBorder: {
                        show: false,
                        color: "#FFFFFF",
                        },
                        axisTicks: {
                        show: false,
                        color: "#FFFFFF",
                        },
                    },
                    ],
                };

                this.chartTwo = this.seriesTotal;

                // console.log("chartOptionsScenarioTotal", this.chartOptionsScenarioTotal.xaxis.categories);

                } catch (error) {
                console.log("updating data to scenario chart:");
                }
            }
            },

        loadData() {
            this.resetChartData();
            this.updateTotalScenarioData();
            this.updateChartScenarioData();
            this.updateChartScenarioDataTwo();
            
            
            this.updateChartKey();
           
            
        },

        resetSingleData() {

            this.series[0].data = [];
            this.series[1].data = [];
            this.series[2].data = [];

            this.seriesTwo[0].data = []; 
            this.seriesTwo[1].data = [];
            this.seriesTwo[2].data = [];
            this.seriesTwo[3].data = [];

            this.seriesTwoMWH[0].data = [];
            this.seriesTwoMWH[1].data = [];

        

        },

     
        resetChartData() {

            this.chartOptionsScenarioTotal.dataLabels.enabledOnSeries = [];
            this.chartOptionsScenarioTotal.yaxis = [];
            this.seriesTotal = [];

            this.chartOptionsScenario.dataLabels.enabledOnSeries = [];
            this.chartOptionsScenario.yaxis = [];
            this.seriesScenario = [];

            this.chartOptionsScenarioTwo.yaxis = [];
            this.seriesScenarioTwo = [];

            this.chartOne = null;
            this.chartTwo = null;
        },

    },
    mounted(){
        this.resetChartData();
        this.updateChartData();
        this.updateTotalScenarioData();
        this.updateChartScenarioData();
        this.updateChartScenarioDataTwo();
        
    },
    unmounted(){
        this.resetChartData();
        // console.log("unmounted estimatorChartS1",  this.seriesScenario);
        
    },
    watch:{
        estimatorData: {
            handler: 'updateChartData',
            deep: true, // use deep to watch nested changes
        },
        scenarioResults: {
            handler: 'loadData',
            deep: true,
        },
        totalData: {
            handler: 'loadData',
            deep: true,
        },
    },

}

</script>
