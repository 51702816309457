<template>
  <div>
    <el-col :span="24">
      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Project Instances</h5>
        <projectInstanceTable ref="grandchildComp5" />
      </div>
      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Verra eq1 - Baseline Scenario</h5>
        <verraEq1 ref="grandchildComp" />
      </div>
      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Verra eq4</h5>
        <verraEq4 ref="grandchildComp1" />
      </div>
      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Verra eq5</h5>
        <verraEq5 ref="grandchildComp2" />
      </div>
      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Verra eq7</h5>
        <verraEq7 ref="grandchildComp3" />
      </div>
      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Verra eq9</h5>
        <verraEq9 ref="grandchildComp4" />
      </div>

      <!-- <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Verra eq2</h5>
        <fullBreakdown ref="grandchildComp2" />
      </div>
      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Verra eq3</h5>
        <byStateAndRegion ref="grandchildComp3" />
      </div>

      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Verra eq4</h5>
        <StateRegionAndChargerType ref="grandchildComp4" />
      </div>

      <div
        class="row m-0 mt-3 py-3 width-100-p bg-me-primary border-color-light rounded-4"
      >
        <h5 class="text-white mt-3 ml-5">Verra eq5</h5>
        <VerraTemplate ref="grandchildComp5" />
      </div> -->
    </el-col>
  </div>
</template>

<script>
import verraEq1 from "./verraEq1.vue";
import verraEq4 from "./verraEq4.vue";
import verraEq5 from "./verraEq5.vue";
import verraEq7 from "./verraEq7.vue";
import verraEq9 from "./verraEq9.vue";
import projectInstanceTable from "./projectInstanceTable.vue";
// import byStateAndRegion from "./byStateAndRegion.vue";
// import fullBreakdown from "./fullBreakdown.vue";
// import StateRegionAndChargerType from "./StateRegionAndChargerType.vue";
// import VerraTemplate from "./VerraTemplate.vue";

export default {
  props: {
    parentDataFilter: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      dataList: [],
    };
  },
  components: {
    verraEq1,
    verraEq4,
    verraEq5,
    verraEq7,
    verraEq9,
    projectInstanceTable,
    // byStateAndRegion,
    // fullBreakdown,
    // StateRegionAndChargerType,
    // VerraTemplate,
  },

  methods: {
    emitToParent(parentDataFilter) {
      this.$refs.grandchildComp5.getTableData(parentDataFilter);
      this.$refs.grandchildComp.getTableData(parentDataFilter);
      this.$refs.grandchildComp2.getTableData(parentDataFilter);
      this.$refs.grandchildComp3.getTableData(parentDataFilter);
      this.$refs.grandchildComp4.getTableData(parentDataFilter);
    },
  },
};
</script>
