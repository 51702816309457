<template>
  <div class="ma-0" v-loading="loading" id="verra_eq4">
    <el-table
      :data="dataList"
      :fit="true"
      height="300"
      class="my-2 width-100-p data-table"
      stripe
      scrollbar-always-on
    >
      <el-table-column
        :label="'Region'"
        :prop="'region'"
        :width="$vuetify.display.width <= 1750 ? 250 : 120"
        sortable
      />
      <el-table-column
        :label="'Instance ID'"
        :prop="'instanceid'"
        :width="$vuetify.display.width <= 1750 ? 250 : 137"
        sortable
      />
      <el-table-column
        :label="'Charger Type'"
        :prop="'charger_type'"
        :width="$vuetify.display.width <= 1750 ? 250 : 180"
        sortable
      />
      <el-table-column
        :label="'Proration'"
        :prop="'proration'"
        :width="$vuetify.display.width <= 1750 ? 250 : 180"
        sortable
      />
      <el-table-column
        :label="'Fleet'"
        :prop="'fleet'"
        :width="$vuetify.display.width <= 1750 ? 250 : 150"
        sortable
      >
        <!-- <template #default="scope">
          {{
            scope.row.energy_delivered == null
              ? 0
              : Number(scope.row.energy_delivered).toLocaleString()
          }}
        </template> -->
      </el-table-column>

      <el-table-column
        :label="'EV model'"
        :prop="'ev_model'"
        :width="$vuetify.display.width <= 1750 ? 250 : 150"
        sortable
      >
        <!-- <template #default="scope">
          {{
            scope.row.energy_consumed == null
              ? 0
              : Number(scope.row.energy_consumed).toLocaleString()
          }}
        </template> -->
      </el-table-column>

      <el-table-column
        :label="'EC'"
        :prop="'ec'"
        :width="$vuetify.display.width <= 1750 ? 250 : 200"
        sortable
      >
        <!-- <template #default="scope">
          {{
            scope.row.emission_factor_electricity == null
              ? 0
              : Number(scope.row.emission_factor_electricity).toLocaleString()
          }}
        </template> -->
      </el-table-column>

      <el-table-column
        :label="'EF_kW'"
        :prop="'ef_kw'"
        :width="$vuetify.display.width <= 1750 ? 250 : 180"
        sortable
      >
        <!-- <template #default="scope">
          {{
            scope.row.abatement_tco2 == null
              ? 0
              : Number(scope.row.abatement_tco2).toLocaleString()
          }}
        </template> -->
      </el-table-column>

      <el-table-column
        :label="'LE_y'"
        :prop="'le_y'"
        :width="$vuetify.display.width <= 1750 ? 250 : 180"
        sortable
      >
        <!-- <template #default="scope">
          {{
            scope.row.battery_export_tco2 == null
              ? 0
              : Number(scope.row.battery_export_tco2).toLocaleString()
          }}
        </template> -->
      </el-table-column>

      <el-table-column
        :label="'ER_c'"
        :prop="'er_c'"
        :width="$vuetify.display.width <= 1750 ? 250 : 180"
        sortable
      >
        <!-- <template #default="scope">
          {{
            scope.row.project_tco2 == null
              ? 0
              : Number(scope.row.project_tco2).toLocaleString()
          }}
        </template> -->
      </el-table-column>

      <el-table-column
        :label="'ER_f'"
        :prop="'er_f'"
        :width="$vuetify.display.width <= 1750 ? 250 : 138"
        sortable
      >
        <!-- <template #default="scope">
          {{
            scope.row.baseline_tco2 == null
              ? 0
              : Number(scope.row.baseline_tco2).toLocaleString()
          }}
        </template> -->
      </el-table-column>
    </el-table>

    <!-- page count and pagination -->
    <el-row class="row-bg" justify="space-between">
      <el-col :span="5" class="d-flex justify-content-start align-items-center">
        <el-select
          v-model="perPageCount"
          placeholder="Select"
          size="small"
          style="width: 70px"
        >
          <el-option label="10" :value="'10'" />
          <el-option label="25" :value="'25'" />
          <el-option label="50" :value="'50'" />
          <el-option label="100" :value="'100'" />
        </el-select>
      </el-col>
      <el-col
        :span="8"
        class="d-flex align-items-center justify-content-center"
      >
        <el-pagination
          small
          background
          layout="prev, pager, next"
          :total="total_items"
          v-model:page-size="page_size"
          v-model:current-page="current_page"
          @current-change="changePage"
          class="my-4"
        />
      </el-col>
      <el-col :span="5" class="d-flex justify-content-end align-items-center">
        <span class="text-white">
          {{ (current_page - 1) * perPageCount + 1 }}-{{
            current_page * perPageCount > total_items
              ? total_items
              : current_page * perPageCount
          }}
          of {{ total_items }}
        </span>
      </el-col>
    </el-row>
    <!-- page count and pagination -->
  </div>
</template>

<script>
import userService from "@/services/user.service";
import { snakeToTitleCase } from "./tools";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      request: {
        start_date: null,
        end_date: null,
        search: null,
        report_type: "verra_eq4",

        current_page: null,
        perPageCount: null,
      },
      dataList: [],

      // for page count and pagination
      perPageCount: 50,
      current_page: 1,
      page_size: 50,
      total_items: 100,
      // for page count and pagination
    };
  },

  watch: {
    // for page count
    perPageCount: function (newVal) {
      this.current_page = 1;
      this.page_size = parseInt(newVal);
      this.tableAction({
        index: this.page_size,
        data: null,
        kind: "perPageChange",
      });
    },
    // for page count
  },

  methods: {
    ...mapMutations("auditorReport", ["set_verraEq4"]),

    // for page count and pagination
    changePage(page) {
      this.tableAction({
        index: page,
        data: null,
        kind: "changePage",
      });
    },

    handleReload() {
      this.request.current_page = this.current_page;
      this.request.perPageCount = this.perPageCount;
      this.getTableData(this.request);
    },

    tableAction(event) {
      if (event.kind == "changePage") {
        this.current_page = event.index;
        this.handleReload();
      } else if (event.kind == "perPageChange") {
        this.current_page = 1;
        this.perPageCount = event.index;
        this.handleReload();
      }
    },
    // for page count and pagination

    convertText(t) {
      return snakeToTitleCase(t);
    },

    // rounding table display numbers
    rounding(row, column) {
      const value = row[column.property];
      const valueToNumber = parseFloat(value);

      return !isNaN(valueToNumber) ? parseFloat(valueToNumber.toFixed(4)) : 0; // set digits of data on output table
    },

    async getTableData(request) {
      try {
        // Reset data list and update request parameters
        this.dataList = [];
        this.request.start_date = request.startDate;
        this.request.end_date = request.endDate;
        this.request.search = request.search;
        request.report_type = this.request.report_type;

        // Make API call
        const res = await userService.DashboardAuditorReports(this.request);

        // Handle response
        if (res.data != null) {
          this.dataList = res.data.data;
          this.total_items = res.data.total_record;
          this.set_verraEq4(this.dataList);
        } else {
          console.error(
            "Empty response data. Please check the API or request parameters."
          );
        }
      } catch (error) {
        // Log detailed error information
        console.error("Error fetching table data:", error.message);
        console.error("Request:", this.request);
        console.error(
          "Response Data:",
          error.response?.data || "No response data available"
        );
        console.error("HTTP Status:", error.response?.status || "Unknown");
      } finally {
        console.log("Request completed.");
      }
    },
  },
  mounted() {
    this.getTableData(this.request);
  },
};
</script>
<style scoped>
.ma-0 >>> .el-table__row > td {
  border-color: #234b49;
}

.ma-0 >>> .el-table th.is-leaf {
  border-color: #234b49;
  border-bottom: none;
}

.ma-0 >>> .el-table__inner-wrapper::before {
  background-color: #234b49;
}
</style>
