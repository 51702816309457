<template>

<Breadcrumbs main="Charger Anomaly" title="default" />

<div class="container-fluid p-0">
  <div class="row widget-grid">
    <!-- <Filter
      :stateProp="stateList"
      @filterHandle="filterHandle"
      :hasLocation="true"
    /> -->
    <el-row class="m-0 width-100-p mt-3">
      <!-- Abatement -->
      <el-col class="d-flex align-item-center px-2" :span="24">
        <div
          class="row m-0 bg-me-primary border-color-light width-100-p rounded-4"
        >

          <el-col :span="24">
                <ChargeringSiteMap />
          </el-col>
        </div>
      </el-col>
    </el-row>
    <!-- <el-row class="m-0 width-100-p mt-3">
      <el-col class="d-flex align-item-center px-2" :span="24">
        <div class="row m-0 width-100-p">
          <div
            class="row m-0 py-3 width-100-p bg-me-primary border-color-light rounded-4"
          >
            
          </div>
        </div>
      </el-col>
    </el-row> -->
  </div>
</div>

</template>

<script>
import ChargeringSiteMap from './chargeringSiteMap.vue';

export default {
    props: {},
    components: {
        ChargeringSiteMap,

    },

}
</script>

<style scoped>
</style>