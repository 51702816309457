<template>

    <div class="container-fluid p-0 " id="convert-to-pdf">
        <div  class="row widget-grid">

            <!-- init input table data, when only one scenario -->
            <div v-if="!moreScrenarios">

                <!-- {{ groupedCombinedData }}
                {{ flattenedCombinedData }}
                {{ tableColumnsCombinedData }} -->

                <el-row class="m-0 width-100-p mt-3">
                    <el-col
                    :span="24">
                        <!-- Input Summary table -->
                        <div class="estimaInputTable">

                            <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                                <h5 class="text-white mt-3 ml-5">Input summary</h5>

                                <el-table :data="inputTableData" stripe scrollbar-always-on style="width: 95%" class="mt-3 mb-3 ml-5 data-table">

                                    <el-table-column prop="location" label="Location" :width="getColumnWidth(250, 283, 391, 6)"  />

                                    <el-table-column prop="charging_type" label="Charging type" :width="getColumnWidth(250, 283, 391, 6)" />

                                    <el-table-column prop="usage" label="Annual usage (MWh)" :formatter="rounding" :width="getColumnWidth(250, 283, 391, 6)">

                                    <template #default="scope">
                                        {{
                                        scope.row.usage == null
                                            ? ""
                                            : Number(scope.row.usage).toLocaleString()
                                        }}
                                    </template>
                                    </el-table-column>

                                    <el-table-column prop="annual_growth_in_usage" label="Annual growth in usage (%)" :width="getColumnWidth(250, 283, 391, 6)" >
                                    <template #default="scope">
                                        {{
                                        scope.row.annual_growth_in_usage == null
                                            ? ""
                                            : Number(scope.row.annual_growth_in_usage).toLocaleString()
                                        }}
                                    </template>
                                    </el-table-column>

                                    <el-table-column prop="ei" label="Grid emission intensity (tCO2-e/MWh)" :formatter="rounding" :width="getColumnWidth(250, 283, 391, 6)" >
                                    <template #default="scope">
                                        {{
                                        scope.row.ei == null
                                            ? ""
                                            : Number(scope.row.ei).toLocaleString()
                                        }}
                                    </template>
                                    </el-table-column>

                                    <el-table-column prop="annual_emission_intensity_improvement" label="Annual emission intensity improvement (%)" :width="getColumnWidth(250, 283, 391, 6)" >
                                    <template #default="scope">
                                        {{
                                        scope.row.annual_emission_intensity_improvement == null
                                            ? ""
                                            : Number(scope.row.annual_emission_intensity_improvement).toLocaleString()
                                        }}
                                    </template>
                                    </el-table-column>

                                </el-table>

                            </div>

                        </div>
                        <!-- Input Summary table END -->

                    </el-col>
                </el-row>
            </div>

            <!-- {{ scenarioInputData }} -->
            <!-- {{  moreScrenarios }} -->

            <!-- scenarios input table -->
            <div v-if="moreScrenarios">
                <el-row class="m-0 width-100-p mt-3">
                <el-col
                :span="24">
                    <!-- Input Summary table -->
                    <div class="estimaInputTable">

                        <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                            <h5 class="text-white mt-3 ml-5">Scenarios Input summary</h5>

                            <el-table :data="expandedScenarioData" stripe style="width: 95%" class="mt-3 mb-3 ml-5 data-table">

                                <el-table-column prop="id" label="Scenario ID"   />

                                <el-table-column prop="location" label="Location"   />

                                <el-table-column prop="charging_type" label="Charging type"  />

                                <el-table-column prop="usage" label="Annual usage (MWh)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 290 : 283">

                                <template #default="scope">
                                    {{
                                    scope.row.usage == null
                                        ? ""
                                        : Number(scope.row.usage).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="annual_growth_in_usage" label="Annual growth in usage (%)" :width="$vuetify.display.width <= 1750 ? 290 : 283" >
                                <template #default="scope">
                                    {{
                                    scope.row.annual_growth_in_usage == null
                                        ? ""
                                        : Number(scope.row.annual_growth_in_usage).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="ei" label="Grid emission intensity (tCO2-e/MWh)" :formatter="rounding" :width="$vuetify.display.width <= 1750 ? 290 : 283" >
                                <template #default="scope">
                                    {{
                                    scope.row.ei == null
                                        ? ""
                                        : Number(scope.row.ei).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                                <el-table-column prop="annual_emission_intensity_improvement" label="Annual emission intensity improvement (%)" :width="$vuetify.display.width <= 1750 ? 290 : 283" >
                                <template #default="scope">
                                    {{
                                    scope.row.annual_emission_intensity_improvement == null
                                        ? ""
                                        : Number(scope.row.annual_emission_intensity_improvement).toLocaleString()
                                    }}
                                </template>
                                </el-table-column>

                            </el-table>

                        </div>

                    </div>
                    <!-- Input Summary table END -->

                </el-col>
            </el-row>

            </div>
            <!-- scenarios input table end -->

            <!-- <div class="html2pdf__page-break"/> -->

            <!-- chart -->
            <div >

                <el-row class="m-0 width-100-p mt-3">
                    <el-col :span="24">
                  
                        <div class="estimaChart width-100-p">

                            <div class="m-0 width-100-p mt-3">
                                <estimatorCharts :key="chartKey" :totalData="generateFlattenedTotalData" :ismorescenario="moreScrenarios" />
                            </div>

                        </div>
                       
                    </el-col>
                </el-row>

            </div>
             <!-- chart END-->

            <!-- <div class="html2pdf__page-break"/> -->

            <!-- init output table, when only one scenario -->
            <div v-if="!moreScrenarios">
                <el-row class="m-0 width-100-p mt-3">
                    <el-col :span="24">

                        <!-- Totals table -->
                        <div v-if="ac_and_dc" class="estimaTotalTable">
                            <!-- show table when charging type ac and dc -->

                            <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                                <h5 class="text-white mt-3 ml-5">Totals</h5>


                                <el-table :data="flattenedCombinedData" stripe scrollbar-always-on style="width: 95%" class="mt-3 mb-3 ml-5 data-table">

                                    <el-table-column prop="Location" label="Location" >
                                        <template #default="scope">
                                            {{ scope.row.Location }}
                                        </template>
                                    </el-table-column>

                                    <el-table-column prop="year" label="Year" >
                                        <template #default="scope">
                                            {{ scope.row.Year }}
                                        </template>
                                    </el-table-column>
                                    
                                   
                                    <el-table-column
                                    v-for="column in tableColumnsCombinedData"
                                    :key="column.label"
                                    :label="column.label"
                                    > 
                                    
                                    <el-table-column
                                        v-for="child in column.children"
                                        :key="child.label"
                                        :label="child.label"
                                        :prop="child.prop"
                                    >
                                        <template #default="scope">
                                        {{
                                            scope.row[child.prop] == null
                                            ? ""
                                            : Number(scope.row[child.prop]).toLocaleString()
                                        }}
                                        </template>
                                    </el-table-column>
                                    
                                    </el-table-column>
                                </el-table>



                                



                            </div>

                        </div>
                        <!-- Totals  table END -->

                        <!-- Totals table -->
                        <div v-if="!ac_and_dc" class="estimaTotalTable">
                            <!-- show table when charging type is ac or dc only -->
                        <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                            <h5 class="text-white mt-3 ml-5">Totals</h5>

                           

                            <el-table :data="flattenedCombinedData" stripe scrollbar-always-on style="width: 95%" class="mt-3 mb-3 ml-5 data-table">

                                <el-table-column prop="Location" label="Location" >
                                    <template #default="scope">
                                        {{ scope.row.Location }}
                                    </template>
                                </el-table-column>

                                <el-table-column prop="year" label="Year" >
                                    <template #default="scope">
                                        {{ scope.row.Year }}
                                    </template>
                                </el-table-column>


                                <el-table-column
                                v-for="column in tableColumnsCombinedData"
                                :key="column.label"
                                :label="column.label"
                                > 

                                <el-table-column
                                    v-for="child in column.children"
                                    :key="child.label"
                                    :label="child.label"
                                    :prop="child.prop"
                                >
                                    <template #default="scope">
                                    {{
                                        scope.row[child.prop] == null
                                        ? ""
                                        : Number(scope.row[child.prop]).toLocaleString()
                                    }}
                                    </template>
                                </el-table-column>

                                </el-table-column>
                            </el-table>

                        </div>

                        </div>
                        <!-- Totals  table END -->


                    </el-col>
                </el-row>
            </div>
            <!-- init output table, when only one scenario END -->


            <!-- scenario result output table -->

            

            <div v-if="moreScrenarios">

                <div class="html2pdf__page-break"/>

                <!-- scenario abatement table -->
                <div class="estimateTotalMultiHeaderTable">
                            <!-- show table when charging type ac and dc -->

                            <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                                <h5 class="text-white mt-3 ml-5">Scenarios Total</h5>

                                <!-- Total Table -->
                                <el-table :data="generateFlattenedTotalData" stripe scrollbar-always-on style="width: 95%" class="mt-3 mb-3 ml-5 data-table">
                                    <el-table-column prop="scenarioId" label="Scenario Name" >
                                        <template #default="scope">
                                            Scenario {{ scope.row.scenarioId }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                    v-for="column in generateTotalColumns"
                                    :key="column.label"
                                    :label="column.label"
                                    > 
                                    <el-table-column
                                        v-for="child in column.children"
                                        :key="child.label"
                                        :label="child.label"
                                        :prop="child.prop"
                                    >
                                        <template #default="scope">
                                        {{
                                            scope.row[child.prop] == null
                                            ? ""
                                            : Number(scope.row[child.prop]).toLocaleString()
                                        }}
                                        </template>
                                    </el-table-column>
                                    </el-table-column>
                                </el-table>
                                <!-- Total Table -->
                            </div>

                        </div>
                        <!-- scenario total table END -->

                       <!-- scenario abatement table -->
                       <div class="estimateTotalMultiHeaderTable">
                            <!-- show table when charging type ac and dc -->

                            <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                                <h5 class="text-white mt-3 ml-5">Scenarios Abatement (tCO2-e)</h5>


                                <!-- Test dynamic header scenario abatement table -->
                                <el-table :data="abatementFlattenedData" stripe scrollbar-always-on style="width: 95%" class="mt-3 mb-3 ml-5 data-table">
                                    <el-table-column prop="Year" label="Year">
                                        <template  #default="scope">
                                            <span v-if="isNaN(scope.row.Year)" style="font-weight: bold;">Total</span>
                                            <span v-else>{{ scope.row.Year }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        v-for="(column, index) in abatementTableColumns"
                                        :key="index"
                                        :label="column.label"
                                    >
                                        <template v-for="(child, idx) in column.children">
                                            <el-table-column
                                                v-if="child.children"
                                                :key="idx"
                                                :label="child.label"
                                            >
                                                <el-table-column
                                                v-for="(leaf, leafIdx) in child.children"
                                                :key="leafIdx"
                                                :prop="leaf.prop"
                                                :label="leaf.label"
                                                >
                                                <!-- Formatting logic applied here -->
                                                <template #default="scope">
                                                    {{
                                                        scope.row[leaf.prop] == null
                                                            ? ""
                                                            : Number(scope.row[leaf.prop]).toLocaleString()
                                                    }}
                                                </template>
                                                
                                                </el-table-column>
                                            </el-table-column>
                                        
                                        </template>
                                    </el-table-column>
                                </el-table>

                                <!-- Test dynamic header  scenario abatement table -->
                            </div>

                        </div>
                        <!-- scenario abatement table END -->

                       <!-- scenario project emission table -->
                       <div class="estimaTotalTable">
                            <!-- show table when charging type ac and dc -->

                            <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                                <h5 class="text-white mt-3 ml-5">Scenarios Project Emission (tCO2-e)</h5>

                                <!-- scenario abatement table -->
                                <!-- <el-table :data="projectEmissionFlattenedData" stripe scrollbar-always-on style="width: 95%" class="mt-3 mb-3 ml-5 data-table">
                                      
                                        <el-table-column prop="year" label="Year"  sortable />

                                        <el-table-column
                                            v-for="(column, index) in projectEmissionTableColumns"
                                            :key="index"
                                            :prop="column.prop"
                                            :label="column.label"
                                            sortable
                                        >
                                            <template #default="scope">
                                            {{
                                                scope.row[column.prop] == null
                                                ? ""
                                                : Number(scope.row[column.prop]).toLocaleString()
                                            }}
                                            </template>
                                        </el-table-column>
                                </el-table> -->
                                <!-- scenario abatement table -->

                                <!-- scenario abatement table -->
                                <el-table :data="projectEmissionFlattenedData" stripe scrollbar-always-on style="width: 95%" class="mt-3 mb-3 ml-5 data-table">
                                    <el-table-column prop="year" label="Year" >
                                        <!-- <template #default="scope">
                                            Scenario {{ scope.row.scenarioId }}
                                        </template> -->
                                    </el-table-column>
                                    <el-table-column
                                    v-for="column in projectEmissionTableColumns"
                                    :key="column.label"
                                    :label="column.label"
                                    > 
                                    <el-table-column
                                        v-for="child in column.children"
                                        :key="child.label"
                                        :label="child.label"
                                        :prop="child.prop"
                                    >
                                        <template #default="scope">
                                        {{
                                            scope.row[child.prop] == null
                                            ? ""
                                            : Number(scope.row[child.prop]).toLocaleString()
                                        }}
                                        </template>
                                    </el-table-column>
                                    </el-table-column>
                                </el-table>
                           
                                <!-- scenario abatement table -->

                            </div>

                        </div>
                        <!-- scenario abatement table END -->

                       <!-- scenario baseline emission table -->
                       <div class="estimaTotalTable">
                            <!-- show table when charging type ac and dc -->

                            <div class=" mt-3 bg-me-primary border-color-light width-100-p rounded-4">
                                <h5 class="text-white mt-3 ml-5">Scenarios Baseline Emission (tCO2-e)</h5>

                                <!-- scenario abatement table -->
                                <!-- <el-table :data="baselineEmissionFlattenedData" stripe scrollbar-always-on style="width: 95%" class="mt-3 mb-3 ml-5 data-table">
                                        
                                        <el-table-column prop="year" label="Year"  sortable />

                                       
                                        <el-table-column
                                            v-for="(column, index) in baselineEmissionTableColumns"
                                            :key="index"
                                            :prop="column.prop"
                                            :label="column.label"
                                            sortable
                                        >
                                            <template #default="scope">
                                            {{
                                                scope.row[column.prop] == null
                                                ? ""
                                                : Number(scope.row[column.prop]).toLocaleString()
                                            }}
                                            </template>
                                        </el-table-column>
                                </el-table> -->
                                <!-- scenario baseline emission table -->

                                <el-table :data="baselineEmissionFlattenedData" stripe scrollbar-always-on style="width: 95%" class="mt-3 mb-3 ml-5 data-table">
                                    <el-table-column prop="year" label="Year" >
                                        <!-- <template #default="scope">
                                            Scenario {{ scope.row.scenarioId }}
                                        </template> -->
                                    </el-table-column>
                                    <el-table-column
                                    v-for="column in baselineEmissionTableColumns"
                                    :key="column.label"
                                    :label="column.label"
                                    > 
                                    <el-table-column
                                        v-for="child in column.children"
                                        :key="child.label"
                                        :label="child.label"
                                        :prop="child.prop"
                                    >
                                        <template #default="scope">
                                        {{
                                            scope.row[child.prop] == null
                                            ? ""
                                            : Number(scope.row[child.prop]).toLocaleString()
                                        }}
                                        </template>
                                    </el-table-column>
                                    </el-table-column>
                                </el-table>

                            </div>

                        </div>
                        <!-- scenario baseline emission END -->
            </div> 


            <div class="html2pdf__page-break"/>

                <!-- two button  -->
                <div id="two-buttons-pdf" v-if="showButtons">
                    <div>

                    <el-row class="m-0 width-100-p mt-3">
                        <el-col class="d-flex align-item-center" :span="$vuetify.display.width >= 1618 ? 3 : ($vuetify.display.width >= 683 ? 6 : ($vuetify.display.width >= 393 ? 12 : 24))">
                            <el-button @click="exportPdf"
                                color="#ddf0ac"
                                class="height-40 rounded-3"
                            >
                                <svg class="stroke-icon width-20 height-20">
                                <use
                                    href="@/assets/svg/icon-sprite.svg#estimator_pdf"
                                ></use>
                                </svg>
                                <span class="me-primary pl-3"> Export to PDF </span>
                            </el-button>
                        </el-col>

                        <el-col class="d-flex align-item-center" :span="$vuetify.display.width >= 1618 ? 2 : ($vuetify.display.width >= 683 ? 3 : ($vuetify.display.width >= 393 ? 12 : 24))">
                            <el-button
                                @click="handleExportFile"
                                color="#0D3937"
                                class="height-40 border-color-light rounded-3 mr-1"
                                >
                                <i class="text-white fa fa-cloud-download"></i>
                            </el-button>
                        </el-col>

                        <el-col class="d-flex align-item-center" :span="$vuetify.display.width >= 1618 ? 2 : ($vuetify.display.width >= 683 ? 3 : ($vuetify.display.width >= 393 ? 12 : 24))">
                            <el-button @click="resetForm"
                                color="#0D3937"
                                class="height-40 rounded-3 border-color-light"
                                >
                                <svg class="stroke-icon width-20 height-20">
                                    <use
                                        href="@/assets/svg/icon-sprite.svg#estimator_reset_btn"
                                        ></use>
                                </svg>
                                    <span class="text-white pl-3"> Reset </span>
                            </el-button> 
                        </el-col>


                    </el-row>

                    </div>

                </div>
                 <!-- two button  -->

        </div>
    </div>

</template>

<script>

import { mapState } from "vuex";
import estimatorCharts from "./estimatorCharts.vue";

import XLSX from "xlsx";



export default{
    data(){
        return {
            showButtons: true, //control display button or not

            // expandedScenarioResultData: [],

            chartKey: 0,
            
        }
    },
    props:{
        moreScrenarios: {
            type: Boolean,
        }
    },
    computed: {

        // data from store for total tables
        ...mapState('estimator', {
            estimatorData: state => state.estimatorData
        }),


        // Scenario input
        ...mapState('estimator', {
            scenarioInputData: state => state.scenarios
        }),

        // Scenario output
        ...mapState('estimator', {
            scenarioResultData: state => state.scenarioResults
        }),

        ...mapState('estimator', {
            ac_and_dc: state => state.ac_and_dc
        }),

        combinedData() {
        if (!this.estimatorData) {
            console.log("Estimator data is missing or not valid.");
            return[];  // Return an empty array if estimatorData is invalid
        }
        // when only ac or dc is selected
        if (!this.estimatorData.ac || !this.estimatorData.dc) {

            
            return this.estimatorData;
        }

        
        return this.estimatorData.ac_dc;

        },

        groupedCombinedData() {

            const groupedData = {};

            const data = this.estimatorData;

            let sum_ac_abatement = 0;
            let sum_ac_project_emission = 0;
            let sum_ac_baseline_emission = 0;
            let sum_ac_abatement_per_mwh = 0;

            let sum_dc_abatement = 0;
            let sum_dc_project_emission = 0;  
            let sum_dc_baseline_emission = 0;
            let sum_dc_abatement_per_mwh = 0;

            if (!data.ac_dc) {

                data.forEach((item) => {
                    const {year, abatement, project_emission, baseline_emission, abatement_per_mwh, location, charging_type } = item;
                    
                    if (charging_type === "AC") {

                        sum_ac_abatement += abatement || 0;
                        sum_ac_project_emission += project_emission || 0;
                        sum_ac_baseline_emission += baseline_emission || 0;
                        sum_ac_abatement_per_mwh += abatement_per_mwh || 0;

                        if (!groupedData[year]) {
                            groupedData[year] = {
                                ac_data: [],
                                location: location
                            };
                        }

                        groupedData[year].ac_data.push({
                            abatement,
                            project_emission,
                            baseline_emission,
                            abatement_per_mwh,
                        });
                    }

                    if (charging_type === "DC") {

                        sum_dc_abatement += abatement || 0;
                        sum_dc_project_emission += project_emission || 0;
                        sum_dc_baseline_emission += baseline_emission || 0;
                        sum_dc_abatement_per_mwh += abatement_per_mwh || 0;

                        if (!groupedData[year]) {
                            groupedData[year] = {
                                dc_data: [],
                                location: location
                            };
                        }

                        groupedData[year].dc_data.push({
                            dc_abatement: abatement,
                            dc_project_emission: project_emission,
                            dc_baseline_emission: baseline_emission,
                            dc_abatement_per_mwh: abatement_per_mwh,
                        });
                    }
                });

                if(groupedData[0].ac_data) {
                    groupedData['Total'] = {
                        ac_data: [
                            {
                                abatement: sum_ac_abatement,
                                project_emission: sum_ac_project_emission,
                                baseline_emission: sum_ac_baseline_emission,
                                abatement_per_mwh: sum_ac_abatement_per_mwh / 11,
                            }
                        ],
                        location: ""
                    };
                }
                if(groupedData[0].dc_data) {
                    groupedData['Total'] = {
                        dc_data: [
                            {
                                dc_abatement: sum_dc_abatement,
                                dc_project_emission: sum_dc_project_emission,
                                dc_baseline_emission: sum_dc_baseline_emission,
                                dc_abatement_per_mwh: sum_dc_abatement_per_mwh / 11,
                            }
                        ],
                        location: ""
                    };
                }
            }

            if (data.ac_dc) {
                
                const newData = data.ac_dc;

                newData.forEach((item) => {

                const {year, abatement, dc_abatement, project_emission, dc_project_emission, baseline_emission, dc_baseline_emission, abatement_per_mwh, dc_abatement_per_mwh, location } = item;

                sum_ac_abatement += abatement || 0;
                sum_ac_project_emission += project_emission || 0;
                sum_ac_baseline_emission += baseline_emission || 0;
                sum_ac_abatement_per_mwh += abatement_per_mwh || 0;
                sum_dc_abatement += dc_abatement || 0;
                sum_dc_project_emission += dc_project_emission || 0;
                sum_dc_baseline_emission += dc_baseline_emission || 0;
                sum_dc_abatement_per_mwh += dc_abatement_per_mwh || 0;
               
                if (!groupedData[year]) {
                    groupedData[year] = {
                        ac_data: [],
                        dc_data: [],
                        ac_dc_data: [],
                        location: location
                    };
                }

                groupedData[year].ac_data.push({
                    abatement,
                    project_emission,
                    baseline_emission,
                    abatement_per_mwh,
                });

                groupedData[year].dc_data.push({
                    dc_abatement,
                    dc_project_emission,
                    dc_baseline_emission,
                    dc_abatement_per_mwh,
                });

                // sum of ac and dc
                groupedData[year].ac_dc_data.push({
                    total_abatement: abatement + dc_abatement,
                    total_project_emission: project_emission + dc_project_emission,
                    total_baseline_emission: baseline_emission + dc_baseline_emission,
                    total_abatement_per_mwh: abatement_per_mwh + dc_abatement_per_mwh,
                });


            });

            groupedData['Total'] = {
                ac_data: [
                    {
                        abatement: sum_ac_abatement,
                        project_emission: sum_ac_project_emission,
                        baseline_emission: sum_ac_baseline_emission,
                        abatement_per_mwh: sum_ac_abatement_per_mwh / 11,
                    }
                ],
                dc_data: [
                    {
                        dc_abatement: sum_dc_abatement,
                        dc_project_emission: sum_dc_project_emission,
                        dc_baseline_emission: sum_dc_baseline_emission,
                        dc_abatement_per_mwh: sum_dc_abatement_per_mwh / 11,
                    }
                ],
                ac_dc_data: [
                    {
                        total_abatement: sum_ac_abatement + sum_dc_abatement,
                        total_project_emission: sum_ac_project_emission + sum_dc_project_emission,
                        total_baseline_emission: sum_ac_baseline_emission + sum_dc_baseline_emission,
                        total_abatement_per_mwh: (sum_ac_abatement_per_mwh + sum_dc_abatement_per_mwh) / 11,
                    }
                ],
                location: ""
            };
            
        }

            // console.log("grouped combined data", groupedData);
            
            return groupedData;
        },

        flattenedCombinedData() {

            const groupedData = this.groupedCombinedData;

            const flattenedData = [];

            Object.keys(groupedData).forEach( year => {
                const yearData = groupedData[year];
                const row = { Year: year, Location: yearData.location };

                
                if (yearData.ac_data) {
                    yearData.ac_data.forEach((acItem) => {
                    row[`AC_abatement`] = acItem.abatement;
                    row[`AC_project_emission`] = acItem.project_emission;
                    row[`AC_baseline_emission`] = acItem.baseline_emission;
                    row[`AC_abatement_per_mwh`] = acItem.abatement_per_mwh;
                });
                }

                if (yearData.dc_data) {
                    yearData.dc_data.forEach((dcItem) => {
                    row[`DC_abatement`] = dcItem.dc_abatement;
                    row[`DC_project_emission`] = dcItem.dc_project_emission;
                    row[`DC_baseline_emission`] = dcItem.dc_baseline_emission;
                    row[`DC_abatement_per_mwh`] = dcItem.dc_abatement_per_mwh;
                });
                }

                if (yearData.ac_dc_data) {
                    yearData.ac_dc_data.forEach((ac_dcItem) => {
                    row[`TOTAL_abatement`] = ac_dcItem.total_abatement;
                    row[`TOTAL_project_emission`] = ac_dcItem.total_project_emission;
                    row[`TOTAL_baseline_emission`] = ac_dcItem.total_baseline_emission;
                    row[`TOTAL_abatement_per_mwh`] = ac_dcItem.total_abatement_per_mwh;
                });
                }
            
                flattenedData.push(row);
            });

            const hasTotalData = flattenedData.some(row => row['TOTAL_abatement'] !== undefined);
            if (hasTotalData) {
                const columnOrder = [
                'Year', 'Location',
                'AC_abatement', 'DC_abatement', 'TOTAL_abatement',
                'AC_project_emission', 'DC_project_emission', 'TOTAL_project_emission',
                'AC_baseline_emission', 'DC_baseline_emission', 'TOTAL_baseline_emission',
                'AC_abatement_per_mwh', 'DC_abatement_per_mwh', 'TOTAL_abatement_per_mwh'
                ];

                const reorderedData = flattenedData.map(row => {
                const reorderedRow = {};
                columnOrder.forEach(col => {
                    reorderedRow[col] = row[col] !== undefined ? row[col] : null;
                });
                return reorderedRow;
                });

                console.log("flattened combined data", reorderedData);

                return reorderedData;
            } else {
                return flattenedData;
            }

        },

        tableColumnsCombinedData() {

            const data = this.groupedCombinedData;


            const headers = [
               
            ];

            const level1Headers = ['abatement', 'project_emission', 'baseline_emission', 'abatement_per_mwh'];
            const level2Headers = [];

            if (data[0].ac_data) {
                level2Headers.push('ac');
            }
            if (data[0].dc_data) {
                level2Headers.push('dc');
            }
            if (data[0].ac_dc_data) {
                level2Headers.push('total');
            }

            level1Headers.forEach(level1 => {
                const children = level2Headers.map(level2 => {
                    return {
                        label: `${level2.toUpperCase()}`,
                        prop: `${level2.toUpperCase()}_${level1}`
                    };
                });

                headers.push({
                    label: level1.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
                    children
                });
            });

            // console.log(" columns combined data", headers);
            
            return headers;
        },

        inputTableData() {
        if (!this.estimatorData) {
            console.log("Estimator data is missing or not valid.");
            return [];  // Return an empty array if estimatorData is invalid
        }
            // when only ac or dc is selected
        if (!this.estimatorData.ac || !this.estimatorData.dc) {
            return this.estimatorData.filter(item=>item.year===0);
        }
        // Combine ac and dc data into one array
        return [
            ...(this.estimatorData.ac || []).filter(item => item.year === 0).map(item => ({ ...item, type: 'ac' })),
            ...(this.estimatorData.dc || []).filter(item => item.year === 0).map(item => ({ ...item, type: 'dc' }))
        ];
        },

        // scenario input data when has two charger type
        expandedScenarioData() {
            return this.scenarioInputData.flatMap((scenario) => {
            const rows = [];
            if (scenario.data.showAC) {
                rows.push({
                id: scenario.id,
                // name: scenario.name,
                location: scenario.data.location,
                charging_type: "AC",
                usage: scenario.data.ac_au,
                annual_growth_in_usage: scenario.data.ac_agu,
                ei: scenario.data.b_gei,
                annual_emission_intensity_improvement: scenario.data.b_aeii,
                
                });
            }
            if (scenario.data.showDC) {
                rows.push({
                id: scenario.id,
                location: scenario.data.location,
                // name: scenario.name,
                charging_type: "DC",
                usage: scenario.data.dc_au,
                annual_growth_in_usage: scenario.data.dc_agu,
                ei: scenario.data.b_gei,
                annual_emission_intensity_improvement: scenario.data.b_aeii,
                
                });
            }
            return rows;
            });
        },

        
        // for senario abatement table
        Test_ScenarioAbatementResultData() {
            const aggregatedData = [];

            this.scenarioResultData.forEach(scenario => {
                const data = scenario.data;
                // console.log("TEST Abatement data:", data);
                const id = scenario.scenario_id;

                // add sum of abatement
                let sum_ac_abatement = 0;
                let sum_dc_abatement = 0;
                // add sum of abatement per mwh
                let sum_ac_abatement_per_mwh = 0;
                let sum_dc_abatement_per_mwh = 0;

               


    
            if (!data.ac_dc) {
                
                data.forEach(item => {

                    if(item.charging_type === "AC") {
                        sum_ac_abatement += item.abatement || 0;
                        sum_ac_abatement_per_mwh += item.abatement_per_mwh || 0;
                        aggregatedData.push(
                        {
                            Scenario_ID: id,
                            Year: item.year,
                            AC_Abatement: item.abatement || 0,
                            AC_Abatement_per_mwh: item.abatement_per_mwh || 0
                        });
                    }
                    if(item.charging_type === "DC") {
                        sum_dc_abatement += item.abatement || 0;
                        sum_dc_abatement_per_mwh += item.abatement_per_mwh || 0;
                        aggregatedData.push(
                        {
                            Scenario_ID: id,
                            Year: item.year,
                            DC_Abatement: item.abatement || 0,
                            DC_Abatement_per_mwh: item.abatement_per_mwh  || 0
                        });
                    }
                });
                aggregatedData.push(
                    {
                        Scenario_ID: id,
                        Year: "Total",
                        AC_Abatement: sum_ac_abatement,
                        DC_Abatement: sum_dc_abatement,
                        AC_Abatement_per_mwh: sum_ac_abatement_per_mwh / 11,
                        DC_Abatement_per_mwh: sum_dc_abatement_per_mwh / 11,

                        // added total columns for sum of ac and dc
                        Total_AC_DC_Abatement: sum_ac_abatement + sum_dc_abatement,
                        Total_AC_DC_Abatement_per_mwh: sum_ac_abatement_per_mwh / 11 + sum_dc_abatement_per_mwh / 11,
                    }
                );
            }

            if (data.ac_dc) {
                // console.log("Abatement data.ac_dc", data.ac_dc);
                const newData = data.ac_dc;
                newData.forEach(item => {
                    sum_ac_abatement += item.abatement || 0;
                    sum_dc_abatement += item.dc_abatement || 0;
                    sum_ac_abatement_per_mwh += item.abatement_per_mwh || 0;
                    sum_dc_abatement_per_mwh += item.dc_abatement_per_mwh || 0;

                    aggregatedData.push({
                        Scenario_ID: id,
                        Year: item.year,
                        AC_Abatement: item.abatement || 0,
                        DC_Abatement: item.dc_abatement || 0,
                        AC_Abatement_per_mwh: item.abatement_per_mwh || 0,
                        DC_Abatement_per_mwh: item.dc_abatement_per_mwh || 0,

                        // added total columns for sum of ac and dc
                        Total_AC_DC_Abatement: item.abatement + item.dc_abatement,
                        Total_AC_DC_Abatement_per_mwh: item.abatement_per_mwh + item.dc_abatement_per_mwh,
                    });
                });
                aggregatedData.push({
                    Scenario_ID: id,
                    Year: "Total",
                    AC_Abatement: sum_ac_abatement,
                    DC_Abatement: sum_dc_abatement,
                    AC_Abatement_per_mwh: sum_ac_abatement_per_mwh / 11,
                    DC_Abatement_per_mwh: sum_dc_abatement_per_mwh / 11,

                    // added total columns for sum of ac and dc
                    Total_AC_DC_Abatement: sum_ac_abatement + sum_dc_abatement,
                    Total_AC_DC_Abatement_per_mwh: sum_ac_abatement_per_mwh / 11 + sum_dc_abatement_per_mwh / 11,
                });
            }


            });
            // console.log("scenario abatement table:", aggregatedData);
            // console.log("scenario abatement grouped data", this.Test_groupDataByYear(aggregatedData));
            
            // console.log("scenario total data:" ,this.ScenarioTotalData); //test
            // console.log("scenario total grouped data", this.GroupedScenarioTotalData); //test
            // console.log("scenario total column:", this.generateTotalColumns); //test
            // console.log("total flattened data:", this.generateFlattenedTotalData);
            
            return aggregatedData;
        },

        abatementGroupedData() {
            // console.log("Test_ScenarioAbatementResultData", this.Test_ScenarioAbatementResultData);
            // console.log("Test_GROUP", this.Test_groupDataByYear(this.Test_ScenarioAbatementResultData));
            return this.Test_groupDataByYear(this.Test_ScenarioAbatementResultData);
            // return this.generateGroupedData(this.ScenarioAbatementResultData);
        },

        abatementFlattenedData() {
            // console.log("test flattee data", this.Test_flattenGroupedData(this.abatementGroupedData));
            
            // return this.generateFlattenedData(this.abatementGroupedData);
            return this.Test_flattenGroupedData(this.abatementGroupedData);
        },

        abatementTableColumns() {
            // console.log("test header", this.Test_generateDynamicTableHeader(this.abatementGroupedData));
            return this.Test_generateDynamicTableHeader(this.abatementGroupedData);
            // return this.generateTableColumns(this.abatementGroupedData);
        },
        // for senario abatement table end
    
        // for scenario total table start
        ScenarioTotalData() {
            const aggregatedData = [];

            this.scenarioResultData.forEach(scenario => {
                const data = scenario.data;
                const id = scenario.scenario_id;

                let sum_ac_abatement = 0;
                let sum_ac_project_emission = 0;
                let sum_ac_baseline_emission = 0;
                let sum_dc_abatement = 0;
                let sum_dc_project_emission = 0;
                let sum_dc_baseline_emission = 0;

                if (!data.ac_dc) {
                    data.forEach(item => {
                        if (item.charging_type === "AC") {
                            sum_ac_abatement += item.abatement || 0;
                            sum_ac_project_emission += item.project_emission || 0;
                            sum_ac_baseline_emission += item.baseline_emission || 0;
                        }
                        if (item.charging_type === "DC") {
                            sum_dc_abatement += item.abatement || 0;
                            sum_dc_project_emission += item.project_emission || 0;
                            sum_dc_baseline_emission += item.baseline_emission || 0;
                        }
                    });
                }

                if (data.ac_dc) {
                    const newData = data.ac_dc;


                    newData.forEach(item => {
                        sum_ac_abatement += item.abatement || 0;
                        sum_ac_project_emission += item.project_emission || 0;
                        sum_ac_baseline_emission += item.baseline_emission || 0;
                        sum_dc_abatement += item.dc_abatement || 0;
                        sum_dc_project_emission += item.dc_project_emission || 0;
                        sum_dc_baseline_emission += item.dc_baseline_emission || 0;

                    });
                }

                if (
                    sum_ac_abatement !== 0 ||
                    sum_ac_project_emission !== 0 ||
                    sum_ac_baseline_emission !== 0 ||
                    sum_dc_abatement !== 0 ||
                    sum_dc_project_emission !== 0 ||
                    sum_dc_baseline_emission !== 0
                ) {
                aggregatedData.push({
                    Scenario_ID: id,
                    AC_Abatement: sum_ac_abatement,
                    AC_Project_Emission: sum_ac_project_emission,
                    AC_Baseline_Emission: sum_ac_baseline_emission,
                    DC_Abatement: sum_dc_abatement,
                    DC_Project_Emission: sum_dc_project_emission,
                    DC_Baseline_Emission: sum_dc_baseline_emission,

                    // added total columns for sum of ac and dc
                    Total_AC_DC_Abatement: sum_ac_abatement + sum_dc_abatement,
                    Total_AC_DC_Project_Emission: sum_ac_project_emission + sum_dc_project_emission,
                    Total_AC_DC_Baseline_Emission: sum_ac_baseline_emission + sum_dc_baseline_emission,

    
                });
             }
            });

            // console.log("Scenario Total data:", aggregatedData);
            return aggregatedData;
        },

        GroupedScenarioTotalData() {

            const groupedData = [];

            this.ScenarioTotalData.forEach(scenario => {
            const Abatement = [];
            const Project_Emission = [];
            const Baseline_Emission = [];

            if (scenario.AC_Abatement !== 0 || scenario.AC_Project_Emission !== 0 || scenario.AC_Baseline_Emission !== 0) {
                Abatement.push({ AC: scenario.AC_Abatement });
                Project_Emission.push({ AC: scenario.AC_Project_Emission });
                Baseline_Emission.push({ AC: scenario.AC_Baseline_Emission });
            }

            if (scenario.DC_Abatement !== 0 || scenario.DC_Project_Emission !== 0 || scenario.DC_Baseline_Emission !== 0) {
                Abatement.push({ DC: scenario.DC_Abatement });
                Project_Emission.push({ DC: scenario.DC_Project_Emission });
                Baseline_Emission.push({ DC: scenario.DC_Baseline_Emission });
            }

            // added total columns for sum of ac and dc
            if (scenario.Total_AC_DC_Abatement !== 0 || scenario.Total_AC_DC_Project_Emission !== 0 || scenario.Total_AC_DC_Baseline_Emission !== 0) {
                Abatement.push({ Total: scenario.Total_AC_DC_Abatement });
                Project_Emission.push({ Total: scenario.Total_AC_DC_Project_Emission });
                Baseline_Emission.push({ Total: scenario.Total_AC_DC_Baseline_Emission });
            }

            groupedData.push({
                Scenario_ID: scenario.Scenario_ID,
                Abatement: Abatement,
                Project_Emission: Project_Emission,
                Baseline_Emission: Baseline_Emission
            });
            });

            return groupedData;
        },

        generateFlattenedTotalData() {
            const flattenedData = [];

            this.GroupedScenarioTotalData.forEach((scenario) => {
            const row = { scenarioId: parseInt(scenario.Scenario_ID) };

            // Process each category (Abatement, Project_Emission, Baseline_Emission)
            Object.keys(scenario).forEach((key) => {
                if (key === "Scenario_ID") return; // Skip Scenario_ID field

                scenario[key].forEach((entry) => {
                Object.keys(entry).forEach((subKey) => {
                    const propKey = `${key}_${subKey}`;
                    row[propKey] = entry[subKey];
                });
                });
            });

            flattenedData.push(row);
            });

            return flattenedData;
    },

        generateTotalColumns() {
        
            const headers = ["Abatement", "Project_Emission", "Baseline_Emission"];
            const subHeaders = ["AC", "DC", "Total"];

            const totalTableColumn = headers.map((header) => ({
                label: header.replace("_", " "),
                children: subHeaders.map((subHeader) => ({
                    label: subHeader,
                    prop: `${header}_${subHeader}`
                }))
            }));
            
       
            return totalTableColumn;
        },

        // for senario project emission table
        ScenarioProjectEmissionResultData() {
            const aggregatedData = [];

            this.scenarioResultData.forEach(scenario => {
                const data = scenario.data;
                // console.log("ProjectEmission data:", data);
                const id = scenario.scenario_id;

                let sum_project_emission = 0;
                let sum_ac_project_emission = 0;
                let sum_dc_project_emission = 0;

            if (!data.ac_dc) {
                
                data.forEach(item => {
                    sum_project_emission += item.project_emission || 0;
                    aggregatedData.push(
                    {
                        Scenario_ID: id,
                        Year: item.year,
                        Project_Emission: item.project_emission || 0,
                    });
                });
                aggregatedData.push(
                    {
                        Scenario_ID: id,
                        Year: "Total",
                        Project_Emission: sum_project_emission,
                    }
                );
            }

            if (data.ac_dc) {
                // console.log("ProjectEmission data.ac_dc", data.ac_dc);
                const newData = data.ac_dc;
                newData.forEach(item => {
                    sum_ac_project_emission += item.project_emission || 0;
                    sum_dc_project_emission += item.dc_project_emission || 0;
                    aggregatedData.push({
                        Scenario_ID: id,
                        Year: item.year,
                        AC_Project_Emission: item.project_emission
                        || 0,
                        DC_Project_Emission: item.dc_project_emission || 0,

                        // added total columns for sum of ac and dc
                        Total_AC_DC_Project_Emission: item.project_emission + item.dc_project_emission,
                    });
                });
                aggregatedData.push({
                    Scenario_ID: id,
                    Year: "Total",
                    AC_Project_Emission: sum_ac_project_emission,
                    DC_Project_Emission: sum_dc_project_emission,

                    // added total columns for sum of ac and dc
                    Total_AC_DC_Project_Emission: sum_ac_project_emission + sum_dc_project_emission,
                });
            }
            });
            // console.log(aggregatedData);
            return aggregatedData;
        },

        projectEmissiongroupedData() {

            // console.log("projectEmissiongroupedData", this.generateGroupedData(this.ScenarioProjectEmissionResultData));
            
            return this.generateGroupedData(this.ScenarioProjectEmissionResultData);
        },

            
        projectEmissionFlattenedData() {

            // console.log("projectEmissionFlattenedData", this.generateFlattenedData(this.projectEmissiongroupedData));
            return this.generateFlattenedData(this.projectEmissiongroupedData);
        },

        projectEmissionTableColumns() {

            // console.log("projectEmissionTableColumns", this.generateTableColumns(this.projectEmissiongroupedData));
            return this.generateTableColumns(this.projectEmissiongroupedData);
        },
        // for senario project emission table end

        // for senario Baseline emission table
        ScenarioBaselineEmissionResultData() {
            const aggregatedData = [];

            this.scenarioResultData.forEach(scenario => {
                const data = scenario.data;
                // console.log("BaselineEmission data:", data);
                const id = scenario.scenario_id;

                let sum_baseline_emission = 0;
                let sum_ac_baseline_emission = 0;
                let sum_dc_baseline_emission = 0;

            if (!data.ac_dc) {
                
                data.forEach(item => {
                    sum_baseline_emission += item.baseline_emission || 0;
                    aggregatedData.push(
                    {
                        Scenario_ID: id,
                        Year: item.year,
                        Baseline_Emission: item.baseline_emission
                        || 0
                    });
                });
                aggregatedData.push(
                    {
                        Scenario_ID: id,
                        Year: "Total",
                        Baseline_Emission: sum_baseline_emission,
                    }
                );
            }

            if (data.ac_dc) {
                // console.log("BaselineEmission data.ac_dc", data.ac_dc);
                const newData = data.ac_dc;
                newData.forEach(item => {
                    sum_ac_baseline_emission += item.baseline_emission || 0;
                    sum_dc_baseline_emission += item.dc_baseline_emission || 0;

                    aggregatedData.push({
                        Scenario_ID: id,
                        Year: item.year,
                        AC_Baseline_Emission: item.baseline_emission
                        || 0,
                        DC_Baseline_Emission: item.dc_baseline_emission
                        || 0,

                        // added total columns for sum of ac and dc
                        Total_AC_DC_Baseline_Emission: item.baseline_emission + item.dc_baseline_emission,
                    });
                });
                aggregatedData.push({
                    Scenario_ID: id,
                    Year: "Total",
                    AC_Baseline_Emission: sum_ac_baseline_emission,
                    DC_Baseline_Emission: sum_dc_baseline_emission,

                    // added total columns for sum of ac and dc
                    Total_AC_DC_Baseline_Emission: sum_ac_baseline_emission + sum_dc_baseline_emission,
                });
            }
            });
            // console.log(aggregatedData);
            return aggregatedData;
        },

        baselineEmissiongroupedData() {
            return this.generateGroupedData(this.ScenarioBaselineEmissionResultData);
        },

        baselineEmissionFlattenedData() {
            return this.generateFlattenedData(this.baselineEmissiongroupedData);
        },

        baselineEmissionTableColumns() {
            return this.generateTableColumns(this.baselineEmissiongroupedData);
        },
        // for senario baseline emission table end


    },

    components:{
        estimatorCharts,
 
    },

    methods: {

        // export xlsx file feature
        handleExportFile() {

            // single scenario export feature
            if (!this.moreScrenarios) {
                // Prepare the data
                const data = this.flattenedCombinedData;
                const headers = this.tableColumnsCombinedData;

                console.log("single scenario data", data);
                console.log("single scenario headers", headers);
                
                

                const inputSummary = this.inputTableData;

                // console.log("headers", headers);
                
                // Create a new workbook and worksheet
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.aoa_to_sheet([]);

                // Add headers to the worksheet
                const headerRows = [["","Year"], ["","Location"]];
                headers.forEach(header => {
                    if (header.children) {
                    header.children.forEach(child => {
                        headerRows.push([header.label, child.label]);
                    });
                    } else {
                    headerRows.push([header.label]);
                    }
                });

                // Flatten the header rows for multi-level headers
                const flattenedHeaderRows = headerRows.reduce((acc, row) => {
                    row.forEach((cell, index) => {
                    if (!acc[index]) acc[index] = [];
                    acc[index].push(cell);
                    });
                    return acc;
                }, []);

                // Add the flattened header rows to the worksheet
                flattenedHeaderRows.forEach((row, index) => {
                    XLSX.utils.sheet_add_aoa(ws, [row], { origin: { r: index, c: 0 } });
                });

                // Create a new worksheet for inputTableData
                const wsInput = XLSX.utils.json_to_sheet(inputSummary);
                
                // Append the inputTableData worksheet to the workbook
                XLSX.utils.book_append_sheet(wb, wsInput, 'Input Summary');
            
                // Add data to the worksheet
                XLSX.utils.sheet_add_json(ws, data, { origin: -1, skipHeader: true });

                // Append the worksheet to the workbook
                XLSX.utils.book_append_sheet(wb, ws, 'Total Emission Data');

                // Export the workbook
                XLSX.writeFile(wb, 'estimator_result.xlsx');
        }

        // export xlsx file feature for more than one scenario
        if(this.moreScrenarios) {
            // Prepare the data
            // const data = this.flattenedCombinedData;
            // const headers = this.tableColumnsCombinedData;

            // input summary table
            const inputSummary = this.expandedScenarioData;

            // scenario total table
            const totalData = this.generateFlattenedTotalData;
            const totalDataHeaders = this.generateTotalColumns;

            // projec emission table
            const projectData = this.projectEmissionFlattenedData;
            const projectHeaders = this.projectEmissionTableColumns;

            // baseline emission table
            const baselineData = this.baselineEmissionFlattenedData;
            const baselineHeaders = this.baselineEmissionTableColumns;

            // abatement table
            const abatementData = this.abatementFlattenedData;
            const abatementHeaders = this.abatementTableColumns;

            // console.log("abatement data", abatementData);
            

        
            // Create a new workbook and worksheet
            const wb = XLSX.utils.book_new();
            const total_ws = XLSX.utils.aoa_to_sheet([]);
            const project_ws = XLSX.utils.aoa_to_sheet([]);
            const baseline_ws = XLSX.utils.aoa_to_sheet([]);
            const abatement_ws = XLSX.utils.aoa_to_sheet([]);
        // table 001 input summary

            // Create a new worksheet for inputTableData
            const wsInput = XLSX.utils.json_to_sheet(inputSummary);
            
            // Append the inputTableData worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, wsInput, 'Input Summary');

        // table 002 total table

            // Add headers to the worksheet
            const totalHeaderRows = [["","Scenario Name"]];
            totalDataHeaders.forEach(header => {
                if (header.children) {
                header.children.forEach(child => {
                    totalHeaderRows.push([header.label, child.label]);
                });
                } else {
                    totalHeaderRows.push([header.label]);
                }
            });

            // Flatten the header rows for multi-level headers
            const flattenedTotalHeaderRows = totalHeaderRows.reduce((acc, row) => {
                row.forEach((cell, index) => {
                if (!acc[index]) acc[index] = [];
                acc[index].push(cell);
                });
                return acc;
            }, []);

            // Add the flattened header rows to the worksheet
            flattenedTotalHeaderRows.forEach((row, index) => {
                XLSX.utils.sheet_add_aoa(total_ws, [row], { origin: { r: index, c: 0 } });
            });

        // table 003 project emission

            // Add headers to the worksheet
            const projectHeaderRows = [["","Year"]];
            projectHeaders.forEach(header => {
                if (header.children) {
                header.children.forEach(child => {
                    projectHeaderRows.push([header.label, child.label]);
                });
                } else {
                    projectHeaderRows.push([header.label]);
                }
            });

            // Flatten the header rows for multi-level headers
            const flattenedProjectHeaderRows = projectHeaderRows.reduce((acc, row) => {
                row.forEach((cell, index) => {
                if (!acc[index]) acc[index] = [];
                acc[index].push(cell);
                });
                return acc;
            }, []);

            // Add the flattened header rows to the worksheet
            flattenedProjectHeaderRows.forEach((row, index) => {
                XLSX.utils.sheet_add_aoa(project_ws, [row], { origin: { r: index, c: 0 } });
            });

        // table 004 baseline emission

            // Add headers to the worksheet
            const baselineHeaderRows = [["","Year"]];
            baselineHeaders.forEach(header => {
                if (header.children) {
                header.children.forEach(child => {
                    baselineHeaderRows.push([header.label, child.label]);
                });
                } else {
                baselineHeaderRows.push([header.label]);
                }
            });

            // Flatten the header rows for multi-level headers
            const flattenedBaselineHeaderRows = baselineHeaderRows.reduce((acc, row) => {
                row.forEach((cell, index) => {
                if (!acc[index]) acc[index] = [];
                acc[index].push(cell);
                });
                return acc;
            }, []);

            // Add the flattened header rows to the worksheet
            flattenedBaselineHeaderRows.forEach((row, index) => {
                XLSX.utils.sheet_add_aoa(baseline_ws, [row], { origin: { r: index, c: 0 } });
            });

        // table 005 abatement
        
            // Add headers to the worksheet
            const abatementHeaderRows = [["","","Year"]];
            abatementHeaders.forEach(header => {
                if (header.children) {
                header.children.forEach(child => {
                    if (child.children) {
                    child.children.forEach(grandchild => {
                        abatementHeaderRows.push([header.label, child.label, grandchild.label]);
                    });
                    } else {
                    abatementHeaderRows.push([header.label, child.label]);
                }
            });
            } else {
                abatementHeaderRows.push([header.label]);
            }
            });

            // Flatten the header rows for multi-level headers
            const flattenedAbatementHeaderRows = abatementHeaderRows.reduce((acc, row) => {
                row.forEach((cell, index) => {
                if (!acc[index]) acc[index] = [];
                acc[index].push(cell);
                });
                return acc;
            }, []);

            // Add the flattened header rows to the worksheet
            flattenedAbatementHeaderRows.forEach((row, index) => {
                XLSX.utils.sheet_add_aoa(abatement_ws, [row], { origin: { r: index, c: 0 } });
            });



        // load data to worksheet

            // Add data to the worksheet
            XLSX.utils.sheet_add_json(total_ws, totalData, { origin: -1, skipHeader: true });

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, total_ws, 'Scenario_Total');

            // Add data to the worksheet
            XLSX.utils.sheet_add_json(abatement_ws, abatementData, { origin: -1, skipHeader: true });

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, abatement_ws, 'Abatement');

            // Add data to the worksheet
            XLSX.utils.sheet_add_json(project_ws, projectData, { origin: -1, skipHeader: true });

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, project_ws, 'Project_Emission');

            // Add data to the worksheet
            XLSX.utils.sheet_add_json(baseline_ws, baselineData, { origin: -1, skipHeader: true });

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, baseline_ws, 'Baseline_Emission');


        // output
            // Export the workbook
            XLSX.writeFile(wb, 'estimator_scenarios_result.xlsx');
        
        }
            
        },
        // export xlsx file feature

        // improve table layout
        getColumnWidth(smallWidth, mediumWidth, largeWidth) {
            const screenWidth = this.$vuetify.display.width;
            if (screenWidth <= 1750) {
                return smallWidth;
            } else if (screenWidth <= 2087) {
                return mediumWidth;
            } else if (screenWidth <= 2350) {
                return largeWidth;
            } else {
                return "";
            }
        },


        updateChartKey() {
            this.chartKey += 1;
        },

        // generateGroupedData
        generateGroupedData(ScenarioData) {
            const groupedData = {};
            // Group data by year
            ScenarioData.forEach((item) => {
                const { Year, Scenario_ID, ...data } = item;
                // Initialize year in grouped data if not exists
                if (!groupedData[Year]) {
                    groupedData[Year] = { Year };
                }
                // Add scenario-specific data
                groupedData[Year][`Scenario_${Scenario_ID}`] = data;
            });
            return groupedData;
        },

        
        // generateFlattendData
        generateFlattenedData(data) {
            const result = [];
            Object.values(data).forEach((yearData) => {
                const row = { year: yearData.Year };
                Object.entries(yearData).forEach(([key, value]) => {
                    if (key.startsWith("Scenario_")) {
                        Object.entries(value).forEach(([fieldKey, fieldValue]) => {
                            row[`${key}_${fieldKey}`] = fieldValue;
                        });
                    }
                });
                result.push(row);
            });
            return result;
        },

        // generateTableColumns
        // generateTableColumns(data) {
        //     const columns = [];
        //     const sampleYear = Object.values(data)[0] || {};
        //     Object.entries(sampleYear).forEach(([key, value]) => {
        //         if (key.startsWith("Scenario_")) {
        //         Object.keys(value).forEach((fieldKey) => {
        //             columns.push({
        //             label: `${key.replace("_"," ")} ${fieldKey.replaceAll("_"," ").replaceAll("Project Emission", " ").replaceAll("AC Project Emission", " ").replaceAll("DC Project Emission", " ").replaceAll("Baseline Emission", " ").replaceAll("AC Baseline Emission", " ").replaceAll("DC Baseline Emission", " ").replaceAll("Total AC DC", "Total")}`,
        //             prop: `${key}_${fieldKey}`,
        //             });
        //         });
        //         }
        //     });
        //     return columns;
        // },

        generateTableColumns(data) {
            const columns = [];
            const sampleYear = Object.values(data)[0] || {};

            

            Object.entries(sampleYear).forEach(([key, value]) => {
                if (key.startsWith("Scenario_")) {
                const children = [];

                Object.keys(value).forEach((fieldKey) => {
                    // const label = `${key.replace("_", " ")} ${fieldKey.replaceAll("_", " ").replaceAll("Project Emission", " ").replaceAll("AC Project Emission", " ").replaceAll("DC Project Emission", " ").replaceAll("Baseline Emission", " ").replaceAll("AC Baseline Emission", " ").replaceAll("DC Baseline Emission", " ").replaceAll("Total AC DC", "Total")}`;
                    const prop = `${key}_${fieldKey}`;

                    if (fieldKey.includes("Total")) {
                        children.push({
                            label: "Total",
                            prop: prop,
                        });
                    } else if (fieldKey.includes("AC")) {
                        children.push({
                            label: "AC",
                            prop: prop,
                        });
                    } else if (fieldKey.includes("DC")) {
                        children.push({
                            label: "DC",
                            prop: prop,
                        });
                    } else
                    {
                        children.push({
                            label: "",
                            prop: prop,
                        });
                    }
                });

                if (children.length > 0) {
                    columns.push({
                    label: key.replace("_", " "),
                    children: children,
                    });
                }
                }
            });

            return columns;
            },

        


        rounding(row, column) {
            return parseFloat(row[column.property].toFixed(4)); // set digits of data on output table
        },
   
        resetForm() {
            this.$emit('resetForm');
            },
        
        async exportPdf() {
            try {
                
                await this.updateChartKey();
                    
                setTimeout(() => {
                    this.$emit('exportPdf');
                }, 3000);
            } catch (error) {
                console.error("Error exporting PDF:", error);
            } 
        },

        // test
        // generateGroupedData
        Test_groupDataByYear(data) {
            const groupedData = {};

            data.forEach((item) => {
                const { Year, Scenario_ID, AC_Abatement, DC_Abatement, AC_Abatement_per_mwh, DC_Abatement_per_mwh, Total_AC_DC_Abatement, Total_AC_DC_Abatement_per_mwh } = item;

                // Initialize year group if not exists
                if (!groupedData[Year]) {
                    groupedData[Year] = {
                        abatement: {},
                        abatement_per_mwh: {},
                    };
                }

                // Initialize scenario group for abatement if not exists
                if (!groupedData[Year].abatement[`Scenario_${Scenario_ID}`]) {
                    groupedData[Year].abatement[`Scenario_${Scenario_ID}`] = {};
                }

                // Initialize scenario group for abatement_per_mwh if not exists
                if (!groupedData[Year].abatement_per_mwh[`Scenario_${Scenario_ID}`]) {
                    groupedData[Year].abatement_per_mwh[`Scenario_${Scenario_ID}`] = {};
                }

                // Add abatement values
                if (AC_Abatement !== undefined) {
                    groupedData[Year].abatement[`Scenario_${Scenario_ID}`]["ac"] = AC_Abatement;
                }
                if (DC_Abatement !== undefined) {
                    groupedData[Year].abatement[`Scenario_${Scenario_ID}`]["dc"] = DC_Abatement;
                }
                if (Total_AC_DC_Abatement !== undefined) {
                    groupedData[Year].abatement[`Scenario_${Scenario_ID}`]["total"] = Total_AC_DC_Abatement;
                }

                // Add abatement_per_mwh values
                if (AC_Abatement_per_mwh !== undefined) {
                    groupedData[Year].abatement_per_mwh[`Scenario_${Scenario_ID}`]["ac"] = AC_Abatement_per_mwh;
                }
                if (DC_Abatement_per_mwh !== undefined) {
                    groupedData[Year].abatement_per_mwh[`Scenario_${Scenario_ID}`]["dc"] = DC_Abatement_per_mwh;
                }
                if (Total_AC_DC_Abatement_per_mwh !== undefined) {
                    groupedData[Year].abatement_per_mwh[`Scenario_${Scenario_ID}`]["total"] = Total_AC_DC_Abatement_per_mwh;
                }
            });

            
            return groupedData;
        },


        
        // testFlattendData
        Test_flattenGroupedData(groupedData) {
            const flattened = [];

            // Iterate through each year
            Object.entries(groupedData).forEach(([Year, yearData]) => {
                // const row = { Year: parseInt(Year) };
                const row = { Year: Year };

                // Flatten abatement data
                Object.entries(yearData.abatement).forEach(([scenarioID, acdcData]) => {
                if (acdcData.ac !== undefined) {
                    row[`abatement_${scenarioID}_ac`] = acdcData.ac;
                }
                if (acdcData.dc !== undefined) {
                    row[`abatement_${scenarioID}_dc`] = acdcData.dc;
                }
                if (acdcData.total !== undefined) {
                    row[`abatement_${scenarioID}_total`] = acdcData.total;
                }
                });

                // Flatten abatement per mwh data
                Object.entries(yearData.abatement_per_mwh).forEach(([scenarioID, acdcData]) => {
                if (acdcData.ac !== undefined) {
                    row[`abatement_per_mwh_${scenarioID}_ac`] = acdcData.ac;
                }
                if (acdcData.dc !== undefined) {
                    row[`abatement_per_mwh_${scenarioID}_dc`] = acdcData.dc;
                }
                if (acdcData.total !== undefined) {
                    row[`abatement_per_mwh_${scenarioID}_total`] = acdcData.total;
                }
                });

                flattened.push(row);
            });

            return flattened;
            },


        // testTableColumns
        Test_generateDynamicTableHeader(groupedData) {
            const tableHeader = [];

            // Header Level 1: Parent headers (abatement and abatement per mwh)
            const level1Headers = ['abatement', 'abatement_per_mwh'];

            level1Headers.forEach((headerName) => {
                const header = {
                    label: headerName.replaceAll('_', ' ').replaceAll("abatement","Abatement").replaceAll("mwh", "MWh"), // Format label
                    children: [], // Level 2 children
                };

                // Extract all unique Scenario_IDs for this header
                const scenarioIDs = new Set();

                Object.values(groupedData).forEach((yearData) => {
                    if (yearData[headerName]) {
                        Object.keys(yearData[headerName]).forEach((scenarioID) => {
                            scenarioIDs.add(scenarioID);
                        });
                    }
                });

                // Header Level 2: Scenario IDs
                scenarioIDs.forEach((scenarioID) => {
                    const scenarioHeader = {
                        label: scenarioID.replaceAll("_", " "), // e.g., Scenario_1
                        children: [], // Level 3 children (ac or dc)
                    };

                    // Header Level 3: AC/DC fields
                    // const acDcHeader = [
                    //     { label: 'AC', prop: `${headerName}_${scenarioID}_ac` },
                    //     { label: 'DC', prop: `${headerName}_${scenarioID}_dc` },
                    //     { label: 'Total', prop: `${headerName}_${scenarioID}_total` },
                    // ];

                    const acDcHeader = [];

                        // Ensure abatement object and properties exist
                    const abatementData = groupedData[0]?.abatement?.[`${scenarioID}`];
                    if (abatementData) {
                        if (abatementData.ac) {
                            acDcHeader.push({ label: 'AC', prop: `${headerName}_${scenarioID}_ac` });
                        }
                        if (abatementData.dc) {
                            acDcHeader.push({ label: 'DC', prop: `${headerName}_${scenarioID}_dc` });
                        }
                        if (abatementData.total) {
                            acDcHeader.push({ label: 'Total', prop: `${headerName}_${scenarioID}_total` });
                        }
                        
                    }

                    scenarioHeader.children.push(...acDcHeader);

                    header.children.push(scenarioHeader);
                });

                tableHeader.push(header);
            });

            return tableHeader;
        },

        // test


    },
 
}
</script>

<style scoped>
.estimaTotalTable :deep(.el-table) {
    border-color: #234b49;
}

/* Apply border color to table rows and columns */
.estimaInputTable :deep(.el-table__row > td) {
    border-color: #234b49;
}

.estimaTotalTable :deep(.el-table__row > td) {
    border-color: #234b49;
}

/* Apply border color to leaf headers */
.estimaInputTable :deep(.el-table th.is-leaf) {
    border-color: #234b49;
}

.estimaTotalTable :deep(.el-table th.is-leaf) {
    border-color: #234b49;
    border-bottom: none;
    border-top: none;
}

.estimaTotalTable :deep(.el-table th) {
    border-color: #234b49;
}

.estimaTotalTable :deep(.el-table th.is-group) {
    border-color: #234b49;
    background-color: #234b49;
}

/* Apply background color to the table wrapper before pseudo-element */
.estimaInputTable :deep(.el-table__inner-wrapper::before) {
    background-color: #234b49;
}

.estimaTotalTable :deep(.el-table__inner-wrapper::before) {
    background-color: #234b49;
    border-color: #234b49;
    border-top: none;
}

.estimaTotalTable :deep(.el-table__inner-wrapper::before) {
    background-color: #234b49;
    border-color: #234b49;
}

.estimateTotalMultiHeaderTable :deep(.el-table__header-wrapper) {
    background-color: #234b49;
    border-color: #234b49;
}

.estimateTotalMultiHeaderTable :deep(.el-table th) {
    border-color: #234b49;
}

.estimateTotalMultiHeaderTable :deep(.el-table__row > td) {
    border-color: #234b49;
}

</style>