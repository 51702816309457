<!-- eslint-disable vue/multi-word-component-names -->

<template>
    <Breadcrumbs main="Estimator" :path="breadcrumbPath" />
    <div class="container-fluid p-0">

        <div v-if="!isSubmitted">

            <div v-for="scenario in scenarios" :key="scenario.id">

                <!-- UserInput Form -->
                <userInputs  :formData="scenario.data" :activeApply="scenarios.length" :scenarioID="scenario.id" @removeForm="handleRemove(scenario.id)" @resetForm="handleInputReset(scenario.id)" @submitForm="handleSubmitted" />  
                <!-- UserInput Form -->

            </div>

            

                <!-- Add another scenario -->
                <div>
                    <div >
                        <el-row class="m-0 p-1 width-100-p mt-2">
                            
                        
                            <!-- <button><span>Apply</span></button> -->
                            <el-col class="d-flex align-item-center" 
                                :span="$vuetify.display.width >= 1619 ? 3 : ($vuetify.display.width >= 1378 ? 3 : ($vuetify.display.width >= 1187 ? 4 : ($vuetify.display.width >= 827 ? 5 : 24)) ) "
                                :class="$vuetify.display.width < 827
                                    ? 'x-left mt-2'
                                    : ''
                                    ? 'justify-end'
                                    : ''
                                ">
                                <el-button
                                    color="#ddf0ac"
                                    class="height-40 rounded-3"
                                    @click="addScenario"
                                >
                                        <span class="me-primary pl-3"> Add Scenario </span>
                                </el-button>
                            </el-col>

                            <div>
                            <el-col class="d-flex ml-1 align-item-center" v-if="scenarios.length > 1"
                            :span="$vuetify.display.width >= 1619 ? 3 : ($vuetify.display.width >= 1378 ? 3 : ($vuetify.display.width >= 1187 ? 8 : ($vuetify.display.width >= 827 ? 7 : 24)) ) "
                            :class="$vuetify.display.width < 827
                                    ? 'x-left mt-2'
                                    : ''
                                    ? 'justify-end'
                                    : ''
                                ">
                                <!-- <button><span>Apply</span></button> -->
                                <el-button
                                    color="#ddf0ac"
                                    @click="handleApplyAll"
                                    class="height-40 rounded-3"
                                >
                                    <svg class="stroke-icon width-20 height-20">
                                    <use
                                        href="@/assets/svg/icon-sprite.svg#stroke-refresh"
                                    ></use>
                                    </svg>
                                    <span class="me-primary pl-3"> Apply </span>
                                </el-button>
                            </el-col>   
                        </div>                         
                        
                        </el-row>
                        

                    </div>

                </div>
                <!-- Add another scenario -->

        </div>

        <!-- {{ scenarios }} -->

        <!-- {{ scenarioResults }} -->
        
        <!-- output page -->
        <!-- <screnarioOutput v-if="isSubmitted && isMoreScrenarios" @resetForm="handleOutputReset" @exportPdf="handleExport" /> -->
        <eOutput :key="outputKey" v-if="isSubmitted" :moreScrenarios="isMoreScrenarios"  @resetForm="handleOutputReset" @exportPdf="handleExport" />


        <!-- generatePDF -->
        <genPdf :pdfMoreScrenarios="isMoreScrenarios" v-if="isSubmitted" ref="childPdf" />


    </div>


</template>

<script>
import userInputs from "./userInputs.vue";

import eOutput from "./estimaOutput.vue";

import genPdf from "./genPdf.vue";

import Breadcrumbs from "@/components/bread_crumbs.vue";

import { mapState, mapMutations } from "vuex";

import userService from "@/services/user.service";

import { ElMessageBox } from "element-plus";



// handel resizeobsever error overlay start
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
// handel resizeobsever error overlay end

export default {
    props: {},
    components: {

        Breadcrumbs,
        userInputs,
        eOutput,
        genPdf,


    },
    computed: {
        // scenarios
        ...mapState('estimator',{
            scenarios: state => state.scenarios
        }),

        // scenarios results
        ...mapState('estimator',{
            scenarioResults: state => state.scenarioResults
        }),

        // stateList
        stateList() {
        return this.states;
        },

    },
    data(){
        return{


            outputKey: 0,

            resetInput : 0,

            isSubmitted: false, // control component switch

            isMoreScrenarios: false, // control component switch

            states: [],
            filter: {
                state: "",
                startDate: null,
                endDate: null,
                customer_id: null,
            },
            breadcrumbPath: [
                // {
                // title: "General",
                // disabled: true,
                // href: "/home",
                // },
                // {
                // title: "Estimator",
                // disabled: false,
                // href: "/dashboard-estimator",
                // active: true,
                // },
            ],

        }
    },

    methods: {
        // scenarios
        ...mapMutations('estimator', ['set_scenario']),
        ...mapMutations('estimator', ['set_remove_scenario']),
        ...mapMutations('estimator', ['set_reset_from']),
        ...mapMutations('estimator', ['set_reset_scenario']),

        // scenarios results
        ...mapMutations('estimator', ['reset_scenario_result']),
        ...mapMutations('estimator', ['add_scenario_result']),

        // estimatordata 
        ...mapMutations('estimator',['reset_estimatorData']),
        

        updateKey() {
            this.outputKey ++;
        },
        // scenarios for mutiple input forms
        addScenario() {

            this.set_scenario();
        },

        // remove scenario
        removeScenario(id) {

            this.set_remove_scenario(id);
        },

        // handle remove button from child comp
        handleRemove(id){
            this.removeScenario(id);
        },

        handleSubmitted() {
            this.isSubmitted = true;
            // console.log("form submitted");
            
        },

        // reset  from input page
        handleReset() {
            this.isSubmitted = false;
            this.handleInputReset();
        },

        // reset  from output page
        handleOutputReset() {
            this.isSubmitted = false;
            this.isMoreScrenarios = false;
            this.set_reset_scenario();
        },

        async handleExport() {
            // console.log("index receive pdf ");

            await this.updateKey();

            setTimeout(()=>{
                this.$refs.childPdf.generateReport();
            }, 2000);
            // this.$refs.childPdf.generateReport();
            
            
        },

        // reset from input form page
            // reset input forms by id, add empty payload for mutations
        handleInputReset(id) {
            this.set_reset_from({
                id: id, 
                payload:{
                    // AC / DC form contro
                showAC: false,
                showDC: false,
                // basic parameter 
                b_gei: '', // Grid Emi
                b_aeii: 5, // Annual  
                b_por: 0, //  Percenta
                b_fei: '', // Fuel Emi
                // AC charging paramet
                ac_au: '', // Annual u
                ac_agu: '', // Annual 
                ac_mpg: '', // MPG (mi
                ac_afec: '', // AFEC (
                ac_tir: '', // Technol
                ac_ce: 100, // Charger
                // DC charging paramet
                dc_au: '', // Annual u
                dc_agu: '', // Annual 
                dc_mpg: '', // MPG (mi
                dc_afec: '', // AFEC (
                dc_tir: '', // Technol
                dc_ce: '', // Charger 
                location: 'null',
            }
        });
            this.resetInput += 1;
        },

    // check missing field for scenario inputs
        
        checkInputs(inputs) {

        const missingFields = [];

        inputs.forEach((scenario) => {
            const { showAC, showDC, ...restData } = scenario.data;

            if (!showAC && !showDC) {
                    missingFields.push({
                        scenarioID: scenario.id,
                        missingFields: 'all',
                    })
                } else  {

            Object.keys(restData).forEach((key) => {
                const value = restData[key];

                
                // Case 1: Both `showAC` and `showDC` are true
                if (showAC && showDC) {
                    if (
                        (key.startsWith("ac_") || key.startsWith("dc_") || key.startsWith("b_")) &&
                        (value === null || value === "" || value === undefined || isNaN(value))
                    ) {
                        missingFields.push({
                            scenarioID: scenario.id,
                            missingField: key,
                        });
                    }
                }

                // Case 2: `showAC` is true and `showDC` is false
                if (showAC && !showDC) {
                    if (
                        (key.startsWith("ac_") || key.startsWith("b_")) &&
                        (value === null || value === "" || value === undefined || isNaN(value))
                    ) {
                        missingFields.push({
                            scenarioID: scenario.id,
                            missingField: key,
                        });
                    }
                }

                // Case 3: `showDC` is true and `showAC` is false
                if (showDC && !showAC) {
                    if (
                        (key.startsWith("dc_") || key.startsWith("b_")) &&
                        (value === null || value === "" || value === undefined || isNaN(value))
                    ) {
                        missingFields.push({
                            scenarioID: scenario.id,
                            missingField: key,
                        });
                    }
                }
           
            });
        }
        });

        // Print and return the result
        if (missingFields.length === 0) {
            console.log("No missing fields found.");
            return [];
        } else {
            console.log("Missing fields detected:");
            return missingFields;
        }
    },


    // methods for apply all scenario button
        handleApplyAll() {

            

            const missingFields = this.checkInputs(this.scenarios);
            let isEmpty = 0;
            
            this.scenarios.forEach((scenario) => {
                const data = scenario.data;
                if (data.showAC || data.showDC) {
                    isEmpty += 1;
                }
            });

            // console.log("scenarios", this.scenarios);
            
            // console.log("length", isEmpty);
            
            // console.log("Checking scenario inputs...");

            if (isEmpty === 0) {
                return ElMessageBox.alert(
                        `<strong><span> Input form empty!</span></strong>`,
                    {dangerouslyUseHTMLString: true,}
                );
            } else if ( missingFields.length === 0) {
                // console.log("Submitting all scenarios...");
                this.SubmiteAllScenarios();

                this.isMoreScrenarios = true;
                this.handleSubmitted();

            } else {
                const fieldDescriptions = {
                    b_gei: "Grid emission intensity",
                    b_aeii: "Annual emissions intensity improvement",
                    b_por: "Percentage of onsite renewables",
                    b_fei: "Fuel emission intensity",
                    ac_au: "Annual usage (AC)",
                    ac_agu: "Annual growth in usage (AC)",
                    ac_mpg: "Miles per gallon (AC)",
                    ac_afec: "Average fuel economy coefficient (AC)",
                    ac_tir: "Technology improvement rate (AC)",
                    ac_ce: "Charger efficiency (AC)",
                    dc_au: "Annual usage (DC)",
                    dc_agu: "Annual growth (DC)",
                    dc_mpg: "Miles per gallon (DC)",
                    dc_afec: "Average fuel economy coefficient (DC)",
                    dc_tir: "Technology improvement rate (DC)",
                    dc_ce: "Charger efficiency (DC)",
                };


                const results = [];

                missingFields.forEach(field => {
                    // console.log("missing fields", field);

                    
                    
                    if(field.missingFields === 'all') {
                        
                        results.push({
                            ID: field.scenarioID,
                            field: 'charging type',
                        });
                        return;
                    //     return ElMessageBox.alert(
                    //     `<strong><span> Scenario ID ${field.scenarioID}: please choose charging type!</span></strong>`,
                    // {dangerouslyUseHTMLString: true,}
                    //      );
                    } else {
                        const replacedField = field.missingField
                        .split(", ") // Split multiple fields if they exist
                        .map(f => fieldDescriptions[f] || f) // Replace with description or keep as-is
                        .join(", ");
                        results.push({
                            ID: field.scenarioID,
                            field: replacedField,
                        })
                    // return ElMessageBox.alert(
                    //         `<strong><span> Scenario ID ${field.scenarioID}: ${replacedField} is missing!</span></strong>`,
                    //         'Check Inputs',
                    //     {dangerouslyUseHTMLString: true,}
                    // );
            }

                });
                results.forEach((result) => {
                    return ElMessageBox.alert(
                            `<strong><span> Scenario ID ${result.ID}: ${result.field} is missing!</span></strong>`,
                            'Check Inputs',
                        {dangerouslyUseHTMLString: true,}
                    );
                })
                
            }
        },


        async SubmiteAllScenarios() {

            try {
                this.reset_scenario_result();

                const results = await Promise.all(
                    this.scenarios.map( async (scenario) => {
                        try {
                            const response = await userService.DashboardEstimator(scenario.data);
                            if(response && response.data) {

                                this.add_scenario_result({
                                    id: scenario.id,
                                    responseData: response.data,
                                });

                                
                                return {
                                    id: scenario.id,
                                    success: true,
                                };

                            } else {
                                console.error(`No data received for scenario: ${scenario.id}`);
                                return {
                                    id: scenario.id,
                                    success: false,
                                };
                            }
                        } catch (error) {
                            console.error(`Error when submitting scenario ${scenario.id}`, error);
                            return {
                                    id: scenario.id,
                                    success: false,
                                };
                        }
                    })
                );

                console.log('submit scenario results:', results);
                // console.log('scenarioResults:', this.scenarioResults);
                

                
            } catch (error) {
                console.error('Error posting all scenarios:', error);
            } 
            
        },



    },

    mounted(){
        // add the scenario 1
        this.addScenario();

        // when comp mounted, reset scenarios
        this.set_reset_scenario();

        // when mount rest estimator data
        this.reset_estimatorData();

        // when mount rest scenario_results
        this.reset_scenario_result();

    },

}
</script>

<style scoped>
span {
    color: white;
}
label{
    color: white;
}
</style>
